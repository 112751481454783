:root {
  --white: #ffffff;
  --black-button: #181851;
  --offwhite: #f4f4f4;
}

// FAQ
$billerq-color: #534686;
$primary-color: #534686;
$disable-color: #7a7a7b;
$active-color: #ffa47a;
$white: #ffffff;
$light-gray: #5346861a;


.login-button {
  display: inline-flex;
  padding: 11px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background-color: #534686 !important;
  color: var(--white);
}

.logo {
  height: 60px;
}

.FAQ-container {
  width: 88%;
  margin-left: auto;
  margin-right: auto;
  font-family: "poppins";
  padding-top: 70px;
  padding-bottom: 70px;
}

.billerqColor {
  color: $billerq-color;
}

.active-color {
  color: $active-color;
}

.disabled-color {
  color: $disable-color;
}

.active-color:hover {
  color: $billerq-color;
}

.FAQ-title h2 {
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 40px;
}

.img-faq {
  display: flex;
  justify-content: center;
  align-items: center;
}

// .img-faq .img {
//   width: 36%;
// }

// .img-faq .faq-section {
//   width: 60%;
// }

.faq-section h2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

// .faq-section {
//   width: 90%;
// }

.faq-section p {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #747373;
  width: 90%;
}

.buy-or-sell {
  padding: 0 30px;
  text-align: center;
}

.buy-or-sell h3 {
  font-size: 24px;
}

.accordion-head {
  font-size: 16px;
  padding: 10px;
}

.accordion-button {
  background-color: transparent !important;
  color: #6f42c1;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #0f0e10;
}

// @media only screen and (max-width: 800px) {
//   .img-faq {
//     flex-direction: column;
//   }

//   .img-faq .img {
//     width: 90%;
//   }

//   .img-faq .faq-section {
//     width: 90%;
//   }
// }

.img-faq {
  display: flex;
  justify-content: center;
  align-items: center;
}

.FAQ-image {
  height: 650px;
}

.img-faq .img {
  width: 50%;
}

// .img-faq .faq-section {
//   width: 60%;
// }

// Contact

.contact-container {
  width: 100%;
  height: 100%;
  background: radial-gradient(50% 50% at 50% 50%, #453364 0%, #231f20 100%);
  font-family: "poppins";
  color: #fff;
}

.connect-container {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}

.contact-detals {
  width: 46%;
  padding: 50px;
}

.contact-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contact-detals h2 {
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.contact-section {
  display: flex;
  align-items: center;
  padding-top: 30px;
}

.contact-section p {
  padding-left: 20px;
  font-size: 17px;
  margin-bottom: 0;
  // width: 80%;
}

.connect-details {
  padding: 50px;

  .form-control {
    padding: 12px 15px;
    width: 400px;
  }

  @media (max-width: 600px) {
    .form-control {
      width: 100%;
    }
  }

  .button-submit {
    display: inline-flex;
    padding: 11px 43px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--theme-default);
    margin-top: 10px;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    border-radius: 10px;
    background: #fff;
  }
}

.connect-details h2 {
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.input-field {
  background: #fff;
}

@media only screen and (max-width: 888px) {
  .contact-section p {
    width: 100%;
  }
}

@media only screen and (max-width: 787px) {
  .connect-container {
    flex-direction: column;
  }

  .contact-detals {
    width: 100%;
  }

  .connect-details {
    padding: 0px 50px 50px 50px;
  }
}

// Testimonial
.testimonial-container {
  padding-top: 50px;
  padding-bottom: 50px;
  font-family: "poppins";
}

.testimonial-container h2 {
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.carousel-Image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonial-carousal {
  margin-left: 30px;
  margin-right: 30px;
  padding: 30px 30px;
}

.feedback {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.carousel-container-testimonial {
  width: 60%;
}


// client

.client-detail {
  display: flex;
  align-items: center;
}

.client-detail p {
  margin-bottom: 0;
}

.client-name {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.client-designation {
  color: #747373;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.design-element6 {
  position: absolute;
  top: 8%;
  left: 66%;
  transition: left 0.3s ease;
}

.section2-container {
  height: 100%;
  width: 100%;
  font-family: 'Poppins', sans-serif;
}

.design-elements4 {
  display: flex;
  justify-content: center;
  padding-top: 70px;
}

.productivity-container {
  display: block;
  margin: 0 auto;
  /* Simplified to center container */
  text-align: center;
  width: 50%;
}

.productivity-container h2 {
  font-size: 30px;
  font-weight: 600;
  padding-top: 20px;
}

.productivity-container p {
  font-size: 18px;
  font-weight: 400;
  padding: 15px 0 20px;
}

.productivity-button {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 30px;
  flex-wrap: wrap;
}

.button-black-dash {
  border-radius: 10px;
  background: var(--black-button);
  color: var(--white);
}

.button-white-dash {
  border-radius: 10px;
  color: var(--black-button);
  background: var(--offwhite);
}

.productivity-button .button-black-dash,
.productivity-button .button-white-dash {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border: 1px solid transparent;

}

.blackBox {
  margin: 50px auto 60px;
  width: 80%;
  background-color: var(--black-button);
  border-radius: 20px 50px 20px 20px;
}

.whiteBox {
  width: 550px;
  height: 80px;
  float: right;
  background-color: var(--white);
  transform: skew(20deg);
}

.dashboard-container {

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.dashboard-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  // margin:auto;
}

.dashboard-image {
  margin-top: 10px;
  width: 50%;
}

.dashboard-image img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;

}

@media only screen and (max-width: 768px) {
  .dashboard-image {
    width: 90%;
  }
}

@media only screen and (max-width: 992px) {
  .billerQ-details p {
    width: 80%;
  }
}

@media only screen and (max-width: 766px) {
  .whiteBox {
    width: 300px;
  }
}

@media only screen and (max-width: 690px) {
  .billerQ-details {
    padding-left: 50px;
  }

  .productivity-container {
    width: 80%;
  }

  .productivity-button .button-white {
    margin-left: 0;
  }
}

@media only screen and (max-width: 500px) {
  .productivity-button {
    flex-direction: column;
  }

  .productivity-button .button-white {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 430px) {
  .whiteBox {
    display: none;
  }

  .blackBox {
    border-radius: 20px;
  }

  .billerQ-details {
    padding-top: 50px;
  }

  .billerQ-details h2 {
    font-size: 22px;
  }

  .billerQ-details p {
    font-size: 15px;
  }
}


@media only screen and (max-width: 850px) {
  .carousel-Image {
    flex-direction: column;
  }

  .carousel-container-testimonial {
    width: 80%;
  }

  .testimonial-carousal {
    width: 90%;
    padding: 15px 15px;
  }
}

// Blog
.blackBoxBlog {
  margin-top: 50px;
  margin-bottom: 60px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--theme-default);
  border-radius: 50px 20px 20px 20px;
  padding-bottom: 50px;
}

.whiteBoxLeft {
  width: 550px;
  height: 80px;
  background-color: var(--white);
  -webkit-transform: skew(-20deg);
  -moz-transform: skew(-20deg);
  -o-transform: skew(-20deg);
  transform: skew(-20deg);
}

.blog-title {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 70px;
}

.blog-title h2 {
  color: #f4f4f4;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.carousel-container-blog {
  width: 80%;
}

.blog-carousal {
  padding-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;

  .card {
    display: flex;
    flex-direction: column;
    /* width: clamp(20rem, calc(20rem + 2vw), 22rem); */
    margin-left: 10px;
    margin-right: 10px;
    overflow: hidden;
    box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
    border-radius: 1em;
    background: #ece9e6;
    background: linear-gradient(to right, #ffffff, #ece9e6);
    margin-bottom: 35px;
  }

  img {
    max-width: 100%;
    display: block;
    object-fit: cover;
  }
}

.blog-carousal-item {
  border-radius: 15px;
  padding-left: 40px;
  margin-top: 40px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 40px;
  background-color: #f4f4f4;
}

.blog-carousal-style {
  padding-top: 15px;
  padding-bottom: 20px;
}

.card__body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.card__body h4 {
  font-size: 25px;
  text-transform: capitalize;
}

.card__footer {
  display: flex;
  padding: 1rem;
  margin-top: auto;
}

.user {
  display: flex;
  gap: 0.5rem;
}

.user__image {
  border-radius: 50%;
}

.user__info>small {
  color: #666;
}

.testimonial-video {
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonial-image {
  height: 500px;
}

@media only screen and (max-width: 1080px) {
  .card__body h4 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 950px) {
  .card {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .card {
    width: 100%;
    margin: 0px auto;
  }
}

@media only screen and (max-width: 833px) {
  .whiteBoxLeft {
    width: 350px;
  }

  .blog-title .button-white {
    margin-right: 10px;
  }

  .blog-title h2 {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 600px) {
  .whiteBoxLeft {
    display: none;
  }

  .blackBoxBlog {
    border-radius: 20px;
  }

  .card {
    width: 100%;
    margin: 0px auto;
  }

  .blog-title {
    padding-top: 40px;
  }

  .blog-title h2 {
    font-size: 22px;
  }

  .blog-title .button-white {
    padding: 11px 20px;
  }
}

@media only screen and (max-width: 350px) {
  .blog-title {
    padding-top: 30px;
  }

  .blog-title h2 {
    font-size: 20px;
  }

  .blog-title .button-white {
    padding: 11px 25px;
  }
}

// Footer
.footer-containers {
  width: 100%;
  // height: 100%;
  padding-top: 50px;
  background-color: #ffffff;
  font-family: "poppins";
}

.footer-container p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer-container h3 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

}

.footer-section1 {
  width: 70%;
}

.footer-section2 {
  padding: 1rem;

  h3 {
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 0.5rem;
    }
  }
}

.social-media .icon {
  margin-right: 15px;
}

.social-media .icon:hover {
  color: #453364;
}

.footer-contact-location {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-contact {
  display: flex;
}

.footer-contact p,
.footer-contact-location p {
  margin-left: 15px;
}

.footer-line {
  background: #d8d8d8;
  color: #d8d8d8;
  border-color: #d8d8d8;
  height: "1px";
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.copyright {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
  font-size: 13px;
  color: #c3c3c3;
}

@media only screen and (max-width: 991px) {
  .footer-section3 {
    padding-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-sections {
    padding-left: 40px;
    padding-right: 40px;
  }

  .footer-section2 {
    padding-top: 30px;
  }
}

.fileInputStyle {
  height: 45px !important;
  padding: 0.775rem 1.075rem !important;
}

.imagestyle {
  padding-left: 10px;
}

.requireStar {
  color: red;
}

.onhover-traslate {
  transform: translateY(-22px) !important;
}

.profile-nav {
  border: 5px solid red;

  Image {
    height: 40px !important;
  }
}

.h-width {
  height: 40px !important;
}

.onhover-dropdown {
  margin-right: 10px;

  .wrap-text {
    white-space: nowrap;
  }

  .role-popup {
    width: 14rem !important;
    // left: 0px !important;
    padding: 0px 20px;

    // border: 2px solid red;
    .role-name {
      padding: 10px 10px;
    }
  }

  .add-company {
    padding: 15px;
    text-align: center;
    font-size: medium;
    font-weight: 700;
  }
}

.spinner-enable-stb {
  .bttn {
    background-color: $billerq-color !important;
    border: none;
    box-sizing: border-box;
    width: 130px;

    .loader-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .loader {
      width: 15px;
      height: 15px;
      border: 3px solid #fff;
      border-bottom-color: $billerq-color;
      border-radius: 50%;
      display: inline-flex;
      box-sizing: border-box;
      align-items: center; // Center vertically
      justify-content: center;
      animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  .btn:disabled {
    background-color: $billerq-color !important;
  }
}

.spinner-enable {
  .btn {
    background-color: $billerq-color !important;
    border: none;
    width: 7rem;

    .loader {
      width: 15px;
      height: 15px;
      border: 3px solid #fff;
      border-bottom-color: $billerq-color;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  .btn:disabled {
    background-color: $billerq-color !important;
  }
}

.bg-subscription {
  background-color: rgba(212, 223, 241, 0.868);
  padding: 20px;
  border-radius: 5px;
  // margin-top: 20px;
}

.bg-subscriptiontr {
  background-color: rgba(212, 223, 241, 0.868) !important;
}

.bg-light-blue {
  background-color: rgba(212, 223, 241, 0.868) !important;
}

.TaxTypeahead.no-color-onfocus {
  border-color: transparent !important;

  &:focus {
    border-color: transparent !important;
  }

  // padding: 0px !important;
  // border: none !important;
  // border-color:#53468600 !important;
}

.order-history {
  .table-bordered {
    .quantityInputField {
      .countspan {
        // width: 6% !important;
        padding-left: 10px;
        padding-right: 10px;
      }

      .btnspan {
        // height: 4px;
        // width: 3px !important;

        .quatitybutton {
          padding: 1px 5px !important;
          border-radius: 2px;
        }
      }
    }
  }
}

.login-div {
  .login-btnx {
    padding: 11px 43px !important;

    .loaderx {
      // padding: 10px 10px;
      // padding: 13px 50px!important;

      width: 24px;
      height: 24px;
      border: 3px solid #000000;
      border-bottom-color: #534686;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.connection-div {
  height: 160px;
  // overflow: auto;
  overflow-x: hidden !important;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(255, 255, 255, 0.3);
    border: none;
    // border-radius: 3px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #cbcbcb;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #cac5c5;
  }
}

.parallel-calendar {
  display: flex !important;
}

.card-text-color {
  color: #534686 !important;
}

// ScrollDownButton.scss
.scroll-down-button {
  position: fixed;
  bottom: 40px;
  right: 30px;
  background-color: transparent;
  // color: #534686;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 100%;
  opacity: 0;
  transition: opacity 0.3s, background-color 0.3s;
  background-color: #534686;

  i {
    transition: transform 0.3s ease-in;
  }

  &.visible {
    opacity: 1;
    background-color: #534686;

    i {
      color: white;
    }
  }

  &.up {
    i {
      transition: transform 0.3s ease-in;
      transform: rotate(80deg);
    }
  }
}

.qpay-rightbar {
  writing-mode: vertical-rl;
  transform: (rotate(180deg));
  font-size: small;
  // font-weight:900;
}

// globalStyles.scss

body {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    border-radius: 3px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #cbcbcb;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: #cac5c5;
  }
}

.tab-company-edit {
  .tab-button {
    background-color: #534686 !important;
    border: none;
  }
}

.rdt_TableHead {
  h4 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: normal;
  }
}

.rdt_TableHeadRow {
  // background: #007BFF;
  color: #534686;
  border-radius: 6px;
}

.rdt_Table {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.badge {
  background: #f3f3f3;
  color: #000;
}

.badge-inactive {
  background: crimson;
  color: #fff;
}

.badge-suspended {
  background: #b793d2;
  color: #fff;
}

.badge-active {
  background: green;
  color: #fff;
}

.badge-terminated {
  background: crimson !important;
  color: #fff;
}

.badge {
  background: #f3f3f3;
  color: #000;
}

.badge-inactive {
  background: crimson;
  color: #fff;
}

.badge-Inactive {
  background: crimson;
  color: #fff;
}

.badge-Pending {
  background: #ffa47a;
  color: #fff;
}

.badge-archive {
  background: #ffa47a;
  color: #fff;
}

.badge-suspended {
  background: #b793d2;
  color: #fff;
}

.badge-Active {
  background: #67c100;
  color: #fff;
}

.badge-active {
  background: #67c100;
  color: #fff;
}

.badge-Paid {
  background: #67c100;
  color: #fff;
}

.badge-processed {
  background: #67c100;
  color: #fff;
}

.badge-disconnected {
  background: rgba(0, 0, 0, 0.87);
  color: #fff;
}

.badge-pending {
  background: crimson;
  color: #fff;
}

.badge-partially {
  background: #0e1d6f;
  color: #fff;
}

.badge-partial {
  background: #0e1d6f;
  color: #fff;
}

.badge-paid {
  background: #67c100;
  color: #fff;
}

.badge-received {
  background: #67c100;
  color: #fff;
}

.badge-closed {
  background: #67c100;
  color: #fff;
}

.badge-open {
  background: crimson;
  color: #fff;
}

.badge-in_progress {
  background: #0e1d6f;
  color: #fff;
}

.badge-in_terminated {
  background: #75788c;
  color: #fff;
}

.payment-total {
  ul {
    li {
      // background-color: #7a7a7b;
      display: flex;
      justify-content: space-between;
      border: none;
      padding: 2px;
      padding-left: 10px;
      margin-right: 20px;

      i {
        font-size: large;
        color: $billerq-color;
        cursor: pointer;
      }

      span {
        line-height: 2rem;

        &:nth-child(2) {
          font-weight: 500;
          text-align: right;
        }
      }
    }
  }
}

.cursor {
  cursor: pointer;
}

.details-container .detail-item,
.invoice-details .detail-item {
  line-height: 0.9;
}

.print-payment {
  margin-top: 20px;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: -16px;
}

.left {
  margin-right: 10px;
  font-weight: 600;
}

.right {
  margin-left: 10px;
  text-align: right;
}

.customer-details {
  margin-bottom: 4px;
}

.customer-add {
  font-weight: 500;
}

.customer-add p {
  margin: -2px;
}

.customer-name {
  margin: -4px;
  font-weight: 800;
  font-size: 15px;
}

.customer-address p {
  // margin: -10px 0;
  font-weight: 400;
  font-size: 12px;
}

.print-button {
  background-color: navy;
  color: white;
  padding: 6px 20px;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.print-button:hover {
  background-color: rgb(138, 138, 192);
}

.thank-you {
  margin-top: -20px;
}

.cancel-button {
  padding: 4px 17px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: rgb(212, 212, 220);
}

.paid-amount {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 700;
}

.invoice-main {
  .invoice_div {
    .invoice_ul {
      line-height: 15px;
      font-size: 8px;
      font-weight: 500;

      li {
        border: none;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}

.invoice_secound {
  .invoice_div {
    ul {
      line-height: 15px;

      li {
        border: none;
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}

.order-box {
  ul {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        margin-left: 30px;
        font-weight: bold;
      }
    }
  }
}

.discount-button {
  button {
    background-color: $billerq-color !important;
    border: none;
  }

  div {
    strong {
      float: right;
      margin-left: 10px;
    }
  }
}

.table-row-color {
  th {
    background-color: rgba(212, 223, 241, 0.868) !important;
  }
}

.subcription-table {
  thead {
    th {
      background-color: rgba(212, 223, 241, 0.868) !important;
    }
  }

  tbody {
    tr {
      td {
        background-color: rgba(212, 223, 241, 0.868) !important;
      }
    }
  }
}

.dashboardTableLinks {
  color: #9993B4;
}

.react-datepicker-wrapper {
  width: 100%;
}

.plusDesign {
  height: 45px;
  width: 45px;
  background: #534686;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  font-weight: 600;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
}

.itemWidth {
  width: 12% !important;
}

.wallet-checkbox {
  border-color: #5346868a !important;
}

.wallet-checkbox:checked {
  background-color: #534686 !important;
}

.staticContainer {
  display: flex;
}

.complainHeight {
  height: 359px;
}

.dashboardTable {
  max-height: 335px;
  overflow-y: auto;
}

@media only screen and (max-width: 1426px) {
  .dashboardTable {
    max-height: 360px;
  }
}

@media only screen and (max-width: 1400px) {
  .dashboardTable {
    max-height: 260px;
  }
}

@media only screen and (max-width: 950px) {
  .cardWidth {
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .personalBusinessDetails {
    margin-top: 20px !important;
  }
}

@media only screen and (min-width: 376px) {
  .filterGap {
    gap: 1rem !important;
  }
}

.custom-span {
  display: inline-block;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .personalBusinessDetails {
    padding-top: 30px;
  }

  .searchFilter .form-control {
    max-width: 80%;
    /* Adjust the max-width of the search input */
  }

  .custom-search-filter {
    border-radius: 5px;
    padding: 10px;
  }

  .custom-search-input {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px;
  }

  .custom-filter-btn {
    width: 100%;
    padding: 10px;
    text-align: center;
  }
}

@media only screen and (max-width: 575px) {
  .customizer-links {
    position: fixed;
    right: 00px;
    top: 50%;
    z-index: 3;
    transform: translate(0, -50%);
    box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);

    >.nav {
      border-radius: 8px;
      background-color: $white;
    }

    &.open {
      right: 330px;
      border-radius: 8px 0 0 8px;
      transition: all 0.3s ease;
    }

    .nav-link {
      padding: 0;
      position: relative;

      .settings {
        z-index: 3;
        position: relative;
      }

      &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        color: $white;
        background-color: $primary-color;
        border-radius: 5px;
        transform: scale(0);
        transition: all 0.3s ease;
      }

      &:hover {
        i {
          color: $white;
        }

        &:before,
        &:after {
          opacity: 1;
          transform: scaleX(2);
          transition: all 0.3s ease;
        }

        span {
          opacity: 1;
          right: 48px;
          transform: scale(1);
          transition: all 0.3s ease;
        }
      }

      &+.nav-link {
        border-top: 1px solid $light-gray;
      }

      span {
        position: absolute;
        right: -60px;
        width: max-content;
        text-align: right;
        padding: 3px 7px;
        display: block;
        top: 0;
        border-radius: 5px;
        transform: scale(0);
        transition: all 0.3s ease;
        opacity: 0;
        color: var(--theme-default);
        background-color: lighten($primary-color, 25%);
        box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
      }

      i {
        font-size: 10px;
        padding: 5px;
        display: inline-block;
        cursor: pointer;
        // margin: 0;
        border-radius: 5px;
      }
    }
  }

  .wallet-checkbox {
    transform: scale(1.5) !important;
  }
}

.loader-transparent {
  position: fixed;
  z-index: 10;
  background: rgba(255, 255, 255, 0.299);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    display: block;
    position: relative;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: $primary-color;
    animation: loader 0.5s linear infinite reverse;

    &:before,
    &:after {
      content: "";
      position: absolute;
      border-radius: 50%;
      border: 3px solid transparent;
      border-top-color: $primary-color;
    }

    &:before {
      top: 5px;
      left: 5px;
      right: 5px;
      bottom: 5px;
      animation: loader 0.3s linear infinite forwards;
    }

    &:after {
      top: 15px;
      left: 15px;
      right: 15px;
      bottom: 15px;
      animation: loader 0.7s linear infinite reverse;
    }
  }

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

@media (max-width: 375px) {
  .payable-dues {
    padding-bottom: 20px !important;
  }
}

.sidebar-list:hover .according-menu {
  display: block !important;
}

.resendOtpClass {
  button {
    border: none;
    background-color: white;
    text-decoration: underline;
  }
}

.OtpClass {
  button {
    border: none;
    text-decoration: underline;
  }
}

/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.search-bar-loader {
  width: 25px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #534686 90%, #0000) 0/calc(100%/3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}

@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0)
  }
}

.font-size-fourteen {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
}

.subscription-features {
  height: 320px;
  padding: 25px;
  position: relative;
  font-size: 14px;

  .btn {
    bottom: 0;
    position: absolute;
    margin-bottom: 25px;
  }
}

.registrationButton {
  background-color: #181851 !important;
  color: white !important;
}

.bg-subscription-red {
  background-color: #DC0808;
}

.bg-subscription-orange {
  background-color: #E56809;
}

.bg-subscription-blue {
  background-color: #534686;
}

@media only screen and (max-width:575px) {
  .set-font-style {
    font-size: 10px !important;
  }
}

.table-responsive-set label {
  display: none;
}

.table-responsive-set {

  // display: none;
  .total-sub {
    text-align: end;
  }

  .price-sub {
    text-align: end;
  }

  .sub-smallscreen {
    display: none;
  }

}

.sales-order-table {
  label {
    display: none;
  }

  .quantity {
    text-align: center;
  }

  .price {
    text-align: end;
  }

  .discount {
    text-align: center;
  }

  .item-total {
    text-align: end;
  }

  .grand-total {
    text-align: end;
  }

  th:nth-child(7) {
    text-align: end;
  }
}

.modal-pop-up-width {
  max-width: 60% !important;


  @media (max-width: 574px) {
    max-width: 65% !important;
  }


  @media (max-width: 500px) {
    max-width: 92% !important;
  }
}


.swal2-checkbox {
  display: none !important;
}

@media (max-width: 767.98px) {
  .modal-pop-up-width {
    max-width: 93%;
  }

  .sub-plan p {
    font-size: 8px !important;
  }

  .table-responsive-set {
    label {
      display: block;
    }

    td {
      display: flex;
      flex-direction: column;
    }

    .total-sub {
      text-align: start;
    }

    .price-sub {
      text-align: start;
    }

    .sub-smallscreen {
      display: block;
    }

    .sub-grand-total {
      display: none;
    }

    .sub-grandtotal {
      display: flex;
      justify-content: flex-end;
    }


    td {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      padding: 0.5rem;
    }


    tr {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      border-bottom: 1px solid #dee2e6;
    }

    tfoot {
      display: flex;
      flex-direction: column;
    }

    thead {
      display: none;
    }
  }

  // sales order mobile responsive
  .sales-order-table {
    thead {
      display: none;
    }

    td {
      display: flex;
      flex-direction: column;
    }

    tfoot {
      display: flex;
      flex-direction: column;
    }

    tfoot tr {
      display: flex;
      flex-direction: column;
      border: none;
    }

    .quantity {
      text-align: start;
    }

    .price {
      text-align: start;
    }

    .discount {
      text-align: start;
    }

    .item-total {
      text-align: start;
    }

    .grand-total {
      text-align: start;
    }

  }

  label {
    display: block !important;
  }

  th:first-child {
    text-align: start !important;
  }

  th:nth-child(5) {
    display: none;
  }

  th {
    text-align: end !important;
  }


  .form-section {
    padding-left: 0px;
    padding-right: 0px;
  }


  .otp-input input {
    margin-right: 10px !important;
  }

  .parallel-calendar {
    display: flex;
    flex-direction: column;
  }


}


@media (max-width :475px) {
  .dropdown-basic {
    margin-right: -30px;
  }

  .button-spacing {
    margin-top: 5px;
  }

  .itemWidth {
    width: 100% !important;
  }

}

@media (max-width :417px) {
  .button-stbspace {
    margin-top: 5px;
  }

}

@media (max-width :768px) {
  .button-addsale {
    margin-left: 15px;
  }

}


@media (max-width :480px) {
  .button-addsale {
    margin-top: 10px;
  }

}

.master-avatar {
  width: 120px;
  height: 120px;
  max-width: 155px;
  max-height: 155px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  border: 7px solid rgba($primary-color, 0.08);
  margin-bottom: 15px;

  img {
    width: 100%;
    height: 100%;
  }
}

.user-profile {
  .profile-header {
    .userpro-box {
      .master-icon-wrapper {
        position: absolute;
        left: 224px;
        top: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        background-color: $white;
        cursor: pointer;
        overflow: hidden;
        margin: 0 auto;
        font-size: 17px;
        box-shadow: 0 0 6px 3px rgba(68, 102, 242, 0.1);

        i {
          color: $primary-color;
        }

        @media (max-width: 766px) {
          top: 29%;
          transform: translateY(-50%);
        }

        @media (max-width: 574px) {
          left: 52%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.signup-bg-color {
  background-color: #181851 !important;
  color: white !important;
}

@media (max-width :480px) {
  .custom-filtrs {
    padding-top: 10px;
  }

}

@media (max-width :480px) {
  .form-element {
    padding-top: 10px;
  }

}

.logo-footer {
  width: 80%;
  // height: 200px;
  padding-top: 20px;
}

.sub-card {
  .sub-card-left {
    background-image: url(../assets/images/sub-card-image/sub-card-image.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    height: 200px;
    padding: 20px;


    .card-header {
      display: flex;
      align-items: center;

      span {
        font-size: 18px;
        font-weight: 600;
        color: black;
      }

      .logo {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }

    }

    .card-body {
      padding-top: 30px !important;

      // top: 40px;
      .text {
        font-size: .6rem;
        font-weight: 600;

      }

      h4 {
        font-size: .8rem;
      }
    }

    .qr-code {
      background-color: white;
      padding: 10px;
      border-radius: 10px;

    }
  }

  .service-type {
    h2 {
      font-size: 1rem;
      color: #333;
      margin-top: 10px;
    }

  }
}

.sub-card-right {
  background-image: url(../assets/images/sub-card-image/sub-card-image.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  height: 200px;
  padding: 20px;

  .card-body {
    .text {
      font-size: .6rem;
      font-weight: 500;

    }

    h4 {
      font-size: .8rem;
      font-weight: 500;
      width: 160px;
    }
  }

  .service-type {
    background-color: #ffffffa6;
    padding: 20px;
    border-radius: 10px;

    h6 {
      font-weight: 600;
    }

    div {
      display: flex;
      flex-direction: column;

      .input-box {
        display: flex;
        flex-direction: row;
        line-height: 2;
      }

      label {
        font-size: 0.6rem;
        color: #000000;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        font-weight: 500;
        width: 70px;

        input {
          margin-left: auto;
        }
      }
    }
  }

}

@media print {
  .sub-card {
    width: 350px;
    margin: 20px;

    .service-type {
      div {
        label {
          span {
            width: 160px !important;
          }

          input {
            margin-left: auto !important;
          }
        }
      }
    }

  }
}

.custom-card {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.icon-small {
  width: 15px;
}


.custom-nav {
  justify-content: center;
  display: flex;
  gap: 15px;

  .nav-item {
    flex: 1;
  }

  .nav-link {
    background-color: #ffffff;
    color: #534686;
    border-radius: 20px;
    padding: 9px 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease, color 0.3s ease;
    display: block;
    margin-left: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-weight: 500;

    &:hover {
      transform: scale(1.05);
    }

    &.active {
      background: linear-gradient(90deg, #1EADFF, #068FFF);
      color: #ffffff;
    }

    @media (max-width: 1400px) {
      .custom-nav .nav-item .nav-link {
        font-size: 8px;
        margin-left: 0px;
      }
    }
  }


  @media (max-width: 700px) {
    flex-direction: column;
    gap: 10px;

    .nav-link {
      margin-left: 0;
      margin-bottom: 10px;
    }
  }

}

// tabs

.tab-link {
  padding: 10px 20px;
  text-decoration: none;
  position: relative;
  color: black;
  cursor: pointer;
}

.tab-link::before,
.tab-link::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
}

.tab-link::before {
  background-color: black;
  color: #534686;
  height: 1px;
  bottom: 0;
  left: 10px;
  right: 10px;
}

.tab-link::after {
  background-color: transparent;
  height: 1px;
  bottom: 0;
}

.tab-link.active {
  color: #049546 !important;
}

.tab-link.active::after {
  background-color: #049546;
  left: 0;
  right: 0;
}

.tab-link.active::before {
  background-color: transparent;
}

.tab-link:not(.active)::after {
  background-color: transparent;
}

.footer-icon {
  font-size: 24px;
  cursor: pointer;
  margin-left: 10px;


  &:hover {
    color: #0D95E8;
  }
}

.added-titlerow {
  background-color: #534686;
  color: #FFFFFF;
  padding: 10px 15px;
  font-weight: bold;
  font-size: 14px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 0;
  margin-top: 20px;

}

.vertical-line {
  width: 1px;
  height: 20px;
  background-color: #ddd;
  margin: 0 10px;
}

.support-history {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 10px 10px 10px;

}

.gradient-green {
  background: linear-gradient(to right, #059666, #10B981);
}

.gradient-blue {
  background: linear-gradient(90deg, #7062a4, #534686);
}

.blue {
  color: #534686;
}

.payment-success {
  margin-top: 130px;
}

.signin-container,
.signup-container {
  background-image:
    // linear-gradient(rgba(0, 0, 0, 0.133), rgba(0, 0, 0, 0)),
    /* Dark overlay */
    url('../assets/images/rks-background.png');

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  padding-top: 50px;
  // margin-top: 140px;

  .signin-heading {
    font-size: 36px;
    color: #ffffff;
    text-align: center;
    // padding-top: 50px;
  }

  .signin-subheading {
    font-size: 14px;
    color: #fff;
    margin-bottom: 20px;
    text-align: center;
  }

  .otp-input-component {
    padding: 9px 30px;

    .otp-button {

      button {
        color: #FFFFFF;
        background-color: #534686;
        transition: background-color 0.2s ease-in-out;

        &:hover {
          background-color: #534686;
        }

        &:disabled {
          background-color: #cccccc;
          cursor: not-allowed;
        }

      }
    }
  }

  .sign-in-card {
    // width: 70%;

    .signin-form-container {
      // display: flex;
      justify-content: center;
      padding: 20px 20px 50px 20px;

      .signin-form {
        width: 100%;

        input:not([type="checkbox"]) {
          width: 100%;
          padding: 12px;
          border: 1px solid #ccd0d5;
          border-radius: 8px;
          font-size: 16px;
          transition: border-color 0.2s ease-in-out;

          &:focus {
            border-color: #534686;
            outline: none;
          }
        }

        button {
          width: 100%;
          background-color: #534686;
          color: white;
          padding: 9px;
          font-size: 16px;
          border: none;
          border-radius: 8px;
          cursor: pointer;
          transition: background-color 0.2s ease-in-out;

          &:hover {
            background-color: #534686;
          }

          &:disabled {
            background-color: #cccccc;
            cursor: not-allowed;
          }
        }

        .button-quickpay {
          width: 100%;
          background-color: #ffffff;
          color: #534686;
          padding: 9px;
          font-size: 16px;
          border: 1.5px solid #534686;
          border-radius: 8px;
          cursor: pointer;
          transition: background-color 0.2s ease-in-out;

          &:hover {
            background-color: #534686;
            color: #ffffff;
          }

          &:disabled {
            background-color: #cccccc;
            cursor: not-allowed;
          }
        }


        .error {
          color: #e53e3e;
          font-size: 14px;
          margin-top: -10px;
        }
      }


      .signin-actions {
        display: flex;
        justify-content: space-between;

        .forgot-password {
          color: #534686;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .register-prompt {
        margin-bottom: -20px;
        text-align: center;

        p {
          font-size: 14px;

          a {
            color: #534686;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

    }

  }
}

.forget-btn {
  background-color: #D6F2FF !important;
  color: #48CBFF !important;


  &:hover {
    background-color: #534686 !important;
    color: #fff !important;
  }
}

.otp-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  input {
    width: 67px !important;
    height: 50px;
    text-align: center;
    font-size: 20px;
    border: 1px solid #ccd0d5;
    border-radius: 8px;
    margin: 0 5px;
    outline: none;
    transition: border-color 0.2s ease-in-out;

    &:focus {
      border-color: #534686;
    }


    &:focus-visible {
      box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.502)
    }
  }
}

.resend-code {
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
  color: #333;

  a {
    color: #534686;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.table-container {
  display: grid;
  width: 100%;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
}

.table-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* 4 equal columns */
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid s#ccc;
}

.table-header {
  background-color: #EFF6FF;
  font-weight: bold;
}

.view-click {
  background-color: #534686;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

.view-click:hover {
  background-color: #534686;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrows from number input in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.img-logo {
  width: 100%;
  max-width: 150px;
  height: auto;
  // display: block;
  margin: 0 100px;
}

.feedback-section {
  background-color: #fff3f5;
  font-weight: 500;

  .phno {
    font-weight: 600;
  }
}

.thank-you-section {
  background-color: #534686;
  color: #fff;
  font-weight: 500;
}

.print-only {
  display: none;
}

@media print {
  .print-only {
    display: table-cell !important;
    /* Ensures it appears in print */
  }

  .feedback-section {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #FFF3F5 !important;
    /* Light pink */
    color: black !important;
  }

  .thank-you-section {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: #534686 !important;
    /* Dark purple */
    color: #FFF !important;
  }

  .no-print {
    display: none !important;
  }

}