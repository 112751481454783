.height-equals {
  border: none;

  .card-boxes {
    margin: 0 auto;
    padding: 0 3rem;
    max-width: 1400px;

    @media (max-width: 992px) {
      padding: 0 2rem;
    }

    @media (max-width: 768px) {
      padding: 0 1rem;
    }

    @media (max-width: 576px) {
      padding: 0;
    }
  }

  .custom-card {
    background-color: white;
    padding: 20px 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;

    .card-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .data-title {
        font-size: 15px;
      }

      .title-icon {
        width: 50px;
        height: 50px;
        margin-right: 8px;
      }
    }

    .data-content {
      font-size: 18px;
      font-weight:700;
    }

    // Media Queries
    @media (max-width: 992px) {
      padding: 16px;

      .card-title {
        h5 {
          font-size: 1.1rem;
        }
      }

      p {
        font-size: 1.4rem;
      }
    }

    @media (max-width: 768px) {
      padding: 12px;

      .card-title {
        h5 {
          font-size: 1rem;
        }
      }

      .title-icon {
        width: 32px;
        height: 32px;
      }

      p {
        font-size: 1.3rem;
      }
    }

    @media (max-width: 576px) {
      padding: 10px;

      .card-title {
        h5 {
          font-size: 0.9rem;
        }
      }

      .title-icon {
        width: 28px;
        height: 28px;
      }

      p {
        font-size: 1.2rem;
      }
    }
  }

  .custom-nav {
    .nav-pills {
      background-color: transparent !important;

      .nav-link {
        background-color: transparent !important;
        border: none;

        &:hover {
          background-color: transparent !important;
        }
      }

      .nav-link.active {
        background-color: transparent !important;
        color: #534686;
      }
    }
  }
}

