 /*---------------------------
      Table of Contents
    -------------------------

    01- Global Styles
    02- Helper Classes
    03- Background & Colors
    04- Typography
    05- page title 
    06- Buttons
    07- Forms
    08- Tabs
    09- Icons
    10- Breadcrumb
    11- Pagination
    12- Lists
    13- Animations
    14- Alerts
    16- Carousel
    17- Preloader
    18- Header & Navbar 
    19- Accordions
    20- Banners
    21- Footer
    22- Call to Action
    23- Carousel 
    24- Slider
    26- Video
    27- Features
    28- Fancybox
    29- Team
    30- Testimonials
    31- Clients
    32- Blog
    33- Contact
    34- Pricing
    35- Sidebar
    36- About
    36- Services
    37- Gallery
    38- Shop
    39- Work Process
    40- Progress Bars

----------------------------*/
 /*-------------------------- 
      Global Styles
---------------------------*/
 :root {
   --font-size-base: 15px;
   --font-primary: "Quattrocento", sans-serif;
   --font-secondary: "Metrophobic", sans-serif;
   --color-primary: #049546;
   --color-primary-200: #472e85;
   --color-primary-300: #1c1a29;
   --color-primary-400: #1c3923;
   --color-primary-500: #049546;
   --color-primary-600: #6d677a;
   --color-primary-700: #472e85;
   --color-primary-800: #240e61;
   --color-secondary: #209dd8;
   --color-primary-rgb: 26, 126, 0;
   --color-secondary-rgb: 0, 38, 120;
   --color-white: #ffffff;
   --color-accent: #00226c;
   --color-dark: #222222;
   --color-light: #6d6fb3;
   --bs-gray: #677a74;
   --bs-gray-dark: #222222;
   --color-gray-100: #f9f9f9;
   --color-gray-200: #e9e9f0;
   --color-gray-300: #4e655d;
   --color-gray-400: #ccd3d1;
   --color-gray-500: #a6b2ae;
   --color-gray-600: #d9e3e0;
   --color-gray-700: #f8f8f2;
   --color-green-100: #d7f6f0;
   --color-primary-300-rgb: 1, 34, 23;
   /* overide Bootstrap color theme */
   --bs-primary: #1a7e00;
   --bs-secondary: #e4a400;
   --bs-success: #198754;
   --bs-warning: #ffc107;
   --bs-danger: #dc3545;
   --bs-light: #f8f9fa;
   --bs-dark: #212529;
   --bs-primary-rgb: 26, 126, 0;
   --bs-secondary-rgb: 0, 38, 120;
   --bs-success-rgb: 25, 135, 84;
   --bs-warning-rgb: 255, 193, 7;
   --bs-danger-rgb: 220, 53, 69;
   --bs-light-rgb: 248, 249, 250;
   --bs-dark-rgb: 33, 37, 41;
   --color-white-rgb: 255, 255, 255;
   --bs-body-line-height: 1.5;
   --bs-body-color: #212529;
   --bs-body-color-rgb: 33, 37, 41;
   --bs-secondary-color: rgba(33, 37, 41, 0.75);
   --bs-secondary-color-rgb: 33, 37, 41;
   --bs-secondary-bg: #e9ecef;
   --bs-secondary-bg-rgb: 233, 236, 239;
   --bs-link-color: #1a7e00;
   --bs-link-color-rgb: 13, 110, 253;
   --bs-link-decoration: none;
   --bs-link-hover-color: #e4a400;
   --bs-link-hover-color-rgb: 10, 88, 202;
   --bs-highlight-bg: #fff3cd;
   --bs-border-radius: 0.375rem;
   --bs-border-radius-sm: 0.25rem;
   --bs-border-radius-lg: 0.5rem;
   --bs-border-radius-xl: 1rem;
   --bs-border-radius-xxl: 2rem;
   --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
   --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
 }

 html {
   scroll-behavior: smooth;
 }

 body {
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   background-color: var(--color-white);
   font-family: var(--font-secondary);
   font-size: var(--font-size-base);
   color: var(--bs-gray);
   overflow-x: hidden;
 }

 ::-moz-selection {
   background-color: var(--color-primary);
   color: var(--color-white);
 }

 ::selection {
   background-color: var(--color-primary);
   color: var(--color-white);
 }

 a {
   color: var(--color-primary);
   text-decoration: none;
   transition: color 0.3s ease;
 }

 a:hover {
   color: var(--color-secondary);
   text-decoration: none;
 }

 section {
   position: relative;
   padding-top: 110px;
   padding-bottom: 110px;
 }

 img {
   max-width: 100%;
 }

 /*-------------------------
       RESET Default Styles
   --------------------------*/
 * {
   outline: none;
 }

 button {
   padding: 0;
   border: none;
   background-color: transparent;
 }

 select {
   background-color: transparent;
 }

 button,
 button:focus,
 .form-control,
 .form-control:focus {
   outline: none;
   -ms-box-shadow: none;
   -o-box-shadow: none;
   box-shadow: none;
 }

 textarea {
   resize: none;
 }

 .svg-pathes {
   position: absolute;
 }

 @media (max-width: 991px) {

   html,
   body {
     overflow-x: hidden;
   }
 }

 @media (min-width: 576px) and (max-width: 1200px) {
   .container {
     max-width: 100%;
   }
 }

 @media (min-width: 1300px) {
   .container {
     max-width: 1280px;
   }
 }

 @media (min-width: 1400px) {
   .row {
     --bs-gutter-x: 40px;
   }

   .container {
     max-width: 1320px;
   }
 }

 /* .btn{ border-radius: 50px!important;} */
 /*----------------------------
        Helper Classes
  ----------------------------*/
 .sticky-top {
   z-index: 500;
 }

 .row-gutter-0 {
   margin-left: 0;
   margin-right: 0;
 }

 .row-gutter-0>[class*=col] {
   padding-right: 0;
   padding-left: 0;
 }

 .text-2-lines {
   display: -webkit-box;
   -webkit-line-clamp: 2;
   line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: normal;
 }

 .rotate-right-45,
 .rotate-left-45 {
   transition: all 0.3s ease;
 }

 .rotate-right-45 {
   transform: rotate(45deg);
 }

 .rotate-left-45 {
   transform: rotate(-45deg);
 }

 .mw-500 {
   max-width: 500px;
 }

 .flex-1 {
   flex: 1;
 }

 .fz-13 {
   font-size: 13px !important;
 }

 .fz-14 {
   font-size: 14px !important;
 }

 .fz-16 {
   font-size: 16px !important;
 }

 .fz-17 {
   font-size: 17px !important;
 }

 .fz-25 {
   font-size: 25px !important;
 }

 .font-secondary {
   font-family: var(--font-secondary);
 }

 .align-v {
   display: flex !important;
   align-items: center !important;
 }

 .align-v-h {
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
 }

 .border-top {
   border-top: 1px solid #e7ebef !important;
 }

 .border-bottom {
   border-bottom: 1px solid #e7ebef !important;
 }

 .sticky-top {
   top: 100px;
 }

 .z-index-1 {
   z-index: 1;
 }

 .fw-600 {
   font-weight: 600;
 }

 .mw-350 {
   max-width: 350px;
 }

 .mw-none {
   max-width: none !important;
 }

 @media (min-width: 1300px) {
   .section-with-space-start .container-fluid {
     padding-inline-start: calc((100% - 1280px) / 2) !important;
     padding-inline-end: 0;
   }

   .section-with-space-end .container-fluid {
     padding-inline-end: calc((100% - 1280px) / 2) !important;
     padding-inline-start: 0;
   }
 }

 @media (min-width: 1400px) {
   .section-with-space-start .container-fluid {
     padding-inline-start: calc((100% - 1320px) / 2) !important;
   }

   .section-with-space-end .container-fluid {
     padding-inline-end: calc((100% - 1320px) / 2) !important;
   }
 }

 /*  margin Top */
 .mt-0 {
   margin-top: 0 !important;
 }

 .mt-5 {
   margin-top: 5px !important;
 }

 .mt-10 {
   margin-top: 10px !important;
 }

 .mt-15 {
   margin-top: 15px !important;
 }

 .mt-20 {
   margin-top: 20px !important;
 }

 .mt-30 {
   margin-top: 30px !important;
 }

 .mt-40 {
   margin-top: 40px !important;
 }

 .mt-50 {
   margin-top: 50px !important;
 }

 .mt-60 {
   margin-top: 60px !important;
 }

 .mt-70 {
   margin-top: 70px !important;
 }

 .mt-80 {
   margin-top: 80px !important;
 }

 .mt-90 {
   margin-top: 90px !important;
 }

 .mt-100 {
   margin-top: 100px !important;
 }

 /* Margin Bottom */
 .mb-0 {
   margin-bottom: 0 !important;
 }

 .mb-5 {
   margin-bottom: 5px !important;
 }

 .mb-10 {
   margin-bottom: 10px !important;
 }

 .mb-20 {
   margin-bottom: 20px !important;
 }

 .mb-30 {
   margin-bottom: 30px !important;
 }

 .mb-40 {
   margin-bottom: 40px !important;
 }

 .mb-45 {
   margin-bottom: 45px !important;
 }

 .mb-50 {
   margin-bottom: 50px !important;
 }

 .mb-60 {
   margin-bottom: 60px !important;
 }

 .mb-70 {
   margin-bottom: 70px !important;
 }

 .mb-80 {
   margin-bottom: 80px !important;
 }

 .mb-90 {
   margin-bottom: 90px !important;
 }

 .mb-100 {
   margin-bottom: 100px !important;
 }

 .mb-150 {
   margin-bottom: 150px !important;
 }

 /* Margin Right */
 .mr-0 {
   margin-right: 0 !important;
 }

 .mr-10 {
   margin-right: 10px !important;
 }

 .mr-20 {
   margin-right: 20px !important;
 }

 .mr-30 {
   margin-right: 30px !important;
 }

 .mr-40 {
   margin-right: 40px !important;
 }

 .mr-50 {
   margin-right: 50px !important;
 }

 /* Margin Left */
 .ml-0 {
   margin-left: 0 !important;
 }

 .ml-20 {
   margin-left: 20px !important;
 }

 .ml-30 {
   margin-left: 30px !important;
 }

 .ml-40 {
   margin-left: 40px !important;
 }

 .ml-50 {
   margin-left: 50px !important;
 }

 .mx-80 {
   margin-right: 80px;
   margin-left: 80px;
 }

 /* padding Top */
 .pb-10 {
   padding-top: 10px !important;
 }

 .pt-20 {
   padding-top: 20px !important;
 }

 .pt-30 {
   padding-top: 30px !important;
 }

 .pt-40 {
   padding-top: 40px !important;
 }

 .pt-50 {
   padding-top: 50px !important;
 }

 .pt-60 {
   padding-top: 60px !important;
 }

 .pt-70 {
   padding-top: 70px !important;
 }

 .pt-80 {
   padding-top: 80px !important;
 }

 .pt-90 {
   padding-top: 90px !important;
 }

 .pt-100 {
   padding-top: 100px !important;
 }

 .pt-110 {
   padding-top: 110px !important;
 }

 .pt-120 {
   padding-top: 120px !important;
 }

 .pt-130 {
   padding-top: 130px !important;
 }

 .pt-140 {
   padding-top: 140px !important;
 }

 .pt-150 {
   padding-top: 150px !important;
 }

 .pt-170 {
   padding-top: 170px !important;
 }

 .pt-220 {
   padding-top: 220 !important;
 }

 /*  Padding Bottom */
 .pb-10 {
   padding-bottom: 10px !important;
 }

 .pb-20 {
   padding-bottom: 20px !important;
 }

 .pb-30 {
   padding-bottom: 30px !important;
 }

 .pb-40 {
   padding-bottom: 40px !important;
 }

 .pb-50 {
   padding-bottom: 50px !important;
 }

 .pb-60 {
   padding-bottom: 60px !important;
 }

 .pb-70 {
   padding-bottom: 70px !important;
 }

 .pb-80 {
   padding-bottom: 80px !important;
 }

 .pb-90 {
   padding-bottom: 90px !important;
 }

 .pb-100 {
   padding-bottom: 100px !important;
 }

 .pb-110 {
   padding-bottom: 110px !important;
 }

 .pb-120 {
   padding-bottom: 120px !important;
 }

 .pb-130 {
   padding-bottom: 130px !important;
 }

 .pb-140 {
   padding-bottom: 140px !important;
 }

 .pb-150 {
   padding-bottom: 150px !important;
 }

 .pb-170 {
   padding-bottom: 170px !important;
 }

 .pb-120 {
   padding-bottom: 120px !important;
 }

 .pb-220 {
   padding-bottom: 220px !important;
 }

 /* padding Right */
 .pr-0 {
   padding-right: 0 !important;
 }

 .pr-15 {
   padding-right: 15px !important;
 }

 .pr-20 {
   padding-right: 20px !important;
 }

 .pr-30 {
   padding-right: 30px !important;
 }

 .pr-50 {
   padding-right: 50px !important;
 }

 .pr-60 {
   padding-right: 60px !important;
 }

 .pr-70 {
   padding-right: 70px !important;
 }

 .pr-100 {
   padding-right: 100px !important;
 }

 /* padding Left */
 .pl-0 {
   padding-left: 0 !important;
 }

 .pl-15 {
   padding-left: 15px !important;
 }

 .pl-20 {
   padding-left: 20px !important;
 }

 .pl-30 {
   padding-left: 30px !important;
 }

 .pl-40 {
   padding-left: 40px !important;
 }

 .pl-50 {
   padding-left: 50px !important;
 }

 @media (max-width: 992px) {
   .order-3-md {
     order: 3;
   }

   .mt-30 {
     margin-top: 20px !important;
   }

   .mt-40 {
     margin-top: 20px !important;
   }

   .mt-50 {
     margin-top: 25px !important;
   }

   .mt-60 {
     margin-top: 30px !important;
   }

   .mt-70 {
     margin-top: 35px !important;
   }

   .mt-80 {
     margin-top: 40px !important;
   }

   .mt-90 {
     margin-top: 45px !important;
   }

   .mt-100 {
     margin-top: 50px !important;
   }

   .mb-30 {
     margin-bottom: 15px !important;
   }

   .mb-40 {
     margin-bottom: 20px !important;
   }

   .mb-50 {
     margin-bottom: 25px !important;
   }

   .mb-60 {
     margin-bottom: 30px !important;
   }

   .mb-70 {
     margin-bottom: 35px !important;
   }

   .mb-80 {
     margin-bottom: 40px !important;
   }

   .mb-90 {
     margin-bottom: 45px !important;
   }

   .mb-100 {
     margin-bottom: 50px !important;
   }

   .mb-150 {
     margin-bottom: 75px !important;
   }

   /* Margin Right */
   .mr-30 {
     margin-right: 15px !important;
   }

   .mr-40 {
     margin-right: 20px !important;
   }

   .mr-50 {
     margin-right: 25px !important;
   }

   /* Margin Left */
   .ml-30 {
     margin-left: 15px !important;
   }

   .ml-40 {
     margin-left: 20px !important;
   }

   .ml-50 {
     margin-left: 25px !important;
   }

   /* padding Top */
   .pt-30 {
     padding-top: 15px !important;
   }

   .pt-40 {
     padding-top: 20px !important;
   }

   .pt-50 {
     padding-top: 25px !important;
   }

   .pt-60 {
     padding-top: 30px !important;
   }

   .pt-70 {
     padding-top: 35px !important;
   }

   .pt-80 {
     padding-top: 40px !important;
   }

   .pt-90 {
     padding-top: 45px !important;
   }

   .pt-100 {
     padding-top: 50px !important;
   }

   .pt-110 {
     padding-top: 55px !important;
   }

   .pt-120 {
     padding-top: 60px !important;
   }

   .pt-130 {
     padding-top: 70px !important;
   }

   .pt-140 {
     padding-top: 70px !important;
   }

   .pt-150 {
     padding-top: 75px !important;
   }

   .pt-160 {
     padding-top: 80px !important;
   }

   .pt-170 {
     padding-top: 85px !important;
   }

   .pt-220 {
     padding-top: 110px !important;
   }

   /*  Padding Bottom */
   .pb-30 {
     padding-bottom: 15px !important;
   }

   .pb-40 {
     padding-bottom: 20px !important;
   }

   .pb-50 {
     padding-bottom: 25px !important;
   }

   .pb-60 {
     padding-bottom: 30px !important;
   }

   .pb-70 {
     padding-bottom: 35px !important;
   }

   .pb-80 {
     padding-bottom: 40px !important;
   }

   .pb-90 {
     padding-bottom: 45px !important;
   }

   .pb-100 {
     padding-bottom: 50px !important;
   }

   .pb-110 {
     padding-bottom: 55px !important;
   }

   .pb-120 {
     padding-bottom: 60px !important;
   }

   .pb-130 {
     padding-bottom: 65px !important;
   }

   .pb-140 {
     padding-bottom: 70px !important;
   }

   .pb-150 {
     padding-bottom: 75px !important;
   }

   .pb-160 {
     padding-bottom: 80px !important;
   }

   .pb-170 {
     padding-bottom: 85px !important;
   }

   .pl-50 {
     padding-left: 25px !important;
   }

   .pb-220 {
     padding-bottom: 110px !important;
   }
 }

 @media (max-width: 991px) {
   .order-1-md {
     order: 1 !important;
   }

   .order-2-md {
     order: 2 !important;
   }
 }

 /* Medium Devices */
 @media (min-width: 768px) and (max-width: 991px) {
   section {
     padding-top: 60px;
     padding-bottom: 60px;
   }
 }

 /* Mobile Phones and tablets */
 @media (min-width: 320px) and (max-width: 767px) {
   section {
     padding-top: 50px;
     padding-bottom: 50px;
   }
 }

 /*-------------------------
     Background & Colors
  --------------------------*/
 .color-gray {
   color: var(--color-gray-100) !important;
 }

 .color-gray-400 {
   color: var(--color-gray-400) !important;
 }

 .color-dark {
   color: var(--color-dark) !important;
 }

 .color-primary,
 .color-primary-hover:hover {
   color: var(--color-primary) !important;
 }

 .color-primary-200 {
   color: var(--color-primary-200) !important;
 }

 a.color-primary:hover {
   color: var(--color-secondary) !important;
 }

 .color-secondary {
   color: var(--color-secondary) !important;
 }

 a.color-secondary:hover {
   color: var(--color-primary) !important;
 }

 .color-heading {
   color: var(--color-accent) !important;
 }

 .color-body {
   color: var(--bs-gray) !important;
 }

 .color-white,
 .color-white-hover:hover,
 a.color-white-hover:hover {
   color: var(--color-white) !important;
 }

 .bg-transparent {
   background-color: transparent;
 }

 .bg-primary-300 {
   background-color: var(--color-primary-300) !important;
 }

 .bg-primary-700 {
   background-color: var(--color-primary-700) !important;
 }

 .bg-gray-700 {
   background-color: var(--color-gray-700) !important;
 }

 .bg-white {
   background-color: var(--color-white) !important;
 }

 .bg-gray {
   background-color: var(--color-gray-100) !important;
 }

 .bg-dark {
   background-color: var(--color-dark) !important;
 }

 .bg-heading {
   background-color: var(--color-accent) !important;
 }

 .bg-primary {
   background-color: var(--color-primary) !important;
 }

 .bg-secondary {
   background-color: var(--color-secondary) !important;
 }

 .bg-img {
   position: relative;
   z-index: 1;
 }

 .background-size-auto {
   background-size: auto !important;
 }

 .bg-parallax {
   background-attachment: fixed;
 }

 .bg-overlay:before {
   content: "";
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 100%;
   z-index: -1;
   background-color: rgba(27, 26, 26, 0.25);
 }

 .bg-overlay-2:before {
   background-color: rgba(27, 26, 26, 0.15);
 }

 .bg-overlay-3:before {
   background-color: rgba(27, 26, 26, 0.05);
 }

 .bg-overlay-gradient:before {
   background-color: transparent;
   opacity: 0.65;
   background-image: linear-gradient(180deg, #010711 0%, rgba(27, 26, 26, 0.65) 34%);
 }

 .bg-overlay-primary-200-gradient:before {
   background-image: linear-gradient(to top, rgba(var(--color-primary-300-rgb), 0) 0%, var(--color-primary-300) 40%, var(--color-primary-300) 100%);
 }

 .bg-overlay-primary-200-gradient:after {
   content: "";
   position: absolute;
   top: 0;
   bottom: 0;
   right: 0;
   left: 0;
   z-index: -1;
   opacity: 0.85;
   background-color: var(--color-primary-300);
 }

 .bg-overlay-secondary:before {
   background-color: rgba(228, 164, 0, 0.93);
 }

 .bg-overlay-primary:before {
   background-color: rgba(26, 126, 0, 0.9);
 }

 // .bg-overlay-primary-gradient:before {
 //   opacity: 0.9;
 //   background-color: var(--color-primary);
 // }
 .bg-overlay-primary-gradient:after {
   --overlay-color: 26, 126, 0;
   content: "";
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: -1;
   background: linear-gradient(to bottom, rgba(var(--overlay-color), 1) 0%, rgba(var(--overlay-color), 1) 2%, rgba(var(--overlay-color), 0) 100%);
 }

 .fancybox-layout3 {
   position: relative;
   overflow: hidden;
 }

 .bg-overlay-secondary-gradient::before {
   content: "";
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   background-color: var(--color-primary);
   /* Black overlay with 50% opacity */
   z-index: -1;
   /* Ensure overlay is above the image */
 }

 .bg-img img {
   width: 100%;
   height: auto;
   display: block;
   position: absolute;
   z-index: 0;
 }


 .bg-overlay-secondary-gradient:before {
   opacity: 0.87;
   background-color: var(--color-primary-200);
 }

 .bg-overlay-secondary-gradient:after {
   --overlay-color: 228, 164, 0;
   content: "";
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: 1;
   background-image: linear-gradient(to bottom, rgba(var(--overlay-color), 0) 0%, var(--color-primary-200) 80%, var(--color-primary-200) 100%);
 }

 .bg-overlay-gray:before {
   opacity: 0.9;
   background-color: #f6f6f1;
 }

 .bg-overlay-gray:after {
   content: "";
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: -1;
   background: linear-gradient(to bottom, #f6f6f1 0%, #f6f6f1 20%, rgba(246, 246, 241, 0.04) 97%, rgba(246, 246, 241, 0) 100%);
 }

 .bg-gray-gradient {
   background-image: linear-gradient(90deg, rgba(6, 70, 178, 0) 0%, rgba(11, 173, 246, 0) 3%, rgb(246, 247, 248) 80%);
 }

 .has-pattern-bg:after {
   content: "";
   position: absolute;
   bottom: 0;
   right: 0;
   width: 100%;
   height: 377px;
   z-index: 1;
   opacity: 0.2;
   background-repeat: no-repeat;
   background-position: bottom right;
 }

 /*-------------------------
     Typography
  --------------------------*/
 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
   color: var(--color-primary-700);
   font-family: var(--font-primary);
   font-weight: 600;
   line-height: 1.4;
   margin-bottom: 20px;
 }

 p {
   line-height: 1.75;
   margin-bottom: 15px;
 }

 h1 {
   font-size: 52px;
 }

 h2 {
   font-size: 42px;
 }

 h3 {
   font-size: 38px;
 }

 h4 {
   font-size: 32px;
 }

 h5 {
   font-size: 24px;
 }

 h6 {
   font-size: 18px;
 }

 .heading-subtitle {
   font-family: var(--font-secondary);
   font-size: 16px;
   color: var(--color-secondary);
   margin-bottom: 12px;
 }

 .heading-title {
   font-size: 37px;
   margin-bottom: 20px;
 }

 .heading-title .underlined-text {
   border-bottom: 4px solid var(--color-primary);
 }

 .heading-desc {
   font-size: 16px;
   margin-bottom: 0;
 }

 .heading-layout2 .heading-title {
   font-size: 40px;
 }

 .heading-layout3 .heading-title {
   font-size: 50px;
 }

 .heading-light .heading-subtitle {
   color: #f0f1f4;
 }

 .heading-light .heading-title {
   color: var(--color-white);
 }

 .heading-light .heading-desc {
   color: var(--color-gray-100);
 }

 .text-block-title {
   font-size: 24px;
   margin-bottom: 14px;
 }

 .text-link {
   font-size: 14px;
   font-weight: 700;
 }

 .text-link a:not(.btn) {
   padding-bottom: 2px;
   border-bottom: 2px solid var(--color-primary);
 }

 .text-link a:not(.btn).color-secondary {
   border-bottom-color: var(--color-secondary);
 }

 .text-link:hover a:not(.btn) {
   color: var(--color-secondary);
   border-bottom-color: var(--color-secondary);
 }

 .text-link:hover a:not(.btn).color-secondary {
   color: var(--color-primary);
   border-bottom-color: var(--color-primary);
 }

 [class*=underlined-text] {
   padding-bottom: 2px;
   border-bottom: 2px solid;
 }

 .underlined-text,
 .underlined-text-primary {
   border-bottom-color: var(--color-primary);
 }

 .underlined-text-secondary {
   border-bottom-color: var(--color-secondary);
 }

 .heading-divider {
   height: 4px;
   margin-top: 14px;
   background-color: var(--color-secondary);
 }

 @media (min-width: 1200px) {
   .text-block-desc {
     font-size: 17px;
   }
 }

 @media (min-width: 768px) and (max-width: 991px) {
   .heading-title {
     font-size: 30px;
   }
 }

 /* Mobile Phones and tablets  */
 @media (min-width: 320px) and (max-width: 767px) {
   .heading-title {
     font-size: 20px;
     margin-bottom: 10px;
   }

   .heading-desc,
   .heading-subtitle,
   .text-link {
     font-size: 14px;
   }

   .heading-layout2 .heading-title {
     font-size: 22px;
   }

   .text-block-desc {
     font-size: 14px;
     line-height: 24px;
     margin-bottom: 10px;
   }
 }

 /*-------------------------
      page title 
  -------------------------*/
 .header-transparent+.page-title {
   margin-top: -135px;
 }

 .pagetitle-subheading {
   padding: 10px 20px;
   border-radius: 10px;
   color: var(--color-white);
   background-color: var(--color-primary);
   font-size: 16px;
   font-weight: 700;
   display: inline-block;
   margin-bottom: 15px;
 }

 .pagetitle-heading {
   font-size: 75px;
   line-height: 1.1;
   font-weight: 700;
   margin-bottom: 17px;
 }

 .pagetitle-desc {
   font-size: 17px;
   font-weight: 700;
   margin-bottom: 0;
 }

 .home-nav {
   position: absolute;
   bottom: 0;
   left: 50%;
   transform: translateX(-50%);
   z-index: 3;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 78px;
   height: 78px;
   border-radius: 50%;
   color: var(--color-white) !important;
   background-color: var(--color-secondary);
   border: 10px solid var(--color-white);
 }

 [class*=page-title] .btn,
 [class*=page-title] .video-btn {
   margin-top: 32px;
 }

 [class*=page-title].bg-overlay:before {
   background-color: rgba(1, 34, 23, 0.25);
   background-position: 0 0;
   background-size: 100% 100%;
   background-repeat: no-repeat;
   background-image: url('../assets/images/patterns/5.png');
 }

 [class*=page-title].bg-overlay:after {
   content: "";
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: -1;
   opacity: 0.7;
   background-image: linear-gradient(to bottom, var(--color-primary-200) 0%, var(--color-primary-200) 19%, rgba(18, 34, 29, 0) 65%, rgba(27, 26, 26, 0) 100%);
 }

 .page-title-light .breadcrumb-item {
   --bs-breadcrumb-divider-color: var(--color-white);
 }

 .page-title-light .pagetitle-heading,
 .page-title-light .breadcrumb-item a,
 .page-title-light .breadcrumb-item.active {
   color: var(--color-white);
 }

 .page-title-light .pagetitle-desc {
   color: var(--color-gray-100);
 }

 .page-title-layout1,
 .page-title-layout2 {
   padding-top: 140px;
   padding-bottom: 35px;
 }

 .page-title-layout2 .pagetitle-desc {
   max-width: 610px;
 }

 .page-title-layout3 {
   padding-top: 135px;
   padding-bottom: 115px;
 }

 .page-title-layout4 {
   padding-top: 130px;
 }

 .page-title-layout4 .breadcrumb-area {
   margin-top: 150px;
   padding: 22px 0;
   border-top: 1px solid rgba(255, 255, 255, 0.25);
 }

 .page-title-layout4 {
   padding-top: 150px;
   padding-bottom: 100px;
 }

 .page-title-layout4 .pagetitle-heading {
   font-size: 40px;
   font-weight: 600;
   line-height: 1.3;
 }

 .page-title-layout4 .pagetitle-categorey {
   display: inline-block;
   border-radius: 2px;
   padding: 5px 15px;
   margin-bottom: 27px;
   background-color: var(--color-secondary);
 }

 .page-title-layout4 .pagetitle-categorey a {
   position: relative;
   color: var(--color-white);
   margin-right: 3px;
   padding-right: 3px;
 }

 .page-title-layout4 .pagetitle-categorey a:after {
   content: ",";
   position: absolute;
   top: 0;
   right: -2px;
   color: var(--color-white);
 }

 .page-title-layout4 .pagetitle-categorey a:last-child {
   margin-right: 0;
   padding-right: 0;
 }

 .page-title-layout4 .pagetitle-categorey a:last-child:after {
   display: none;
 }

 .page-title-layout4 .pagetitle-categorey a:hover {
   color: var(--color-secondary);
 }

 .page-title-layout5 .breadcrumb-area {
   padding: 22px 0;
   margin-bottom: 70px;
   border-bottom: 1px solid rgba(103, 122, 116, 0.25);
 }

 @media (min-width: 992px) and (max-width: 1199px) {
   .pagetitle-heading {
     font-size: 60px;
   }
 }

 @media (min-width: 992px) {
   [class*=page-title] .btn:not(.btn-xl) {
     height: 68px;
     min-width: 190px;
   }
 }

 @media (max-width: 991px) {

   .page-title-layout1,
   .page-title-layout2,
   .page-title-layout3 {
     padding-top: 50px;
   }

   .page-title-layout3 {
     padding-bottom: 40px;
   }

   .pagetitle-heading {
     font-size: 40px;
     margin-bottom: 10px;
   }

   .pagetitle-desc {
     font-size: 15px;
     font-weight: 400;
   }
 }

 /* Mobile Phones and tablets */
 @media (min-width: 320px) and (max-width: 767px) {
   .pagetitle-desc {
     font-size: 16px;
   }
 }

 /*--------------------
     Buttons
  ---------------------*/
 // .btn {
 //   text-transform: capitalize;
 //   position: relative;
 //   z-index: 1;
 //   font-size: 15px;
 //   font-weight: 700;
 //   min-width: 195px;
 //   height: 58px;
 //   display: inline-flex;
 //   align-items: center;
 //   justify-content: center;
 //   text-align: center;
 //   padding: 0 20px;
 //   border: 0;
 //   border-radius: 0;
 //   transition: all 0.3s ease;
 // }
 // .btn:focus, .btn.active, .btn:active {
 //   box-shadow: none;
 //   outline: none;
 // }
 // .btn .icon-filled,
 // .btn .icon-outlined {
 //   width: 26px;
 //   height: 26px;
 //   border-radius: 50%;
 //   display: inline-flex;
 //   align-items: center;
 //   justify-content: center;
 //   margin-left: 5px;
 //   transition: all 0.3s linear;
 // }
 // .btn:not(.btn-link) span,
 // .btn:not(.btn-link) i {
 //   margin: 0 5px;
 // }
 // .btn .icon-right-up {
 //   transition: all 0.3s ease;
 // }
 // .btn:hover .icon-right-up {
 //   transform: rotate(45deg);
 // }

 // .btn-primary {
 //   color: var(--color-white);
 //   background-color: var(--color-primary);
 // }
 // .btn-primary.btn-outlined {
 //   color: var(--color-primary);
 //   background-color: transparent;
 //   border: 2px solid var(--color-primary);
 // }
 // .btn-primary.btn-outlined:hover {
 //   color: var(--color-white);
 //   background-color: var(--color-primary);
 // }
 // .btn-primary-style2 {
 //   color: var(--color-white);
 //   background-color: var(--color-primary-200);
 // }
 // .btn-primary-style2:hover {
 //   color: var(--color-white);
 //   background-color: var(--color-primary);
 // }
 // .btn-primary:active, .btn-primary:focus, .btn-primary:not(.btn-outlined):hover {
 //   color: var(--color-white);
 //   border-color: var(--color-secondary);
 //   background-color: var(--color-secondary);
 // }

 // .btn-primary-200 {
 //   color: var(--color-white);
 //   background-color: var(--color-primary-200);
 // }
 // .btn-primary-200.btn-outlined {
 //   color: var(--color-primary-200);
 //   background-color: transparent;
 //   border: 2px solid #81a297;
 // }
 // .btn-primary-200.btn-outlined:hover {
 //   color: var(--color-white);
 //   border-color: var(--color-primary-200);
 //   background-color: var(--color-primary-200);
 // }
 // .btn-primary-200:active, .btn-primary-200:focus, .btn-primary-200:not(.btn-outlined):hover {
 //   color: var(--color-white);
 //   border-color: var(--color-primary);
 //   background-color: var(--color-primary);
 // }

 .btn-secondary {
   color: var(--color-white);
   background-color: var(--color-secondary);
 }

 .btn-secondary:hover {
   color: var(--color-white);
 }

 .btn-secondary.btn-outlined {
   color: var(--color-secondary);
   background-color: transparent;
   border: 2px solid #b2bdd6;
 }

 .btn-secondary.btn-outlined:hover {
   color: var(--color-white);
   border-color: var(--color-secondary);
   background-color: var(--color-secondary);
 }

 .btn-secondary:active,
 .btn-secondary:focus,
 .btn-secondary:hover:not(.btn-link),
 .btn-secondary:hover:not(.btn-underlined) {
   color: var(--color-white);
   border-color: var(--color-primary);
   background-color: var(--color-primary);
 }

 .btn-secondary-style2:active,
 .btn-secondary-style2:focus,
 .btn-secondary-style2:not(.btn-outlined):hover {
   color: var(--color-secondary);
   background-color: var(--color-white);
 }

 .btn-white {
   color: var(--color-primary-200);
   background-color: var(--color-white);
 }

 .btn-white:active,
 .btn-white:focus {
   color: var(--color-primary-500);
   border-color: var(--color-white);
   background-color: var(--color-white);
 }

 .btn-white:hover:not(.btn-link),
 .btn-white:hover:not(.btn-underlined) {
   color: var(--color-white);
   background-color: var(--color-primary-500);
 }

 .btn-white.btn-outlined {
   color: var(--color-white);
   background-color: transparent;
   border: 2px solid var(--color-white);
 }

 .btn-white.btn-outlined:before {
   border-right-color: var(--color-white);
 }

 .btn-white.btn-outlined:hover {
   color: var(--color-primary);
   background-color: var(--color-white);
 }

 .btn-white-style2 {
   color: var(--color-primary-200);
   background-color: var(--color-white);
 }

 .btn-white-style2:hover {
   color: var(--color-white);
   background-color: var(--color-primary-200);
 }

 .btn-light {
   color: var(--color-secondary);
   background-color: var(--color-light);
 }

 .btn-light:active,
 .btn-light:focus {
   color: var(--color-secondary);
   border-color: var(--color-light);
   background-color: var(--color-light);
 }

 .btn-light:hover {
   color: var(--color-white);
   border-color: var(--color-white);
   background-color: var(--color-secondary);
 }

 .btn-light.btn-outlined {
   color: var(--color-white);
   background-color: transparent;
   border: 2px solid var(--color-light);
 }

 .btn-light.btn-outlined:before {
   border-right-color: var(--color-light);
 }

 .btn-light.btn-outlined:hover {
   color: var(--color-primary);
   border-color: var(--color-white);
   background-color: var(--color-white);
 }

 .btn-light.btn-outlined:hover:before {
   border-right-color: var(--color-white);
 }

 .btn-outlined {
   background-color: transparent;
 }

 .btn-link {
   min-width: 0;
   height: auto;
   padding: 0;
   text-decoration: none;
   border-color: transparent;
   background-color: transparent;
 }

 .btn-link:focus,
 .btn-link:active,
 .btn-link:hover {
   background-color: transparent;
 }

 .btn-link.btn-icon {
   height: auto;
   line-height: 1;
   padding: 0;
 }

 .btn-link.btn-primary {
   color: var(--color-primary);
 }

 .btn-link.btn-primary.btn-underlined {
   padding-bottom: 3px;
   border-bottom: 2px solid var(--color-primary) !important;
 }

 .btn-link.btn-primary:hover {
   color: var(--color-secondary) !important;
   background-color: transparent;
 }

 .btn-link.btn-primary:hover.btn-underlined {
   border-bottom-color: inherit !important;
 }

 .btn-link.btn-primary-200 {
   color: var(--color-primary-200);
 }

 .btn-link.btn-primary-200.btn-underlined {
   border-bottom: 2px solid var(--color-primary-200) !important;
 }

 .btn-link.btn-primary-200:hover {
   color: var(--color-primary) !important;
   background-color: transparent;
 }

 .btn-link.btn-primary-200:hover.btn-underlined {
   border-bottom-color: inherit !important;
 }

 .btn-link.btn-secondary {
   color: var(--color-secondary);
 }

 .btn-link.btn-secondary.btn-underlined {
   border-bottom: 2px solid var(--color-secondary);
 }

 .btn-link.btn-secondary:hover {
   color: var(--color-primary);
 }

 .btn-link.btn-secondary:hover.btn-underlined {
   border-bottom-color: inherit;
 }

 .btn-link.btn-white {
   color: var(--color-white);
 }

 .btn-link.btn-white.btn-underlined {
   border-bottom: 2px solid var(--color-white);
 }

 .btn-link.btn-white:hover {
   color: var(--color-secondary);
 }

 .btn-link.btn-white:hover.btn-underlined {
   border-color: var(--color-secondary);
 }

 .btn-underlined-primary {
   border-bottom-color: var(--color-primary) !important;
 }

 .btn-underlined-primary:hover {
   border-bottom-color: inherit !important;
 }

 .btn-underlined {
   padding-bottom: 2px;
 }

 .btn-block {
   width: 100%;
 }

 .btn-compacted {
   min-width: 175px;
 }

 .btn-wide {
   min-width: 250px;
 }

 .btn-social {
   height: 54px;
   line-height: 54px;
   width: 54px;
   min-width: 0 !important;
   font-weight: 400;
   color: var(--color-white);
   border-radius: 3px;
 }

 .btn-facebook {
   background-color: #4267b2;
 }

 .btn-twitter {
   background-color: #1da0f0;
 }

 .btn-google-plus {
   background-color: #ea4335;
 }

 .btn-lg {
   min-width: 200px;
 }

 .btn-xl {
   height: 70px;
   min-width: 250px;
 }

 .btn-xl i,
 .btn-xl span {
   margin: 0 7px;
 }

 .btn-xhight {
   height: 68px;
 }

 .btn-rounded {
   border-radius: 50px;
 }

 .btn-icon-custom {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 48px;
   height: 48px;
   flex: 0 0 48px;
   font-size: 16px;
   border-radius: 50%;
   margin: 10px;
   color: var(--color-white);
   background-color: var(--color-primary);
   box-shadow: 0 0 0 10px #f0f1f4;
 }

 /* Mobile Phones and tablets */
 @media (min-width: 320px) and (max-width: 767px) {
   .btn:not(.btn-link) {
     font-size: 13px;
     min-width: 120px;
     height: 55px;
   }

   .btn-icon {
     padding: 0 10px;
   }

   .btn-xl {
     height: 55px;
     min-width: 150px;
   }
 }

 /*---------------------------
          Forms
  ----------------------------*/
 .label-text {
   font-size: 14px;
   font-weight: 700;
   line-height: 1;
   margin-bottom: 10px;
   color: var(--color-primary-700);
 }

 label.error {
   color: red;
   font-size: 14px;
   font-weight: 400;
   margin: 7px 0 0 0;
 }

 .form-group {
   position: relative;
   margin-bottom: 30px;
 }

 .form-control {
   font-size: 14px;
   height: 58px;
   padding: 0 20px;
   border-radius: 2px;
   background-color: var(--color-white);
   border: 2px solid var(--color-gray-600);
 }

 .form-control:focus {
   border-color: var(--color-primary);
   background-color: var(--color-white);
 }

 .form-control::-webkit-input-placeholder {
   color: #26365e;
 }

 .form-control:-moz-placeholder {
   color: #26365e;
 }

 .form-control::-moz-placeholder {
   color: #26365e;
 }

 .form-control:-ms-input-placeholder {
   color: #26365e;
 }

 .custom-control {
   display: flex;
   align-items: center;
 }

 textarea.form-control {
   min-height: 120px;
   padding-top: 16px;
 }

 .form-title {
   font-size: 18px;
   line-height: 1;
   margin-bottom: 20px;
 }

 .custom-checkbox {
   position: relative;
 }

 .custom-checkbox input[type=checkbox],
 .custom-checkbox input[type=radio] {
   display: none;
 }

 .checkbox-controls .custom-checkbox {
   margin-right: 27px;
   margin-bottom: 20px;
 }

 .checkbox-controls .custom-checkbox label {
   color: var(--bs-gray);
 }

 /* Input Radio */
 .label-radio {
   display: block;
   position: relative;
   padding-left: 26px;
   margin-bottom: 0;
   cursor: pointer;
   font-size: 13px;
   font-weight: 400;
   color: var(--bs-gray);
 }

 .label-radio input {
   position: absolute;
   z-index: -1;
   opacity: 0;
 }

 .radio-indicator {
   position: absolute;
   top: -1px;
   left: 0;
   height: 17px;
   width: 17px;
   background: transparent;
   border: 2px solid #eaeaea;
   border-radius: 50%;
 }

 .label-radio input:checked~.radio-indicator {
   background: transparent;
 }

 .label-radio:hover input:not([disabled]):checked~.radio-indicator,
 .label-radio input:checked:focus~.radio-indicator {
   background: transparent;
 }

 .radio-indicator:after {
   content: "";
   position: absolute;
   display: none;
   left: 3px;
   top: 3px;
   height: 7px;
   width: 7px;
   border-radius: 50%;
   background: var(--color-primary);
 }

 .label-radio input:checked~.radio-indicator:after {
   display: block;
 }

 .custom-control-label {
   font-weight: 400;
   margin-bottom: 0;
   padding-left: 27px;
   cursor: pointer;
 }

 .custom-control-label:before {
   content: "";
   position: absolute;
   top: -2px;
   left: 0;
   width: 17px;
   height: 17px;
   border-radius: 1px;
   pointer-events: none;
   border: 2px solid #e7ebef;
   transition: all 0.3s ease;
 }

 .custom-control-input:checked~.custom-control-label::before,
 .custom-control-input:not(:disabled):active~.custom-control-label::before {
   border: 6px solid var(--color-primary);
 }

 .custom-control-input:focus~.custom-control-label::before {
   box-shadow: none;
 }

 input[type=checkbox],
 input[type=radio] {
   box-shadow: none !important;
 }

 .nice-select {
   z-index: 2;
   width: 100%;
   height: 58px;
   line-height: 58px;
   border-radius: 2px;
   margin-bottom: 30px;
 }

 .nice-select .list {
   width: 100%;
 }

 .nice-select .current,
 .nice-select .option {
   color: var(--bs-gray);
 }

 .form-group-date .form-group-icon {
   position: absolute;
   top: 53%;
   left: 20px;
   display: flex;
   align-items: center;
   pointer-events: none;
 }

 .form-group-date .form-control[type=date]::-webkit-inner-spin-button,
 .form-group-date .form-control[type=date]::-webkit-calendar-picker-indicator,
 .form-group-date .form-control[type=time]::-webkit-inner-spin-button,
 .form-group-date .form-control[type=time]::-webkit-calendar-picker-indicator {
   display: block;
   width: 100%;
   height: 100%;
   margin: 0;
 }

 ::-webkit-inner-spin-button,
 ::-webkit-calendar-picker-indicator {
   opacity: 0;
 }

 /* Extra Small Devices */
 @media (min-width: 320px) and (max-width: 767px) {

   .form-group,
   .nice-select {
     margin-bottom: 20px;
   }
 }

 /*--------------------
      Tabs
  --------------------*/
 .nav-tabs {
   border-bottom: none;
   justify-content: center;
 }

 .nav-tabs .nav-link {
   display: block;
   position: relative;
   padding: 15px 25px;
   margin-right: -2px;
   text-transform: capitalize;
   font-weight: 700;
   line-height: 1;
   font-size: 16px;
   color: var(--color-primary-200);
   border: 2px solid var(--color-gray-600);
   border-radius: 0;
 }

 .nav-tabs .nav-link:last-of-type {
   margin-right: 0;
 }

 .nav-tabs .nav-link:after {
   content: "";
   position: absolute;
   top: -2px;
   left: 0;
   width: 100%;
   height: 3px;
   background-color: var(--color-primary);
   transform: scale3d(0, 1, 1);
   transform-origin: 100% 50%;
   transition: transform 2s cubic-bezier(0.2, 1, 0.3, 1);
 }

 .nav-tabs .nav-link.active,
 .nav-tabs .nav-link:hover {
   color: var(--color-primary);
 }

 .nav-tabs .nav-link.active:after,
 .nav-tabs .nav-link:hover:after {
   transform: scale3d(1, 1, 1);
   transform-origin: 0 50%;
 }

 .nav-tabs-white .nav-link {
   color: var(--color-white);
 }

 .nav-tabs-white .nav-link.active,
 .nav-tabs-white .nav-link:hover {
   color: var(--color-white);
 }

 .nav-tabs-white .nav-link:after {
   background-color: var(--color-white);
 }

 /* Mobile Phones and tablets */
 @media (min-width: 320px) and (max-width: 767px) {
   .nav-tabs .nav-link {
     font-size: 13px;
   }
 }

 /*------------------- 
      Icons & clip paths
  ------------------*/
 .social-icons {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
 }

 .social-icons li {
   margin-right: 10px;
 }

 .social-icons li a {
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 50%;
   width: 40px;
   height: 40px;
   box-shadow: 0 0 0 2px var(--color-primary-200) inset;
   transition: all 0.4s linear;
 }

 .social-icons li a .social-icon {
   font-size: 20px;
   color: var(--color-primary-200);
   transition: all 0.4s linear;
 }

 .social-icons li a:hover {
   box-shadow: 0 0 0 2px var(--color-secondary) inset;
 }

 .social-icons li a:hover .social-icon {
   color: var(--color-secondary);
 }

 .social-icons li:last-of-type {
   margin-right: 0;
 }

 .icon-arrow-right,
 .icon-arrow-left {
   font-size: 65%;
 }

 .plus-icon {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 26px;
   height: 26px;
   border-radius: 50%;
   font-size: 22px;
   font-weight: 700;
   margin-right: 3px;
   font-style: normal;
   color: var(--color-white);
   background-color: var(--color-primary);
   transition: all 0.3s linear;
 }

 .rating .active {
   color: #f4572e;
 }

 .curve-top-shape {
   position: absolute;
   left: 0;
   right: 0;
   bottom: -1px;
   transform: rotate(180deg);
 }

 .curve-top-shape .curve-svg {
   height: 48px;
   width: 100%;
 }

 .curve-top-shape .curve-path {
   fill: var(--color-white);
   transform-origin: center;
   transform: rotateY(0);
 }

 .hexagon-clippath {
   -webkit-clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
   clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
 }

 .hexagon-clippath2 {
   -webkit-clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
   clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
 }

 .octagon-clippath {
   -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
   clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
 }

 .cms-octagon-clippath {
   filter: url(#rounded-clippath-filter);
 }

 /*-------------------------
      Breadcrumb
  --------------------------*/
 .breadcrumb {
   padding: 0;
   background-color: transparent;
 }

 .breadcrumb-item {
   position: relative;
   font-size: 14px;
   --bs-breadcrumb-divider-color: var(--color-primary-200);
 }

 .breadcrumb-item a {
   font-weight: 700;
   color: var(--bs-breadcrumb-divider-color);
 }

 .breadcrumb-item+.breadcrumb-item::before {
   font-family: "Font Awesome 5 Free";
   font-weight: 900;
   content: "\f105";
 }

 /* Mobile Phones and tablets */
 @media (min-width: 320px) and (max-width: 767px) {

   .breadcrumb-item a,
   .breadcrumb-item+.breadcrumb-item {
     font-size: 13px;
   }
 }

 /*----------------------
      Pagination
  -----------------------*/
 .pagination li {
   margin-right: 10px;
 }

 .pagination li:last-child {
   margin-right: 0;
 }

 .pagination li a {
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 18px;
   font-weight: 700;
   width: 58px;
   height: 58px;
   border-radius: 5px;
   color: var(--color-primary-200);
   border: 2px solid #81a297;
   background-color: var(--color-white);
   transition: all 0.3s ease-in-out;
 }

 .pagination li a:hover,
 .pagination li a.current {
   color: var(--color-white);
   border-color: var(--color-primary-500);
   background-color: var(--color-primary-500);
 }

 /* Mobile Phones and tablets */
 @media (min-width: 320px) and (max-width: 767px) {
   .pagination li a {
     font-size: 16px;
     width: 35px;
     height: 35px;
   }
 }

 /*-------------------
      lists
  -------------------*/
 .contact-list li {
   font-size: 14px;
   margin-bottom: 5px;
 }

 .contact-list li:last-child {
   margin-bottom: 0;
 }

 .contact-list li a {
   color: var(--bs-gray);
 }

 /*------------------------
      Animations
  -----------------------*/
 @keyframes ripple {
   60% {
     box-shadow: 0 0 rgba(255, 255, 255, 0.1), 0 0 0 15px rgba(255, 255, 255, 0.1), 0 0 0 30px rgba(255, 255, 255, 0.1);
   }

   100% {
     box-shadow: 0 0 0 15px rgba(255, 255, 255, 0), 0 0 0 30px rgba(255, 255, 255, 0), 0 0 0 50px rgba(255, 255, 255, 0);
   }
 }

 @keyframes spinner {
   0% {
     transform: rotate(0deg);
   }

   100% {
     transform: rotate(360deg);
   }
 }

 @keyframes slideTopDown {
   0% {
     transform: translateY(0);
   }

   100% {
     transform: translateY(-6px);
   }
 }

 @keyframes headerAnimation {
   0% {
     opacity: 0;
     transform: translateY(-100%);
   }

   100% {
     opacity: 1;
     transform: translateY(0);
   }
 }

 @keyframes zoomOut {
   50% {
     transform: scale(1.1);
   }
 }

 @keyframes bounce {

   0%,
   20%,
   50%,
   80%,
   100% {
     transform: translateY(0);
   }

   40% {
     transform: translateY(-10px);
   }

   60% {
     transform: translateY(-5px);
   }
 }

 /*------------------------
      Alerts
  --------------------------*/
 .alert-primary {
   padding: 30px;
   border-radius: 15px;
   color: var(--color-secondary);
   border-color: transparent;
   background-color: #e0f8f4;
 }

 .alert-primary .alert-title {
   font-size: 19px;
 }

 .alert-info {
   padding: 27px;
   color: var(--bs-gray);
   border-color: #eef7ff;
   background-color: #eef7ff;
 }

 /*----------------------
      Carousel
  -----------------------*/
 .slick-list {
   margin: 0 -20px;
 }

 .slick-slide {
   margin: 0 20px;
 }

 .m-slides-0 .slick-list,
 .m-slides-0 .slick-slide {
   margin: 0 !important;
 }

 .slick-arrow {
   position: absolute;
   top: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 3;
   width: 34px;
   height: 34px;
   font-size: 0;
   transition: all 0.3s linear;
   transform: translateY(-50%);
 }

 .slick-arrow:before {
   display: flex;
   align-items: center;
   justify-content: center;
   font-family: "icomoon";
   font-size: 10px;
   width: 34px;
   height: 34px;
   color: var(--color-primary-200);
   background-color: #e8ebea;
   transition: all 0.3s linear;
 }

 .slick-arrow:hover:before {
   color: var(--color-white);
   background-color: var(--color-primary-200);
 }

 .slick-arrow.slick-next {
   right: 0;
 }

 .slick-arrow.slick-next:before {
   content: "\e9d2";
 }

 .slick-arrow.slick-prev {
   left: 0;
 }

 .slick-arrow.slick-prev:before {
   content: "\e9ce";
 }

 .slick-dots {
   list-style: none;
   position: relative;
   z-index: 2;
   padding: 0;
   text-align: center;
   margin-bottom: 0;
 }

 .slick-dots li {
   display: inline-flex;
   position: relative;
   width: 30px;
   height: 6px;
   margin: 0 4px;
   transition: all 0.3s linear;
 }

 .slick-dots li button {
   display: block;
   font-size: 0;
   width: 30px;
   height: 6px;
   padding: 0;
   background-color: var(--color-primary-600);
   transition: all 0.2s linear;
 }

 .slick-dots li.slick-active {
   width: 40px;
 }

 .slick-dots li.slick-active button {
   width: 40px;
   background-color: var(--color-primary);
 }

 .carousel-dots-secondary .slick-dots li button {
   background-color: rgba(246, 247, 247, 0.6);
 }

 .carousel-dots-secondary .slick-dots li.slick-active button {
   background-color: var(--color-secondary);
 }

 .carousel-dots-light .slick-dots li button {
   background-color: rgba(255, 255, 255, 0.5);
 }

 .carousel-dots-light .slick-dots li.slick-active button {
   background-color: var(--color-white);
 }

 .carousel-section .slick-slide {
   margin: 20px;
   transition: opacity 0.3s ease, visibility 0.3s ease, box-shadow 0.4s ease, transform 0.5s ease;
 }

 .carousel-section .slick-slide:not(.slick-active) {
   opacity: 0;
   visibility: hidden;
 }

 .carousel-section .slick-list {
   margin: -20px;
   overflow: visible;
 }

 .carousel-section .slick-dots {
   margin-top: 20px;
 }

 /*----------------------
      Pre Loader
  -----------------------*/
 .preloader {
   position: fixed;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   width: 100%;
   height: 100%;
   z-index: 5000;
   background-color: var(--color-white);
   display: flex;
   justify-content: center;
   align-items: center;
 }

 .preloader .loading {
   position: relative;
   width: 80px;
   height: 80px;
 }

 .preloader .loading span {
   position: absolute;
   width: 64px;
   height: 64px;
   border: 5px solid #1a7e00;
   border-radius: 50%;
   animation: rotating 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
   border-color: var(--color-primary) transparent transparent transparent;
 }

 .preloader .loading span:nth-child(1) {
   animation-delay: -0.45s;
 }

 .preloader .loading span:nth-child(2) {
   animation-delay: -0.3s;
 }

 .preloader .loading span:nth-child(3) {
   animation-delay: -0.15s;
 }

 /* Rotating Animation */
 @keyframes rotating {
   0% {
     transform: rotate(0deg);
   }

   100% {
     transform: rotate(360deg);
   }
 }

 /*----------------------------
        Navbar & Header
-----------------------------*/
 .header {
   position: relative;
   z-index: 1000;
 }

 /* Navbar */
 .navbar {
   padding: 0;
   height: 100px;
   max-height: 100px;
   background-color: var(--color-white);
   /*  dropdown-menu  */
 }

 .navbar>.container,
 .navbar>.container-fluid {
   position: relative;
   height: 100px;
 }

 .navbar .navbar-brand {
   padding: 0;
   margin: 0;
   line-height: 100px;
 }

 .navbar .logo-light {
   display: none;
 }

 .navbar .navbar-toggler {
   padding: 0;
   border: none;
   border-radius: 0;
   width: 23px;
   position: relative;
 }

 .navbar .navbar-toggler .menu-lines {
   display: inline-block;
 }

 .navbar .navbar-toggler .menu-lines:before,
 .navbar .navbar-toggler .menu-lines:after {
   content: "";
   position: absolute;
   left: 0;
   width: 23px;
   height: 1px;
   display: inline-block;
   background-color: var(--color-secondary);
   transition: 0.3s ease;
 }

 .navbar .navbar-toggler .menu-lines:before {
   top: 0;
 }

 .navbar .navbar-toggler .menu-lines:after {
   top: 12px;
 }

 .navbar .navbar-toggler .menu-lines span {
   position: absolute;
   top: 6px;
   left: 0;
   width: 18px;
   height: 1px;
   background-color: var(--color-secondary);
 }

 .navbar .navbar-toggler.actived .menu-lines>span {
   opacity: 0;
 }

 .navbar .navbar-toggler.actived .menu-lines:before {
   top: 0;
   transform: rotate(-45deg);
 }

 .navbar .navbar-toggler.actived .menu-lines:after {
   top: 0;
   transform: rotate(45deg);
 }

 .navbar .nav-item {
   position: relative;
   margin-right: 40px;
 }

 .navbar .nav-item .nav-item-link {
   font-size: 15px;
   font-weight: 600;
   text-transform: capitalize;
   display: block;
   position: relative;
   color: var(--color-primary-200);
   line-height: 100px;
   letter-spacing: 0px;
   padding-right: 15px;
 }

 .navbar .nav-item .nav-item-link.active,
 .navbar .nav-item .nav-item-link:hover {
   color: var(--color-primary);
 }

 .navbar .nav-item .nav-item-link.active:before,
 .navbar .nav-item .nav-item-link:hover:before {
   width: 100%;
 }

 .navbar .nav-item:last-child {
   margin-right: 0;
 }

 .navbar .dropdown-toggle:after {
   content: "\f107";
   font-family: "Font Awesome 5 Free";
   font-weight: 900;
   border: none;
   vertical-align: middle;
   margin-left: 0;
   position: absolute;
   top: 52%;
   right: 0;
   transform: translateY(-50%);
   font-size: 11px;
 }

 .navbar .dropdown-menu {
   border-radius: 0;
   border: none;
   margin: 0;
   background-color: var(--color-primary-300);
   box-shadow: 0px 5px 83px 0px rgba(13, 14, 67, 0.12);
 }

 .navbar .dropdown-menu.mega-dropdown-menu {
   padding: 0;
 }

 .navbar .dropdown-menu.mega-dropdown-menu .nav-block {
   padding: 50px 20px 40px;
 }

 .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured {
   padding: 45px 50px 40px;
 }

 .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured>.nav {
   justify-content: space-between;
 }

 .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured .nav-title {
   font-size: 24px;
 }

 .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured .nav-desc {
   font-size: 14px;
 }

 .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured .nav-item {
   flex: 0 0 50%;
   max-width: 50%;
   margin-bottom: 10px;
 }

 .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured .nav-item .nav-item-link {
   color: var(--color-white);
   display: flex;
   align-items: center;
   font-weight: 700;
   line-height: 55px !important;
   margin-right: 15px;
 }

 .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured .nav-item .nav-item-link:before {
   background-color: #dfdfea;
 }

 .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured .nav-item .nav-item-link:hover {
   color: var(--color-secondary);
 }

 .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured .nav-item .nav-item-link:hover [class*=icon-] {
   background-color: var(--color-secondary);
 }

 .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured .nav-item [class*=icon-] {
   display: flex;
   align-items: center;
   justify-content: center;
   flex: 0 0 26px;
   width: 26px;
   height: 26px;
   border-radius: 50%;
   margin-right: 10px;
   color: var(--color-primary);
   background-color: var(--color-white);
   transition: 0.3s ease;
 }

 .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured .nav-block-inner {
   padding: 47px 50px 35px;
   border-radius: 10px;
   background-color: var(--color-primary);
 }

 .navbar .dropdown-menu .nav-item {
   padding: 0 40px;
   margin-right: 0;
 }

 .navbar .dropdown-menu .nav-item>.nav-item-link {
   font-size: 14px;
   color: var(--color-gray-500);
   text-transform: capitalize;
   font-weight: 400;
   line-height: 42px !important;
   white-space: nowrap;
   position: relative;
   padding-right: 0;
   border-bottom: 1px solid var(--color-primary-600);
 }

 .navbar .dropdown-menu .nav-item>.nav-item-link:before {
   content: "";
   position: absolute;
   top: auto;
   bottom: -1px;
   left: 0;
   width: 0;
   height: 1px;
   background-color: var(--color-white);
   transition: 0.5s ease;
 }

 .navbar .dropdown-menu .nav-item>.nav-item-link:after {
   content: "\e9cd";
   font-family: icomoon;
   opacity: 0;
   visibility: hidden;
   color: var(--color-white);
   display: inline-block;
   transform: translate(3px, 4px);
   transition: all 300ms ease-in-out 0ms;
 }

 .navbar .dropdown-menu .nav-item>.nav-item-link:hover {
   color: var(--color-white);
 }

 .navbar .dropdown-menu .nav-item>.nav-item-link:hover:before {
   width: 100%;
 }

 .navbar .dropdown-menu .nav-item>.nav-item-link:hover:after {
   opacity: 1;
   visibility: visible;
   transform: translate(3px, 2px);
 }

 .navbar .dropdown-menu .nav-item:last-child {
   margin-right: 0;
 }

 .navbar .dropdown-menu .nav-item:last-child>.nav-item-link {
   border-bottom: 0;
 }

 .navbar .dropdown-menu .nav-item:last-child>.nav-item-link:before {
   display: none;
 }

 .navbar .dropdown-menu .dropdown-toggle:after {
   right: 0;
 }

 .navbar .dropdown-menu .nav-title {
   font-size: 18px;
   margin-bottom: 8px;
 }

 .navbar .dropdown-menu.wide-dropdown-menu>.nav-item {
   padding: 0;
 }

 .navbar .dropdown-menu.wide-dropdown-menu .dropdown-menu-col {
   margin: 40px 0 27px;
   padding: 0;
 }

 /* navbar-actions */
 .navbar-actions>li {
   margin-left: 20px;
 }

 .navbar-actions>li:last-child {
   margin-right: 0;
 }

 .action-btn {
   color: var(--color-secondary);
 }

 .action-btn-search {
   font-size: 18px;
   color: var(--color-primary-200);
 }

 .lang-dropdown .dropdown-item {
   font-size: 14px;
   padding: 0.25rem 1rem;
 }

 .lang-dropdown .lang-dropdown-toggle {
   font-size: 14px;
   height: 40px;
   font-weight: 700;
   display: flex;
   align-items: center;
   padding-right: 15px;
   color: var(--color-white);
 }

 .lang-dropdown .lang-dropdown-toggle:after {
   font-family: "Font Awesome 5 Free";
   font-weight: 900;
   content: "\f107";
   border: 0;
   display: block !important;
 }

 .lang-dropdown .lang-dropdown-toggle i {
   font-size: 30px;
   margin-right: 7px;
 }

 .lang-dropdown .dropdown-menu {
   min-width: 100px !important;
   padding: 0.6rem 0 !important;
 }

 .lang-dropdown .dropdown-menu .dropdown-item {
   color: var(--bs-gray);
 }

 .lang-dropdown .dropdown-menu .dropdown-item:focus,
 .lang-dropdown .dropdown-menu .dropdown-item:hover {
   background-color: transparent;
   color: var(--color-primary);
 }

 .lang-dropdown .dropdown-menu .dropdown-item.active,
 .lang-dropdown .dropdown-menu .dropdown-item:active {
   background-color: transparent;
 }

 /* header topbar */
 .header-topbar {
   height: 40px;
   display: flex;
   align-items: center;
   background-color: var(--color-primary-700);
 }

 .header-topbar .header-topbar-links li {
   margin-right: 20px;
 }

 .header-topbar .header-topbar-links li a {
   position: relative;
   font-size: 13px;
   color: var(--color-white);
 }

 .header-topbar .header-topbar-links li a:before {
   content: "";
   position: absolute;
   left: 0;
   width: 0;
   bottom: 0;
   height: 1px;
   background-color: var(--color-secondary);
   transition: all 0.4s ease;
 }

 .header-topbar .header-topbar-links li a:hover {
   color: var(--color-secondary);
 }

 .header-topbar .header-topbar-links li a:hover:before {
   width: 100%;
 }

 .header-topbar .contact-list li {
   position: relative;
   display: flex;
   align-items: center;
   font-size: 13px;
   margin-right: 30px;
   margin-bottom: 0;
 }

 .header-topbar .contact-list li a:not(.btn) {
   color: #f4f4f4;
 }

 .header-topbar .contact-list li a:not(.btn):hover {
   color: var(--color-white);
 }

 .header-topbar .contact-list li>i {
   font-size: 16px;
   color: var(--color-primary);
   margin-right: 8px;
 }

 .header-topbar .contact-list li:last-child {
   margin-right: 0;
 }

 .header-topbar .social-icons li a {
   width: 20px;
   height: 20px;
   box-shadow: none;
 }

 .header-topbar .social-icons li a .social-icon {
   color: var(--color-secondary);
 }

 .header-topbar .social-icons li a:hover .social-icon {
   color: var(--color-white);
 }

 .header-topbar .header-topbar-search {
   position: relative;
 }

 .header-topbar .header-topbar-search .form-control {
   height: 30px;
   border: 0;
   border-radius: 0;
   color: var(--color-white);
   border: 2px solid;
   background-color: transparent;
   border-color: transparent transparent transparent rgba(255, 255, 255, 0.15);
 }

 .header-topbar .header-topbar-search .form-control:focus {
   border-color: rgba(255, 255, 255, 0.15) !important;
 }

 .header-topbar .header-topbar-search .form-control::-webkit-input-placeholder {
   color: var(--color-gray-100);
   font-style: italic;
   font-size: 13px;
 }

 .header-topbar .header-topbar-search .form-control:-moz-placeholder {
   color: var(--color-gray-100);
   font-style: italic;
   font-size: 13px;
 }

 .header-topbar .header-topbar-search .form-control::-moz-placeholder {
   color: var(--color-gray-100);
   font-style: italic;
   font-size: 13px;
 }

 .header-topbar .header-topbar-search .form-control:-ms-input-placeholder {
   color: var(--color-gray-100);
   font-style: italic;
   font-size: 13px;
 }

 .header-topbar .header-topbar-search .header-topbar-search-btn {
   position: absolute;
   right: 10px;
   top: 50%;
   transform: translateY(-50%);
   color: var(--color-white);
 }

 .header-topbar .lang-dropdown .lang-dropdown-toggle {
   padding: 0 10px;
 }

 .header-topbar .lang-dropdown .lang-dropdown-toggle img {
   margin-right: 7px;
 }

 .header-topbar .action-btn {
   color: var(--color-white) !important;
 }

 .topbar-nav li {
   margin-right: 15px;
 }

 .topbar-nav li a {
   font-size: 13px;
   color: var(--color-white);
 }

 .topbar-nav li a:hover {
   color: var(--color-secondary);
 }

 .topbar-nav li:last-child {
   margin-right: 0;
 }

 .header {
   box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.1);
 }

 .header .promo-text a {
   color: var(--color-primary) !important;
 }

 .header .promo-text a:hover {
   color: var(--color-white) !important;
 }

 .header-layout1 .contact-area {
   background-color: var(--color-primary-500);
 }

 .header-layout3 .promo-text {
   font-size: 13px;
 }

 .header-layout3 .contact-area {
   border-left: 1px solid #d9e0e9;
 }

 .header-layout3 .contact-phone .phone-number {
   white-space: nowrap;
   color: var(--color-primary-200);
 }

 .header-layout3 .contact-phone .contact-icon {
   box-shadow: 0 0 0 2px #81a297 inset;
 }

 .header-layout3 .contact-phone .contact-icon .icon-phone {
   background-color: var(--color-primary-200);
 }

 .header-layout3 .header-topbar .social-icons li a {
   width: auto;
   height: auto;
 }

 .header-layout3 .header-topbar .social-icons li a .social-icon {
   width: 20px;
   height: 20px;
   margin-right: 8px;
 }

 .header-layout3 .header-topbar .social-icons li a .social-text {
   color: var(--color-white);
 }

 .header-layout3 .header-topbar .social-icons li:not(:last-child) {
   margin-right: 20px;
 }

 /* sticky-navbar */
 .is-sticky {
   position: fixed;
   top: 0;
   right: 0;
   left: 0;
   width: 100%;
   z-index: 1040;
   border-bottom: 0 !important;
   height: 80px !important;
   max-height: 80px !important;
   background-color: var(--color-white) !important;
   box-shadow: 0 3px 4px rgba(0, 0, 0, 0.07);
   animation: headerAnimation 0.8s;
 }

 .is-sticky>.container,
 .is-sticky>.container-fluid {
   position: relative;
   height: 80px;
 }

 .is-sticky .side-nav-trigger {
   width: 80px;
   height: 80px;
 }

 .is-sticky .action-btn-cart,
 .is-sticky .navbar-brand {
   line-height: 80px;
 }

 .is-sticky .header-top-right {
   display: none !important;
 }

 .is-sticky .nav-item .nav-item-link {
   line-height: 80px !important;
 }

 .is-sticky .logo-light {
   display: none;
 }

 .is-sticky .logo-dark {
   display: inline-block;
 }

 .is-sticky .navbar-nav {
   margin-top: 0 !important;
 }

 /* miniPopup */
 .miniPopup {
   position: absolute;
   top: 100%;
   left: 0;
   z-index: 150;
   opacity: 0;
   visibility: hidden;
   width: 310px;
   padding: 40px;
   border-radius: 0 0 6px 6px;
   background-color: var(--color-white);
   box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
   transition: 0.3s ease;
   transform: translateY(20px);
 }

 .miniPopup.active {
   opacity: 1;
   visibility: visible;
   transform: translateY(0);
 }

 .miniPopup-language-trigger {
   position: relative;
   z-index: 151;
   height: 46px;
   margin-top: 14px;
   padding: 10px 20px 10px 0;
   border-radius: 0 10px 0 0;
   margin-left: 40px;
   color: var(--bs-gray);
   background-color: var(--color-white);
   transition: 0.3s ease;
 }

 .miniPopup-language-trigger:after {
   content: "\f078";
   font-family: "Font Awesome 5 Free";
   font-weight: 900;
   font-size: 11px;
   position: absolute;
   bottom: -1px;
   left: calc(50% - 10px);
   transform: translateX(-50%);
 }

 .miniPopup-language-trigger.active:after {
   content: "\f077";
 }

 .miniPopup-language-trigger .btn-shape {
   position: absolute;
   width: 38px;
   height: 100%;
   top: 0;
   left: -38px;
   z-index: -1;
   -webkit-clip-path: url(#curve-left);
   clip-path: url(#curve-left);
   background-color: var(--color-white);
   transition: 0.3s ease;
 }

 .miniPopup-language-trigger.shape-right {
   margin: 14px 40px 0 0;
   padding: 10px 0 10px 20px;
   border-radius: 10px 0 0 0;
 }

 .miniPopup-language-trigger.shape-right .btn-shape {
   left: auto;
   right: -37px;
   -webkit-clip-path: url(#curve-right);
   clip-path: url(#curve-right);
 }

 .miniPopup-language-area {
   position: relative;
 }

 .miniPopup-language-area:hover .miniPopup-language-trigger {
   color: var(--color-white);
   background-color: var(--color-primary);
 }

 .miniPopup-language-area:hover .miniPopup-language-trigger .btn-shape {
   background-color: var(--color-primary);
 }

 .miniPopup-language-area:hover .miniPopup-language {
   opacity: 1;
   visibility: visible;
   transform: translateY(0);
 }

 .miniPopup-language {
   width: 176px;
   padding: 5px;
 }

 .miniPopup-language li {
   padding: 10px 8px;
 }

 /* cart-minipopup */
 .cart-minipopup {
   width: 330px;
   padding: 40px;
   position: absolute;
   top: 100%;
   left: 0;
   z-index: 1055;
   visibility: hidden;
   opacity: 0;
   border-radius: 0 0 8px 8px;
   background-color: var(--color-primary-300);
   box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.05);
   transition: 0.4s ease;
   transform: translateY(10px);
 }

 .cart-minipopup.active {
   opacity: 1;
   visibility: visible;
   transform: translateY(0);
 }

 .cart-minipopup .cart-item {
   position: relative;
   display: flex;
   margin-bottom: 20px;
 }

 .cart-minipopup .cart-item .cart-img {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 50px;
   height: 50px;
   overflow: hidden;
   padding: 5px;
   border-radius: 4px;
   margin-inline-end: 15px;
   background-color: rgba(248, 248, 242, 0.85);
 }

 .cart-minipopup .cart-item .cart-img img {
   max-height: 100%;
 }

 .cart-minipopup .cart-item .cart-content {
   flex: 1;
   padding-right: 22px;
 }

 .cart-minipopup .cart-item .cart-title {
   display: block;
   color: var(--color-white);
   font-family: var(--font-primary);
   font-size: 16px;
   font-weight: 600;
   margin-bottom: 4px;
 }

 .cart-minipopup .cart-item .cart-price {
   color: var(--color-secondary);
   font-size: 15px;
   font-weight: 700;
   line-height: 1;
 }

 .cart-minipopup .cart-item .cart-delete {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 20px;
   height: 20px;
   line-height: 20px;
   border-radius: 50%;
   color: var(--color-white);
   background-color: var(--color-primary);
   cursor: pointer;
   font-style: normal;
   position: absolute;
   top: 15px;
   right: 0;
   transition: 0.4s ease;
 }

 .cart-minipopup .cart-item .cart-delete:hover {
   background-color: var(--color-primary-200);
 }

 .cart-minipopup .cart-total {
   font-family: var(--font-primary);
   color: var(--color-gray-400);
   font-size: 19px;
   line-height: 1;
   font-weight: 600;
   margin-bottom: 20px;
 }

 .cart-minipopup .cart-action .btn {
   min-width: 105px;
   height: 40px;
   line-height: 40px;
 }

 .action-btn-cart {
   position: relative;
   font-size: 18px;
   line-height: 100px;
   color: var(--color-primary-200);
 }

 .cart-counter {
   position: absolute;
   top: -10px;
   right: -10px;
   width: 19px;
   height: 19px;
   line-height: 19px;
   z-index: 3;
   font-size: 12px;
   text-align: center;
   border-radius: 50%;
   color: var(--color-white);
   background-color: var(--color-secondary);
 }

 /* search-popup */
 .search-popup {
   position: fixed;
   z-index: 2300;
   top: 0;
   right: 0;
   bottom: 0;
   width: 100%;
   height: 100%;
   opacity: 0;
   visibility: hidden;
   background-color: var(--color-white);
   transform: scale(0.7);
   transition: all 0.5s ease-in-out;
 }

 .search-popup.active {
   opacity: 1;
   visibility: visible;
   transform: scale(1);
 }

 .search-popup.active .search-popup-form {
   transform: translateY(-50%) scaleX(1);
 }

 .search-popup.inActive {
   opacity: 0;
   transition-delay: 0.5s;
   transform: scale(1);
 }

 .search-popup.inActive .search-popup-form {
   transition-delay: 0s;
   transform: translateY(-50%) scaleX(0);
 }

 .search-popup .search-popup-close {
   position: absolute;
   top: 50px;
   right: 50px;
   cursor: pointer;
   font-style: normal;
   width: 100px;
   height: 100px;
   line-height: 100px;
   text-align: center;
   border-radius: 3px;
   color: var(--color-white);
   background-color: var(--color-primary);
   transition: all 0.2s linear;
 }

 .search-popup .search-popup-close:hover {
   background-color: var(--color-secondary);
 }

 .search-popup .search-popup-form {
   position: absolute;
   top: 50%;
   left: 0;
   right: 0;
   width: 100%;
   max-width: 550px;
   margin: -40px auto 0;
   transition-delay: 0.5s;
   transition: all 0.5s ease-in-out;
   transform: translateY(-50%) scaleX(0);
 }

 .search-popup .search-popup-btn {
   position: absolute;
   top: 0;
   left: 0;
   line-height: 80px;
   text-align: center;
   font-size: 28px;
   cursor: pointer;
   color: var(--color-secondary);
   transition: 0.3s ease;
 }

 .search-popup .search-popup-btn:hover {
   color: var(--color-primary);
 }

 .search-popup .search-popup-form-input {
   font-family: var(--font-primary);
   font-weight: 300;
   font-size: 35px;
   z-index: 1;
   width: 100%;
   height: 80px;
   border: none;
   padding: 0 0 0 40px;
   color: var(--bs-gray);
   background: transparent;
   border-bottom: 2px solid #e7ebef;
   transition: all 0.3s ease;
 }

 .search-popup .search-popup-form-input::-webkit-input-placeholder {
   color: var(--bs-gray);
 }

 .search-popup .search-popup-form-input:-moz-placeholder {
   color: var(--bs-gray);
 }

 .search-popup .search-popup-form-input::-moz-placeholder {
   color: var(--bs-gray);
 }

 .search-popup .search-popup-form-input:-ms-input-placeholder {
   color: var(--bs-gray);
 }

 .btn-contact {
   height: 50px;
   min-width: 150px;
 }

 .btn-contact:hover {
   border-color: var(--color-secondary) !important;
   background-color: var(--color-secondary) !important;
 }

 .btn-contact.btn-primary-200.btn-outlined:hover {
   border-color: var(--color-primary) !important;
   background-color: var(--color-primary) !important;
 }

 .contact-area {
   position: relative;
   padding: 0 40px;
 }

 .contact-phone .contact-icon {
   position: relative;
   width: 54px;
   height: 54px;
   font-size: 17px;
   border-radius: 50%;
   padding: 8px;
   margin-right: 12px;
   box-shadow: 0 0 0 2px #76b466 inset;
 }

 .contact-phone .contact-icon:after {
   content: "";
   position: absolute;
   top: 50%;
   left: 50%;
   height: 30px;
   width: 30px;
   z-index: 3;
   border-radius: 50%;
   animation: ripple 1s infinite;
   transform: translate(-50%, -50%);
   box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
 }

 .contact-phone .contact-icon .icon-phone {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 38px;
   height: 38px;
   border-radius: 50%;
   color: var(--color-white);
   background-color: var(--color-primary-800);
 }

 .contact-phone .phone-number {
   font-family: var(--font-primary);
   font-size: 19px;
   font-weight: 500;
   white-space: nowrap;
   color: var(--color-white);
   transition: all 0.3s ease;
 }

 .contact-phone:hover .phone-number {
   color: var(--color-secondary);
 }

 .header-actions>li {
   margin-left: 30px;
 }

 .side-nav-trigger,
 .side-nav-close {
   display: flex;
   align-items: center;
   justify-content: center;
   position: relative;
   width: 100px;
   height: 100px;
   cursor: pointer;
   z-index: 1046;
   margin-inline-end: -40px;
   color: var(--color-white);
   background-color: var(--color-primary);
 }

 .side-nav-trigger .menu-lines,
 .side-nav-close .menu-lines {
   position: relative;
   width: 20px;
   height: 14px;
 }

 .side-nav-trigger .menu-lines:before,
 .side-nav-trigger .menu-lines:after,
 .side-nav-close .menu-lines:before,
 .side-nav-close .menu-lines:after {
   content: "";
   position: absolute;
   right: 0;
   left: 0;
   height: 2px;
   background-color: var(--color-white);
   transition: 0.3s ease;
 }

 .side-nav-trigger .menu-lines:before,
 .side-nav-close .menu-lines:before {
   top: 0;
 }

 .side-nav-trigger .menu-lines:after,
 .side-nav-close .menu-lines:after {
   bottom: 0;
 }

 .side-nav-close {
   position: absolute;
   top: 0;
   right: 40px;
 }

 .side-nav-close .menu-lines:before,
 .side-nav-close .menu-lines:after {
   top: 7px;
 }

 .side-nav-close .menu-lines:before {
   transform: rotate(-45deg);
 }

 .side-nav-close .menu-lines:after {
   transform: rotate(45deg);
 }

 .side-nav-menu {
   position: fixed;
   top: 0;
   right: 0;
   bottom: 0;
   width: 80%;
   max-width: 620px;
   z-index: 1045;
   overflow: hidden;
   opacity: 0;
   visibility: hidden;
   background-color: var(--color-primary-300);
   transform: translateX(100%);
   transition: all 0.5s ease;
 }

 .side-nav-menu .side-nav-content {
   padding: 100px;
   overflow-x: hidden;
   overflow-y: auto;
   margin-inline-end: -20px;
   padding-inline-end: 100px;
   height: calc(100% - 100px);
   max-height: calc(100vh - 50px);
   opacity: 0;
   transform: translateX(100%) scaleX(0);
   transition: all 0.4s ease-in-out 0.3s;
 }

 .side-nav-menu .side-nav-content .contact-list li {
   padding-bottom: 12px;
   margin-bottom: 12px;
   border-bottom: 1px solid var(--color-gray-600);
 }

 .side-nav-menu .side-nav-content .contact-list li:last-child {
   padding-bottom: 0;
   margin-bottom: 0;
   border-bottom: 0;
 }

 .side-nav-menu .side-nav-content .contact-list li a {
   font-size: 16px;
   font-weight: 600;
   padding-inline-start: 7px;
   color: var(--color-white);
 }

 .side-nav-menu.active {
   opacity: 1;
   visibility: visible;
   transform: translateX(0);
 }

 .side-nav-menu.active .side-nav-content {
   opacity: 1;
   transform: translateY(0) scaleX(1);
 }

 .side-nav-menu.inActive {
   opacity: 0;
   visibility: hidden;
   transition-delay: 0.5s;
   transform: translateX(100%);
 }

 .side-nav-menu.inActive .side-nav-content {
   opacity: 0;
   transform: translateX(100%) scaleX(0);
 }

 @media (min-width: 1200px) {
   .navbar>.container-fluid {
     padding-left: 50px;
     padding-right: 0;
   }

   .header-topbar>.container-fluid {
     padding-left: 50px;
     padding-right: 50px;
   }

   .header-layout3 .navbar-brand {
     padding-right: 40px;
     border-right: 1px solid #d9e0e9;
   }
 }

 @media (max-width: 1199px) {
   .header-topbar {
     display: none;
   }
 }

 @media (min-width: 1400px) {
   .navbar>.container-fluid {
     padding-left: 40px;
     padding-right: 0;
   }

   .header-topbar>.container-fluid {
     padding-left: 40px;
     padding-right: 40px;
   }
 }

 /* Large Size Screens */
 @media (min-width: 992px) and (max-width: 1200px) {
   .navbar .nav-item {
     margin-right: 20px;
   }
 }

 /* Mobile and Tablets */
 @media (max-width: 991px) {
   .navbar .navbar-toggler {
     position: absolute;
     right: 15px;
     height: 13px;
     z-index: 3;
   }

   .navbar .navbar-toggler:focus {
     box-shadow: none;
   }

   .navbar .collapse:not(.show) {
     display: block;
   }

   .navbar .navbar-nav {
     margin: 0 !important;
   }

   .navbar .nav-item {
     margin-right: 0;
   }

   .navbar .nav-item .nav-item-link {
     color: var(--color-dark);
     line-height: 35px !important;
     padding-left: 15px;
   }

   .navbar .nav-item .nav-item-link:hover {
     color: var(--color-primary);
   }

   .navbar .nav-item .nav-item-link:before {
     display: none;
   }

   .navbar .navbar-collapse {
     position: fixed;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     width: 100%;
     height: 100%;
     visibility: hidden;
     opacity: 0;
     background-color: rgba(0, 0, 0, 0.6);
     z-index: 1000;
     padding: 0;
     transition: 0.4s ease;
   }

   .navbar .navbar-collapse .navbar-nav {
     opacity: 0;
     width: 240px;
     height: 100%;
     overflow-y: auto;
     padding: 50px 0 20px;
     background-color: var(--color-white);
     transition: 0.3s ease;
     transition-delay: 0.4s;
     transform: translateX(-100%);
   }

   .navbar .navbar-collapse.menu-opened {
     opacity: 1;
     visibility: visible;
   }

   .navbar .navbar-collapse.menu-opened .navbar-nav {
     opacity: 1;
     transform: translateX(0);
   }

   .navbar .navbar-collapse.menu-opened .close-mobile-menu {
     opacity: 1;
     transform: scale(1);
   }

   .navbar .close-mobile-menu {
     position: absolute;
     top: 30px;
     right: 30px;
     z-index: 1100;
     width: 35px;
     height: 35px;
     line-height: 33px;
     text-align: center;
     border-radius: 50%;
     border: 1px solid #fff;
     color: #fff;
     cursor: pointer;
     z-index: 1060;
     opacity: 0;
     transform: scale(0.8);
     transition: all 0.4s ease;
     transition-delay: 0.5s;
   }

   .navbar .nav-item [data-bs-toggle=dropdown]:after {
     font-family: "Font Awesome 5 Free";
     font-weight: 900;
     position: absolute;
     right: 0;
     width: 50%;
     text-align: right;
     z-index: 2;
     padding-right: 15px;
     line-height: 35px;
     display: block !important;
   }

   .navbar .dropdown-toggle:after {
     display: none;
   }

   .navbar .nav-item [data-bs-toggle=dropdown].opened:after,
   .navbar .nav-item [data-bs-toggle=dropdown].show:after {
     content: "\f106";
   }

   .navbar .dropdown-menu {
     background-color: white;
   }

   .navbar .dropdown-menu .nav-item {
     padding: 0 15px 0 30px;
   }

   .navbar .dropdown-menu .nav-item .nav-item-link {
     padding-left: 0;
     border-bottom: none;
   }

   .navbar .dropdown-menu .dropdown-menu-col .nav-item {
     padding: 0;
   }

   .navbar .nav-item.dropdown-submenu>.dropdown-menu.show {
     padding-left: 10px;
   }

   .navbar .dropdown-submenu .dropdown-menu .nav-item {
     padding: 0 0 0 15px;
   }

   .navbar .navbar-nav .dropdown-menu.show {
     padding: 0;
   }

   .navbar .mega-dropdown-menu .container {
     max-width: none;
   }

   .navbar .mega-dropdown-menu>.nav-item {
     padding: 0 10px;
   }

   .navbar .mega-dropdown-menu .nav-item {
     padding: 0;
   }

   .navbar .dropdown-menu .nav-title {
     font-size: 15px;
   }

   .navbar .dropdown-menu.mega-dropdown-menu .nav-block {
     padding: 10px 0 0;
   }

   .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured {
     padding: 10px;
     height: auto;
   }

   .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured:after {
     display: none;
   }

   .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured .nav-title {
     font-size: 18px;
   }

   .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured .nav-desc {
     font-size: 13px;
   }

   .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured .nav-item {
     flex: 0 0 100%;
     max-width: 100%;
     margin-bottom: 0;
   }

   .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured .nav-item .nav-item-link {
     line-height: 40px !important;
   }

   .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured .nav-item [class*=icon-] {
     width: 20px;
     height: 20px;
     flex: 0 0 20px;
     margin-right: 10px;
   }

   .navbar .dropdown-menu.mega-dropdown-menu .nav-block-featured .nav-block-inner {
     padding: 10px;
     margin: -10px;
   }

   .header .navbar,
   .header-transparent .navbar {
     background-color: var(--color-white);
   }

   .header .logo-dark,
   .header-transparent .logo-dark {
     display: inline-block;
   }

   .header .logo-light,
   .header-transparent .logo-light {
     display: none;
   }

   .header .module-btn,
   .header-transparent .module-btn {
     color: var(--color-secondary);
     margin: 0 60px 0 0;
   }

   .header .navbar .navbar-toggler .menu-lines:before,
   .header .navbar .navbar-toggler .menu-lines:after,
   .header .navbar .navbar-toggler .menu-lines span,
   .header-transparent .navbar .navbar-toggler .menu-lines:before,
   .header-transparent .navbar .navbar-toggler .menu-lines:after,
   .header-transparent .navbar .navbar-toggler .menu-lines span {
     background-color: var(--color-secondary);
   }

   .header .navbar-expand-lg>.container,
   .header .navbar-expand-lg>.container-fluid,
   .header-transparent .navbar-expand-lg>.container,
   .header-transparent .navbar-expand-lg>.container-fluid {
     width: 100%;
     max-width: none;
   }

   .header .navbar,
   .header .navbar>.container,
   .header .navbar>.container-fluid,
   .header-transparent .navbar,
   .header-transparent .navbar>.container,
   .header-transparent .navbar>.container-fluid {
     height: 80px;
   }

   .header .header-full .navbar,
   .header .header-full .navbar .navbar-brand,
   .header .navbar-brand,
   .header-transparent .header-full .navbar,
   .header-transparent .header-full .navbar .navbar-brand,
   .header-transparent .navbar-brand {
     margin-left: 15px;
     line-height: 80px !important;
   }

   .header .header-full .navbar,
   .header .header-full .navbar .navbar-brand,
   .header-transparent .header-full .navbar,
   .header-transparent .header-full .navbar .navbar-brand {
     line-height: 80px;
   }

   .header-topbar>.container {
     max-width: none;
   }

   .lang-dropdown .lang-dropdown-toggle,
   .header-transparent .action-btn {
     color: var(--color-secondary);
   }

   .action-btn-search {
     position: absolute;
     top: 50%;
     right: 80px;
     transform: translateY(-50%);
   }

   .contact-area {
     z-index: 1;
     padding: 0;
   }

   .side-nav-trigger {
     width: 80px;
     height: 80px;
     margin-inline-end: 40px;
   }

   .side-nav-close {
     top: 40px;
     width: 40px;
     height: 40px;
     margin-inline-end: 0;
   }

   .side-nav-menu .side-nav-content {
     padding: 90px 40px 40px;
     padding-inline-end: 40px;
     height: calc(100% - 40px);
   }
 }

 /* Medium and large Screens */
 @media (min-width: 992px) {
   .navbar .nav-item .nav-item-link:before {
     content: "";
     position: absolute;
     left: 0;
     width: 0;
     bottom: 0;
     height: 2px;
     background-color: var(--color-primary);
     transition: 0.4s ease;
   }

   .navbar .nav-item .nav-item-link.active:before,
   .navbar .nav-item .nav-item-link:hover:before {
     width: 100%;
   }

   .navbar .dropdown-menu {
     width: auto;
     min-width: 235px;
     padding: 25px 0 23px;
     border-radius: 0 0 4px 4px;
   }

   .navbar .dropdown-title {
     font-size: 17px;
     margin: 0 0 13px 40px;
     color: var(--color-white);
   }

   .navbar .nav-item.has-dropdown>.dropdown-menu,
   .navbar .nav-item.dropdown-submenu>.mega-menu,
   .navbar .nav-item.has-dropdown>.mega-menu,
   .navbar .nav-item.has-dropdown>.dropdown-menu>.nav-item.dropdown-submenu>.dropdown-menu {
     display: block;
     position: absolute;
     left: 0;
     right: auto;
     z-index: 1050;
     opacity: 0;
     visibility: hidden;
     transition: all 0.5s ease;
     transform: translateY(10px);
   }

   .navbar .nav-item.has-dropdown>.dropdown-menu>.nav-item.dropdown-submenu>.dropdown-menu,
   .navbar .nav-item.dropdown-submenu>.dropdown-menu>.nav-item.has-dropdown>.dropdown-menu {
     top: 0;
     left: 100%;
   }

   .navbar .nav-item.has-dropdown:hover>.dropdown-menu,
   .navbar .nav-item.dropdown-submenu:hover>.mega-menu,
   .navbar .nav-item.has-dropdown:hover>.mega-menu,
   .navbar .nav-item.has-dropdown>.dropdown-menu>.nav-item.dropdown-submenu:hover>.dropdown-menu {
     opacity: 1;
     visibility: visible;
     transform: translateY(0);
   }

   .navbar .nav-item.has-dropdown.has-mega-dropdown {
     position: static;
   }

   .navbar .nav-item.has-dropdown .mega-dropdown-menu {
     width: 100%;
   }

   .navbar .nav-item.has-dropdown .mega-dropdown-menu .container {
     display: block;
     max-width: none;
     padding-left: 50px;
   }

   .navbar .dropdown-menu.mega-dropdown-menu .nav-item {
     padding: 0;
   }

   .navbar .dropdown-menu.wide-dropdown-menu {
     min-width: 512px;
     padding: 0;
     overflow: hidden;
   }

   .nav-item i[data-bs-toggle=dropdown] {
     display: none;
   }
 }

 /* Mobile Phones and tablets */
 @media (min-width: 320px) and (max-width: 767px) {
   .dropdown-menu.mega-dropdown-menu>.nav-item {
     padding: 0 15px;
   }

   .dropdown-menu.mega-dropdown-menu .nav-item:last-child>.nav-item-link {
     border-bottom: 1px solid #f2f2f2;
   }

   .dropdown-menu.mega-dropdown-menu [class^=col-]:last-child .nav-item:last-child>.nav-item-link {
     border-bottom: none;
   }
 }

 /* Extra Small Devices */
 @media (min-width: 320px) and (max-width: 575px) {
   .search-popup .search-popup-form {
     width: 90%;
   }

   .search-popup .search-popup-form-input {
     font-size: 20px;
     font-weight: 400;
     height: 40px;
     padding: 0 0 0 30px;
   }

   .search-popup .search-popup-btn {
     font-size: 20px;
     line-height: 40px;
   }

   .search-popup .search-popup-close {
     font-size: 14px;
     top: 30px;
     right: 30px;
     width: 30px;
     height: 30px;
     line-height: 30px;
   }
 }

 @media (max-width: 420px) {
   .header .navbar-brand img {
     max-width: 120px;
   }

   .header .action-btn-login span {
     display: none;
   }
 }

 /*----------------------
      Accordions
  ------------------------*/
 .accordion {
   --bs-accordion-border-width: 0;
   --bs-accordion-border-radius: 0;
 }

 .accordion-item {
   padding: 12px 0;
   background-color: transparent;
 }

 .accordion-item.opened .accordion-header:not(.collapsed) .accordion-title:after {
   color: var(--color-white);
   background-color: var(--color-primary);
   transform: rotate(0);
 }

 .accordion-title {
   font-family: var(--font-primary);
   font-weight: 500;
   font-size: 23px;
   cursor: pointer;
   display: block;
   position: relative;
   padding-left: 40px;
   color: var(--color-primary-200);
 }

 .accordion-title:hover {
   color: var(--color-primary-200);
 }

 .accordion-title:after {
   font-family: "icomoon";
   content: "\e9cd";
   position: absolute;
   top: 5px;
   left: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 22px;
   height: 22px;
   color: var(--color-primary-200);
   background-color: var(--color-gray-600);
   transform: rotate(90deg);
 }

 .accordion-body {
   padding: 0 0 0 40px;
 }

 .accordion-body p {
   color: var(--bs-gray);
   margin-bottom: 0;
 }

 .accordion-body p:not(:last-child) {
   margin-bottom: 30px;
 }

 .custom-accordion-tabs .accordion-item {
   padding-top: 0;
 }

 .custom-accordion-tabs .accordion-item:not(.active) .accordion-body {
   display: none;
 }

 .accordion-bordered .accordion-item {
   border-bottom: 2px solid var(--color-gray-600);
 }

 .accordion-bordered .accordion-item:first-of-type {
   border-top: 4px solid var(--color-primary);
 }

 .accordion-light .accordion-item .accordion-title {
   color: var(--color-white);
 }

 .accordion-light .accordion-item .accordion-body p {
   color: var(--color-gray-100);
 }

 .accordion-light .accordion-item.opened .accordion-title {
   color: var(--color-primary);
 }

 /* Mobile Phones and tablets */
 @media (min-width: 320px) and (max-width: 767px) {
   .accordion-item .accordion-title {
     font-size: 15px;
   }

   .accordion-item .accordion-body p {
     font-size: 13px;
   }
 }

 /*-----------------------
      banner
  ------------------------*/
 .banner-img {
   position: relative;
 }

 .banner-text p:first-of-type {
   font-weight: 600;
 }

 .banner-statistics {
   position: absolute;
   bottom: 0;
   right: 0;
   max-width: 390px;
   z-index: 2;
   background-color: var(--color-primary);
 }

 .banner-statistics:after {
   font-family: "icomoon";
   content: "\e97b";
   position: absolute;
   bottom: 30px;
   right: 30px;
   font-size: 144px;
   line-height: 1;
   color: #3c9126;
 }

 .banner-statistics .counter-number {
   font-size: 82px;
   font-weight: 400;
   line-height: 1;
   margin-bottom: 13px;
 }

 .banner-statistics .statistics-title {
   font-size: 24px;
   font-weight: 700;
   margin-bottom: 16px;
 }

 .banner-statistics .statistics-desc {
   font-size: 15px;
   font-weight: 700;
   margin-bottom: 83px;
 }

 .banner-statistics .counter-number,
 .banner-statistics .statistics-title,
 .banner-statistics .statistics-desc {
   color: var(--color-white);
 }

 .banner-statistics .counter-number,
 .banner-statistics .statistics-title,
 .banner-statistics .statistics-desc,
 .banner-statistics .features-list-layout1 {
   position: relative;
   z-index: 2;
 }

 .banner-statistics .feature-item:not(:last-child) {
   margin-bottom: 8px;
 }

 .banner-statistics:hover:after {
   animation: bounce 1s linear infinite;
 }

 .banner-layout1 .cta-banner {
   position: absolute;
   top: 40px;
   left: 40px;
   width: 280px;
   padding: 40px 37px 40px 40px;
 }

 .banner-layout1 .cta-banner:after {
   content: "";
   position: absolute;
   left: 0;
   bottom: -14px;
   border-right: 15px solid transparent;
   border-top: 17px solid var(--color-white);
   transition: all 0.3s ease;
 }

 .banner-layout1 .cta-banner .cta-icon {
   margin-bottom: 18px;
 }

 .banner-layout1 .cta-banner .cta-title {
   line-height: 1.5;
 }

 .banner-layout1 .banner-img:hover .cta-banner {
   background-color: var(--color-primary);
 }

 .banner-layout1 .banner-img:hover .cta-banner:after {
   border-color: var(--color-primary) transparent transparent transparent;
 }

 .banner-layout1 .banner-img:hover .cta-banner .cta-icon,
 .banner-layout1 .banner-img:hover .cta-banner .cta-title {
   color: var(--color-white);
 }

 .banner-layout2 .popup-video,
 .banner-layout3 .popup-video {
   padding: 0;
   height: 78px;
 }

 .banner-layout2 .popup-video .custom-icon,
 .banner-layout3 .popup-video .custom-icon {
   width: 68px;
   background-color: rgba(0, 0, 0, 0.1);
 }

 .banner-layout2 .popup-video .btn-text,
 .banner-layout3 .popup-video .btn-text {
   padding: 0 35px;
 }

 .banner-layout2 .banner-img:after {
   content: "";
   position: absolute;
   top: -40px;
   left: -40px;
   width: 300px;
   height: 326px;
   z-index: -1;
   background-image: url('../assets/images/patterns/2.png');
 }

 .banner-layout2 .banner-img .popup-video {
   position: absolute;
   bottom: 40px;
   right: 40px;
   left: 40px;
   overflow: hidden;
 }

 .banner-layout3 .popup-video {
   position: absolute;
   top: 50%;
   left: 50%;
   overflow: hidden;
   transform: translate(-50%, -50%);
 }

 .banner-layout3 .popup-video .custom-icon {
   color: var(--color-white);
   background-color: var(--color-primary);
 }

 .banner-layout3 .progress-title,
 .banner-layout3 .progress-percentage,
 .banner-layout5 .progress-title,
 .banner-layout5 .progress-percentage {
   color: var(--color-white);
 }

 .banner-layout3 .progress,
 .banner-layout5 .progress {
   background-color: var(--color-primary-600);
 }

 .banner-layout3 .progress-bar,
 .banner-layout5 .progress-bar {
   background-color: var(--color-secondary);
 }

 .banner-layout6 .testimonials-layout3 {
   position: absolute;
   bottom: 0;
   right: 0;
 }

 .banner-layout7 .heading {
   max-width: 600px;
 }

 .banner-layout7 .banner-text {
   position: relative;
   margin-top: 60px;
   background-color: var(--color-gray-700);
 }

 .banner-layout7 .banner-text:after {
   content: "";
   position: absolute;
   top: 0;
   left: -40px;
   height: 100%;
   width: 40px;
   background-color: inherit;
 }

 .banner-layout7 .banner-text .banner-text-inner {
   max-width: 450px;
 }

 .banner-layout7 .banner-text p {
   line-height: 1.7;
 }

 .banner-layout7 .banner-img:after {
   content: "";
   position: absolute;
   top: -40px;
   right: -40px;
   width: 300px;
   height: 326px;
   z-index: -1;
   background-image: url('../assets/images/patterns/4.png');
 }

 .work-process-layout2 .highlights-banner {
   position: relative;
   z-index: 2;
   margin-bottom: -70px;
   padding: 90px 60px;
   background-color: var(--color-primary);
 }

 /* Extra Large Size Screens */
 @media (min-width: 1200px) {
   .banner-layout1 .banner-text {
     padding-right: 50px;
   }

   .banner-layout2 .banner-text,
   .banner-layout2 .animated-Progressbars {
     padding-inline-start: 70px;
   }

   .banner-layout2 .banner-text p:first-of-type {
     font-size: 17px;
   }

   .banner-layout3 .banner-text-col,
   .banner-layout4 .banner-text-col {
     padding: 120px;
     padding-inline-start: calc((100% - 1180px) / 2);
   }

   .banner-layout3 .popup-video,
   .banner-layout4 .popup-video {
     height: 110px;
   }

   .banner-layout3 .popup-video .custom-icon,
   .banner-layout4 .popup-video .custom-icon {
     width: 118px;
     height: 100%;
   }

   .banner-layout4 .banner-text .heading-desc,
   .banner-layout5 .banner-text .heading-desc {
     font-size: 17px;
     font-weight: 400;
   }

   .banner-layout4 .banner-text,
   .banner-layout4 .accordion,
   .banner-layout5 .banner-text,
   .banner-layout5 .accordion {
     max-width: 540px;
   }

   .banner-layout4 .banner-statistics,
   .banner-layout5 .banner-statistics {
     right: calc((100vw - 1180px) / 2);
     bottom: -100px;
   }

   .banner-layout5 .banner-text-col,
   .banner-layout6 .banner-text-col {
     padding: 40px 120px;
   }

   .banner-layout5 .banner-text-col.start-col,
   .banner-layout6 .banner-text-col.start-col {
     padding-inline-start: calc((100% - 1320px) / 2);
   }

   .banner-layout5 .banner-text-col.end-col,
   .banner-layout6 .banner-text-col.end-col {
     padding-inline-end: calc((100% - 1320px) / 2);
   }

   .banner-layout6 .banner-text {
     padding-inline-end: 30px;
   }

   .banner-layout7 .heading {
     max-width: 600px;
     padding-inline-start: 70px;
   }

   .banner-layout7 .banner-text {
     padding: 100px 70px 130px 70px;
   }

   .banner-layout7 .banner-text p {
     font-size: 17px;
   }

   .banner-statistics {
     padding: 58px 70px 65px;
   }

   .col-content {
     max-width: 600px;
   }

   .banner-layout9 .contact-panel {
     margin-left: 60px;
   }

   .banner-layout9 .testimonials-layout2 {
     margin-top: 90px;
     padding-top: 82px;
   }
 }

 @media (min-width: 1300px) {

   .banner-layout3 .banner-text-col,
   .banner-layout4 .banner-text-col {
     padding: 120px;
     padding-inline-start: calc((100% - 1300px) / 2);
   }

   .banner-layout4 .banner-statistics {
     right: calc((100vw - 1280px) / 2);
   }
 }

 @media (min-width: 1400px) {
   .banner-layout4 .banner-statistics {
     right: calc((100vw - 1320px) / 2);
   }

   .banner-layout3 .banner-text-col,
   .banner-layout4 .banner-text-col {
     padding: 120px;
     padding-inline-start: calc((100% - 1360px) / 2);
   }

   .banner-layout5 .banner-text-col.end-col {
     padding: 120px;
     padding-inline-end: calc((100% - 1320px) / 2);
   }
 }

 /* Mobile Phones & tablets and Small Screens */
 @media (max-width: 1199px) {

   .banner-layout3 .banner-text-col,
   .banner-layout4 .banner-text-col,
   .banner-layout5 .banner-text-col,
   .banner-layout6 .banner-text-col {
     padding: 40px;
   }

   .banner-layout4 .banner-statistics {
     left: 50%;
     transform: translateX(-50%);
   }

   .banner-layout7 .heading,
   .banner-layout7 .banner-text {
     padding: 30px;
     margin: 0 !important;
   }

   .banner-statistics {
     padding: 30px;
   }

   .banner-layout9 .testimonials-layout2 {
     margin-top: 30px;
     padding-top: 30px;
   }
 }

 @media (max-width: 767px) {

   .banner-layout3 .banner-text-col,
   .banner-layout4 .banner-text-col,
   .banner-layout5 .banner-text-col,
   .banner-layout6 .banner-text-col {
     padding: 30px;
   }

   .banner-layout6 .testimonials-layout3 {
     left: 0;
   }

   .banner-layout6 .testimonials-layout3 .slider-wrap:after {
     left: 20px;
   }
 }

 /*-------------------------
      Footer
  --------------------------*/
 .footer {
   overflow-x: hidden;
 }

 .footer .footer-primary {
   position: relative;
   padding: 110px 0 45px;
   background-color: var(--color-primary-300);
 }

 .footer .footer-primary:after {
   content: "";
   position: absolute;
   top: 0;
   right: 0;
   width: 300px;
   height: 326px;
   z-index: 1;
   background-position: top right;
   background-repeat: no-repeat;
   background-image: url('../assets/images/patterns/3.png');
 }

 .footer .footer-secondary {
   position: relative;
   padding-bottom: 30px;
   background-color: var(--color-white);
 }

 .footer .footer-secondary:after {
   content: "";
   position: absolute;
   top: 0;
   left: 60px;
   right: 60px;
   height: 5px;
   background-color: var(--color-secondary);
 }

 .footer [class*=footer-widget] {
   position: relative;
   z-index: 2;
   margin-bottom: 30px;
 }

 .footer .footer-widget-title {
   color: var(--color-white);
   font-size: 17px;
   text-transform: capitalize;
   margin-bottom: 33px;
 }

 .footer .footer-widget-newsletter {
   padding: 35px 40px;
   border-radius: 4px;
   background-color: var(--color-primary-400);
   margin-bottom: 70px;
 }

 .footer .newsletter-form .form-control {
   color: var(--color-white);
   background-color: transparent;
   border: 1px solid var(--color-primary-600);
 }

 .footer .newsletter-form .form-control::-webkit-input-placeholder {
   color: var(--color-gray-400);
 }

 .footer .newsletter-form .form-control:-moz-placeholder {
   color: var(--color-gray-400);
 }

 .footer .newsletter-form .form-control::-moz-placeholder {
   color: var(--color-gray-400);
 }

 .footer .newsletter-form .form-control:-ms-input-placeholder {
   color: var(--color-gray-400);
 }

 .footer .newsletter-form .btn {
   min-width: 150px;
 }

 .footer .newsletter-form .form-control,
 .footer .newsletter-form .btn {
   height: 58px;
   border-radius: 0;
 }

 .footer .footer-cta .cta-title {
   font-size: 18px;
   max-width: 320px;
 }

 .footer .footer-cta .cta-icon {
   font-size: 55px;
   margin-right: 20px;
   line-height: 1;
   color: var(--color-secondary);
 }

 .footer .contact-list li {
   margin-bottom: 17px;
 }

 .footer .contact-list li:last-child {
   margin-bottom: 0;
 }

 .footer .contact-list li a {
   font-family: var(--font-primary);
   font-size: 19px;
   font-weight: 500;
   display: flex;
   align-items: center;
   color: var(--color-secondary);
 }

 .footer .contact-list li a .contact-icon {
   margin-right: 15px;
 }

 .footer .contact-list li a.contact-address {
   font-size: 14px;
   color: var(--color-gray-400);
 }

 .footer .contact-list li a:hover {
   color: var(--color-primary-500);
 }

 .footer .footer-widget-nav li a {
   display: inline-block;
   position: relative;
   color: var(--color-gray-500);
   font-size: 14px;
   margin-bottom: 11px;
 }

 .footer .footer-widget-nav li a:before {
   content: "";
   position: absolute;
   left: 0;
   width: 0;
   bottom: 0;
   height: 1px;
   background-color: var(--color-white);
   transition: all 0.4s ease;
 }

 .footer .footer-widget-nav li a:hover {
   color: var(--color-white);
 }

 .footer .footer-widget-nav li a:hover:before {
   width: 100%;
 }

 .footer .footer-copyright-links {
   margin: 15px 0;
 }

 .footer .footer-copyright-links li a {
   position: relative;
   color: var(--color-primary-200);
   display: block;
 }

 .footer .footer-copyright-links li a:before {
   content: "";
   position: absolute;
   left: 0;
   width: 0;
   bottom: 0;
   height: 1px;
   background-color: var(--color-primary);
   transition: all 0.4s ease;
 }

 .footer .footer-copyright-links li a:hover {
   color: var(--color-primary);
 }

 .footer .footer-copyright-links li a:hover:before {
   width: 100%;
 }

 .footer .footer-copyright-links li:not(:last-child) a {
   margin-right: 25px;
 }

 .footer .footer-copyright-links li:not(:last-child) a:after {
   content: "-";
   position: absolute;
   top: 50%;
   right: -13px;
   transform: translateY(-50%);
   color: var(--color-primary-200);
 }

 .footer .social-icons li a {
   box-shadow: 0 0 0 2px var(--color-gray-300) inset;
 }

 .footer .social-icons li a .social-icon {
   color: var(--color-white);
 }

 .footer .social-icons li a:hover {
   box-shadow: 0 0 0 2px var(--color-secondary) inset;
 }

 .footer .social-icons li a:hover .social-icon {
   color: var(--color-secondary);
 }

 .scroll-btn {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   position: relative;
   z-index: 3;
   width: 144px;
   height: 82px;
   margin-top: -41px;
   color: var(--color-white);
   background-color: var(--color-primary-300);
   transition: all 0.4s linear;
 }

 .scroll-btn .btn-text {
   font-weight: 700;
 }

 .scroll-btn .btn-icon {
   margin-bottom: 5px;
   color: var(--color-secondary);
   transition: all 0.4s linear;
 }

 .scroll-btn:hover {
   color: var(--color-secondary);
 }

 .scroll-btn:hover .btn-icon {
   transform: translateY(-5px);
 }

 /* Mobile Phones and tablets */
 @media (min-width: 320px) and (max-width: 1200px) {
   .footer .footer-primary {
     padding-top: 60px;
     padding-bottom: 30px;
   }

   .footer .footer-secondary {
     padding: 20px 0;
   }

   .footer .footer-secondary:after {
     left: 20px;
     right: 20px;
     height: 3px;
   }

   .footer .footer-widget {
     margin-bottom: 20px;
   }

   .footer .footer-widget-title {
     margin-bottom: 15px;
   }

   .footer .footer-widget-nav li a {
     font-size: 13px;
   }

   .footer .scroll-btn {
     margin-top: -61px;
   }

   .footer .footer-widget-newsletter {
     padding: 30px;
   }
 }

 /* Mobile Phones */
 @media (min-width: 320px) and (max-width: 767px) {
   .footer .footer-widget-newsletter {
     padding: 15px 15px 20px;
   }

   .footer .contact-list li {
     margin-bottom: 10px;
   }

   .footer .contact-list li a {
     font-size: 14px;
   }

   .footer .contact-list li a .contact-icon {
     margin-right: 10px;
   }
 }

 @media (min-width: 320px) and (max-width: 575px) {

   .footer .newsletter-form .btn,
   .footer .newsletter-form .form-control {
     height: 48px;
   }

   .footer .newsletter-form .btn {
     min-width: 130px;
   }

   .footer .footer-cta .cta-title {
     font-size: 15px;
   }

   .footer .footer-cta .cta-icon {
     font-size: 40px;
     margin-right: 10px;
   }

   .footer .footer-copyright-links li:not(:last-child) a {
     font-size: 13px;
     margin-right: 15px;
   }

   .footer .footer-copyright-links li:not(:last-child) a :after {
     right: -10px;
   }

   .footer .copyright-text {
     font-size: 13px;
   }
 }

 @media (max-width: 420px) {
   .footer .newsletter-form {
     flex-direction: column;
   }

   .footer .newsletter-form .btn,
   .footer .newsletter-form .form-control {
     width: 100%;
   }

   .footer .newsletter-form .form-control {
     margin: 10px 0;
   }
 }

 /*-------------------------
         Counters
  --------------------------*/
 .counter-item {
   margin-bottom: 30px;
 }

 .counter-item .counter-number {
   position: relative;
   font-size: 54px;
   line-height: 1;
   color: var(--color-primary);
   font-weight: 400;
   margin-bottom: 10px;
 }

 .counter-item .counter-desc {
   font-family: var(--font-primary);
   color: var(--color-primary-600);
   line-height: 1.6;
   font-size: 18px;
   font-weight: 600;
 }

 .counter-item .counter-icon {
   flex: 0 0 100px;
   font-size: 65px;
   color: var(--color-secondary);
 }

 .counters-light .counter-number {
   color: var(--color-white);
 }

 .counters-light .counter-desc {
   color: var(--color-gray-100);
 }

 .counters-layout1 .counter-item {
   max-width: 330px;
   padding-bottom: 24px;
   margin-bottom: 24px;
   border-bottom: 1px solid var(--color-gray-600);
 }

 .counters-layout1 .counter-item:last-of-type {
   border-bottom: 0;
   padding-bottom: 0;
 }

 /* Mobile Phones and tablets */
 @media (min-width: 320px) and (max-width: 767px) {
   .counter-item .counter-number {
     font-size: 50px;
   }

   .counter-item .counter-desc {
     font-size: 15px;
     line-height: 1.5;
   }
 }

 /*-------------------------
      Call to Action
  --------------------------*/
 .cta-title {
   font-size: 24px;
   margin-bottom: 15px;
 }

 .cta-desc {
   font-size: 16px;
 }

 .cta-banner {
   position: relative;
   z-index: 3;
   padding: 40px;
   background-color: var(--color-white);
   box-shadow: 0px 5px 83px 0px rgba(1, 34, 23, 0.12);
   transition: all 0.3s ease;
 }

 .cta-banner .cta-title {
   font-size: 18px;
   margin-bottom: 15px;
   transition: all 0.3s ease;
 }

 .cta-banner .cta-desc {
   font-size: 16px;
 }

 .cta-icon {
   font-size: 55px;
   line-height: 1;
   color: var(--color-primary);
 }

 .cta-layout1 .contact-phone .phone-number {
   color: var(--color-primary-700);
   transition: all 0.3s ease;
 }

 .cta-layout1 .contact-phone .contact-icon {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 68px;
   height: 68px;
   animation: none;
   box-shadow: 0 0 0 2px #81a297 inset;
   transition: all 0.3s ease;
 }

 .cta-layout1 .contact-phone .contact-icon:after {
   display: none;
 }

 .cta-layout1 .contact-phone .contact-icon .icon-phone {
   width: 46px;
   height: 46px;
   background-color: var(--color-primary-700);
   transition: all 0.3s ease;
 }

 .cta-layout1 .contact-phone:hover .phone-number {
   color: var(--color-secondary);
 }

 .cta-layout1 .contact-phone:hover .icon-phone {
   background-color: var(--color-secondary);
 }

 .cta-layout1 .contact-phone:hover .contact-icon {
   box-shadow: 0 0 0 2px var(--color-secondary) inset;
 }

 @media (max-width: 1199px) {
   .cta-layout1 .cta-icon {
     box-shadow: 0 0 0 5px var(--color-primary), 0 0 0 12px rgba(255, 255, 255, 0.2);
   }

   .cta-layout1:hover .cta-icon {
     box-shadow: 0 0 0 7px var(--color-primary), 0 0 0 12px rgba(255, 255, 255, 0.2);
   }
 }

 @media (max-width: 991px) {
   .cta-banner .cta-title {
     font-size: 16px;
   }
 }

 /*-----------------------
      careers
  ------------------------*/
 .job-item {
   padding: 40px;
   border-radius: 5px;
   background-color: var(--color-white);
   box-shadow: 0px 5px 83px 0px rgba(13, 14, 67, 0.12);
   transition: all 0.4s ease;
 }

 .job-item:hover {
   transform: translateY(-6px);
 }

 .job-item .job-meta {
   margin-bottom: 32px;
 }

 .job-item .job-type {
   font-size: 13px;
   padding: 5px 12px;
   border-radius: 1px;
   margin-right: 10px;
   text-transform: capitalize;
   color: var(--color-white);
   background-color: var(--color-primary);
 }

 .job-item .job-location {
   font-size: 13px;
   color: var(--color-primary-200);
 }

 .job-item .job-title {
   font-size: 21px;
   margin-bottom: 18px;
 }

 .job-item .btn {
   height: 58px;
   min-width: 170px;
   margin-top: 10px;
 }

 .job-item .btn .plus-icon {
   background-color: var(--color-secondary);
 }

 .job-item .btn:hover .plus-icon {
   background-color: var(--color-primary);
 }

 @media (min-width: 1400px) {
   .careers .slick-list {
     margin: -20px;
   }

   .careers .slick-slide {
     margin: 20px;
   }
 }

 /* Mobile Phones and tablets */
 @media (min-width: 320px) and (max-width: 767px) {
   .job-item {
     padding: 20px;
   }

   .job-item .job-meta {
     margin-bottom: 20px;
   }

   .job-item .job-title {
     margin-bottom: 10px;
   }
 }

 /*------------------------
      Slider 
  --------------------------*/
 .header-transparent+.slider {
   margin-top: -100px;
 }

 .header-transparent.header-full+.slider {
   margin-top: -135px;
 }

 .header-transparent+.slider .slide-item {
   padding-top: 100px;
 }

 .slider {
   padding: 0;
 }

 .slider .slide-item {
   min-height: 550px;
   height: calc(100vh - 160px);
 }

 .slider .slide-item.bg-overlay:before {
   background-color: rgba(1, 34, 23, 0.25);
   background-position: 0 0;
   background-size: 100% 100%;
   background-repeat: no-repeat;
   background-image: url('../assets/images/patterns/5.png');
 }

 .slider .slide-item.bg-overlay:after {
   content: "";
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   z-index: -1;
   opacity: 0.7;
   background-image: linear-gradient(to bottom, var(--color-primary-200) 0%, var(--color-primary-200) 19%, rgba(18, 34, 29, 0) 65%, rgba(27, 26, 26, 0) 100%);
 }

 .slider .slide-subtitle {
   display: inline-block;
   color: var(--color-white);
   font-size: 16px;
   font-weight: 700;
   margin-bottom: 8px;
 }

 .slider .slide-subtitle.highlighted {
   padding: 5px 13px;
   border-radius: 10px;
   background-color: var(--color-secondary);
 }

 .slider .slide-title {
   color: var(--color-white);
   margin-bottom: 25px;
 }

 .slider .slide-desc {
   color: var(--color-gray-100);
   max-width: 600px;
   margin-bottom: 30px;
 }

 .slider .slick-arrow:before {
   width: 50px;
   height: 50px;
   color: rgba(255, 255, 255, 0.5);
   background-color: transparent;
 }

 .slider .slick-arrow:hover:before {
   color: var(--color-white);
 }

 .slider .slick-arrow.slick-next:before {
   content: "\e9d7";
 }

 .slider .slick-arrow.slick-prev:before {
   content: "\e9d6";
 }

 .slider .slick-dots {
   position: absolute;
   bottom: 30px;
   text-align: center;
   width: 100%;
 }

 .slider .cta-banner {
   padding: 50px;
 }

 .slider .cta-banner .cta-icon {
   font-size: 50px;
   line-height: 1;
   color: var(--color-primary);
   margin-bottom: 28px;
 }

 .slider .cta-banner .icon-filled {
   background-color: var(--color-secondary);
 }

 .slider .cta-banner .slick-dots {
   position: static;
   text-align: left;
   margin-left: -17px;
 }

 .slider-centerd {
   text-align: center;
 }

 .slider-centerd .slide-desc {
   margin-right: auto;
   margin-left: auto;
 }

 @media (min-width: 1200px) {
   .slider .slide-title {
     font-size: 75px;
     line-height: 1.1;
     font-weight: 700;
   }

   .slider .slide-desc {
     font-size: 17px;
     line-height: 1.5;
     font-weight: 700;
   }

   .slider .slick-arrow:before {
     font-size: 50px;
   }

   .slider .btn {
     min-width: 190px;
   }

   .slider .btn.btn-outlined {
     line-height: 58px;
   }

   .slider .btn.btn-xl {
     min-width: 225px;
   }

   .slider-layout2 .slide-title {
     max-width: 680px;
   }
 }

 @media (min-width: 1400px) {
   .slider .slick-arrow.slick-prev {
     left: 30px;
   }

   .slider .slick-arrow.slick-next {
     right: 30px;
   }
 }

 @media (min-width: 1200px) {
   .slider-centerd .slick-slide {
     padding-bottom: 70px;
   }
 }

 @media (min-width: 768px) and (max-width: 1200px) {
   .slider .slide-item {
     padding-right: 50px;
     padding-left: 50px;
   }

   .slider .slick-arrow.slick-prev {
     left: 10px;
   }

   .slider .slick-arrow.slick-next {
     right: 10px;
   }
 }

 /* Large Size Screens */
 @media (min-width: 992px) and (max-width: 1200px) {
   .slider .slide-title {
     font-size: 65px;
   }
 }

 /* Medium Size Screens */
 @media (min-width: 768px) and (max-width: 991px) {
   .slider .slide-title {
     font-size: 50px;
     margin-bottom: 10px;
   }
 }

 /* Mobile Phones and tablets */
 @media (min-width: 320px) and (max-width: 767px) {
   .header-transparent+.slider {
     margin-top: 0 !important;
   }

   .slider .slide-item {
     min-height: 0;
     padding: 100px 10px;
     height: auto !important;
   }

   .slider .slide-desc {
     font-size: 15px;
     font-weight: 400;
     margin-bottom: 20px;
   }

   .slider .slick-arrow:before {
     font-size: 25px;
   }

   .slider .slick-arrow.slick-next {
     right: -20px;
   }

   .slider .slick-arrow.slick-prev {
     left: -20px;
   }
 }

 /*  Small Screens and tablets  */
 @media (min-width: 576px) and (max-width: 767px) {
   .slider .slide-title {
     font-size: 35px;
     line-height: 45px;
     margin-bottom: 10px;
   }
 }

 /* Extra Small Devices */
 @media (min-width: 320px) and (max-width: 575px) {
   .slider .slide-title {
     font-size: 30px;
     margin-bottom: 10px;
   }

   .slider .btn {
     padding: 0 10px;
     margin-bottom: 10px;
   }

   .slider .slide-item {
     padding: 120px 10px 50px;
   }
 }

 /*--------------------------
        Video
  --------------------------*/
 .video-btn {
   text-align: center;
   display: inline-flex;
   position: relative;
   padding-left: 88px;
   height: 88px;
   align-items: center;
 }

 .video-btn .video-player {
   position: absolute;
   top: 0;
   left: 0;
   width: 88px;
   height: 88px;
   line-height: 88px;
   border-radius: 50%;
   color: var(--color-white);
   background-color: var(--color-secondary);
   transition: all 0.3s linear;
 }

 .video-btn .video-player:before {
   content: "";
   position: absolute;
   top: -40px;
   left: -40px;
   width: calc(100% + 80px);
   height: calc(100% + 80px);
   border-radius: 50%;
   border: 40px solid rgba(255, 255, 255, 0.2);
 }

 .video-btn .video-player:after {
   content: "";
   position: absolute;
   top: 50%;
   left: 50%;
   height: 30px;
   width: 30px;
   z-index: 3;
   border-radius: 50%;
   animation: ripple 3s infinite;
   transform: translate(-50%, -50%);
   box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
 }

 .video-btn:hover .video-player {
   background-color: var(--color-primary);
 }

 .video-btn .video-btn-title {
   font-size: 15px;
   font-weight: 700;
   line-height: 1;
   display: inline-block;
   white-space: nowrap;
   margin-left: 22px;
 }

 .video-btn-sm {
   padding-left: 68px;
   height: 68px;
 }

 .video-btn-sm .video-player {
   width: 68px;
   height: 68px;
   line-height: 68px;
 }

 .video-btn-sm .video-player:before {
   top: -10px;
   left: -10px;
   border-width: 10px;
   width: calc(100% + 20px);
   height: calc(100% + 20px);
 }

 .video-btn-primary .video-player {
   background-color: var(--color-primary);
 }

 .video-btn-primary:hover .video-player {
   background-color: var(--color-secondary);
 }

 .video-btn-white .video-player {
   color: var(--color-primary-200);
   background-color: var(--color-white) !important;
 }

 .video-btn-white .video-player-animation {
   border-color: var(--color-white) !important;
 }

 .video-btn-no-shadow .video-player:before {
   border: 0;
 }

 .video-banner {
   position: relative;
   z-index: 2;
 }

 .video-banner img {
   border-radius: 5px;
 }

 .video-banner .video-btn {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
 }

 .video-banner .video-btn-title {
   position: absolute;
   bottom: -30px;
   left: -7px;
   line-height: 1.5;
 }

 .video-banner .popup-video,
 .banner-img .popup-video {
   position: absolute;
   overflow: hidden;
   height: 110px;
 }

 .video-banner .popup-video.bottom-left,
 .banner-img .popup-video.bottom-left {
   bottom: 40px;
   left: 40px;
 }

 .video-banner .popup-video:not(.bottom-left),
 .banner-img .popup-video:not(.bottom-left) {
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
 }

 .video-banner .popup-video .btn-text,
 .banner-img .popup-video .btn-text {
   padding: 0 35px;
 }

 .video-banner .popup-video .custom-icon,
 .banner-img .popup-video .custom-icon {
   display: flex;
   align-items: center;
   justify-content: center;
   margin-inline-end: 0;
   width: 118px;
   height: 100%;
   margin: 0 !important;
   color: var(--color-white);
   background-color: var(--color-primary);
 }

 @media (min-width: 320px) and (max-width: 767px) {
   .popup-video .btn-text {
     padding: 0 20px;
   }

   .popup-video .custom-icon {
     width: 65px;
   }
 }

 /*-----------------------
      Features
  ------------------------*/
 .feature-item {
   position: relative;
   padding-left: 20px;
   margin-bottom: 10px;
   transition: all 0.3s linear;
 }

 .feature-item:last-of-type {
   margin-bottom: 0 !important;
 }

 .feature-item:before {
   font-family: "icomoon";
   content: "\e91e";
   position: absolute;
   top: 0;
   left: 0;
   font-size: 8px;
   display: flex;
   align-items: center;
   justify-content: center;
   height: 26px;
   color: var(--color-secondary);
 }

 .feature-item .feature-title {
   font-size: 16px;
   font-weight: 500;
   color: var(--color-primary-200);
   margin-bottom: 18px;
 }

 .features-list-layout2 .feature-item:before,
 .features-list-layout2 .feature-item .feature-title {
   color: var(--color-primary-200);
 }

 .features-list-layout3 .feature-item {
   margin-bottom: 20px;
   padding-bottom: 20px;
   border-bottom: 1px solid var(--color-gray-200);
 }

 .features-list-layout3 .feature-item:last-child {
   border-bottom: 0;
   margin-bottom: 0;
   padding-bottom: 0;
 }

 .features-list-layout3 .feature-item {
   padding-left: 45px;
 }

 .features-list-layout3 .feature-item:before {
   width: 26px;
   border-radius: 50%;
   color: var(--color-white);
   background-color: var(--color-secondary);
 }

 .features-light .feature-item:before {
   color: var(--color-white);
 }

 .features-light .feature-item .feature-title {
   color: var(--color-white);
 }

 /* Mobile Phones and tablets  */
 @media (min-width: 320px) and (max-width: 767px) {
   .feature-item .feature-title {
     font-size: 16px;
     margin-bottom: 5px;
   }

   .feature-item .feature-desc {
     font-size: 13px;
     margin-bottom: 10px;
   }
 }

 /*-----------------------
       fancybox 
  ------------------------*/
 .fancybox-item {
   position: relative;
 }

 .fancybox-item .fancybox-icon {
   color: var(--color-gray-200);
   font-size: 60px;
   line-height: 1;
   margin-bottom: 20px;
 }

 .fancybox-item .fancybox-title {
   font-size: 18px;
   margin-bottom: 14px;
 }

 .fancybox-item .fancybox-desc {
   margin-bottom: 0;
 }

 .fancybox-item .fancybox-title,
 .fancybox-item .fancybox-desc {
   position: relative;
   z-index: 2;
   transition: all 0.3s ease;
 }

 .fancybox-layout1 .fancybox-item {
   margin: 35px 0 0;
 }

 .fancybox-layout1 .fancybox-item:hover .fancybox-icon {
   animation: bounce 1s linear infinite;
 }

 .fancybox-layout2 .fancybox-item {
   padding: 0 25px;
   text-align: center;
   border-radius: 4px;
   border: 1px solid var(--color-gray-600);
 }

 .fancybox-layout2 .fancybox-item:before {
   font-family: "icomoon";
   content: "\e9d2";
   position: absolute;
   right: 0;
   top: 50%;
   width: 48px;
   height: 48px;
   z-index: 2;
   font-size: 12px;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
   color: var(--color-primary-200);
   background-color: var(--color-gray-600);
   transition: all 0.4s ease;
   transform: translate(50%, -50%);
 }

 .fancybox-layout2 .fancybox-item:last-of-type:before {
   display: none;
 }

 .fancybox-layout2 .fancybox-item .fancybox-title {
   font-size: 20px;
 }

 .fancybox-layout2 .fancybox-item .fancybox-body {
   transition: all 0.4s ease;
   transform: translateY(50px);
 }

 .fancybox-layout2 .fancybox-item .fancybox-icon {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 88px;
   height: 88px;
   font-size: 50px;
   margin: 0 auto 36px;
   background-color: var(--color-secondary);
   transition: all 0.3s ease;
 }

 .fancybox-layout2 .fancybox-item .btn {
   height: 48px;
   min-width: 150px;
   margin-top: 26px;
   opacity: 0;
   visibility: hidden;
 }

 .fancybox-layout2 .fancybox-item:hover .fancybox-body {
   transform: translateY(-50px);
 }

 .fancybox-layout2 .fancybox-item:hover .fancybox-icon {
   background-color: var(--color-primary);
 }

 .fancybox-layout2 .fancybox-item:hover .btn {
   opacity: 1;
   visibility: visible;
 }

 .fancybox-layout3 {
   padding-bottom: 170px;
 }

 .fancybox-layout3 .fancybox-item {
   display: flex;
   align-items: flex-start;
   margin-bottom: 30px;
 }

 .fancybox-layout3 .fancybox-item .fancybox-icon {
   font-size: 45px;
   flex: 0 0 65px;
   max-width: 65px;
 }

 .fancybox-layout3 .fancybox-item .fancybox-title {
   font-size: 18px;
 }

 .fancybox-layout3 .fancybox-item:hover .fancybox-icon {
   animation: bounce 1s linear infinite;
 }

 .fancybox-layout9 .fancybox-item {
   display: flex;
   margin-bottom: 42px;
 }

 .fancybox-layout9 .fancybox-item:last-of-type {
   margin-bottom: 0;
 }

 .fancybox-layout9 .fancybox-item .fancybox-body {
   flex: 0 0 calc(100% - 80px);
   max-width: calc(100% - 80px);
 }

 .fancybox-layout9 .fancybox-item .fancybox-icon {
   font-size: 50px;
   padding-top: 15px;
   flex: 0 0 80px;
   max-width: 80px;
 }

 .fancybox-layout9 .fancybox-item .fancybox-title {
   font-size: 19px;
   margin-bottom: 16px;
 }

 .fancybox-light .fancybox-item .fancybox-title {
   color: var(--color-white);
 }

 .fancybox-light .fancybox-item .fancybox-icon,
 .fancybox-light .fancybox-item .fancybox-desc {
   color: var(--color-gray-100);
 }

 .fancybox-carousel {
   overflow-x: hidden;
 }

 .fancybox-carousel .slick-list {
   margin: 0 -15px;
   overflow: visible;
 }

 .fancybox-carousel .slick-slide {
   margin: 0 15px;
   transition: all 0.3s ease;
 }

 .fancybox-carousel .slick-slide.slick-active {
   opacity: 1;
   visibility: visible;
 }

 .fancybox-carousel .slick-slide:not(.slick-active) {
   opacity: 0;
   visibility: hidden;
 }

 .fancybox-carousel .slick-dots li:not(.slick-active) button {
   background-color: var(--color-white);
 }

 .fancybox-carousel .fancybox-item .fancybox-desc {
   min-height: 100px;
 }

 .certificate .certificate-img {
   padding: 45px;
   text-align: center;
   border-radius: 3px;
   background-color: var(--color-gray-700);
   transition: all 0.3s ease;
 }

 .certificate .certificate-body {
   padding: 33px 35px;
 }

 .certificate .certificate-title {
   font-size: 21px;
   margin-bottom: 12px;
 }

 .certificate .certificate-desc {
   font-size: 16px;
   margin-bottom: 0;
 }

 .certificate:hover .certificate-img {
   background-color: var(--color-primary);
 }

 @media (min-width: 1200px) {
   .fancybox-carousel .carousel-container {
     margin-right: -140px;
   }

   .fancybox-carousel .carousel-container .slick-dots {
     margin-right: 140px;
   }
 }

 @media (min-width: 1400px) {
   .fancybox-carousel .slick-list {
     margin: -20px;
   }

   .fancybox-carousel .slick-slide {
     margin: 20px;
   }
 }

 @media (min-width: 1500px) {
   .fancybox-carousel .carousel-container {
     margin-right: -500px;
   }

   .fancybox-carousel .carousel-container .slick-dots {
     margin-right: 500px;
   }
 }

 @media (min-width: 992px) {
   .fancybox-layout2 .fancybox-item {
     flex: 0 0 25%;
     max-width: 25%;
     padding: 0 40px;
   }

   .fancybox-layout9 .fancybox-item:hover .fancybox-icon {
     animation: slideTopDown 1s infinite;
   }
 }

 @media (min-width: 991px) and (max-width: 1150px) {
   .fancybox-layout2 .fancybox-item {
     padding: 30px;
   }
 }

 @media (max-width: 991px) {
   .fancybox-layout1 .fancybox-item {
     margin: 0 0 20px;
   }

   .fancybox-layout2 .fancybox-item {
     padding: 30px;
     flex: 0 0 50%;
     max-width: 50%;
   }

   .fancybox-layout2 .fancybox-item:before {
     display: none;
   }

   .fancybox-layout2 .fancybox-item .fancybox-body,
   .fancybox-layout2 .fancybox-item .fancybox-body:hover {
     transform: translateY(0);
   }

   .fancybox-layout2 .fancybox-item .btn {
     opacity: 1;
     visibility: visible;
   }
 }

 /* Mobile Phones and tablets */
 @media (min-width: 320px) and (max-width: 767px) {
   .fancybox-item .fancybox-icon {
     font-size: 40px;
   }

   .fancybox-item .fancybox-title {
     font-size: 15px !important;
     margin-bottom: 5px;
   }

   .fancybox-layout3 {
     padding-bottom: 120px;
   }

   .fancybox-layout9 .fancybox-item {
     margin-bottom: 20px;
   }

   .fancybox-layout9 .fancybox-item .fancybox-title {
     margin-bottom: 10px;
   }

   .fancybox-layout9 .fancybox-item .fancybox-icon {
     font-size: 40px;
     flex: 0 0 60px;
     max-width: 60px;
   }

   .fancybox-layout9 .fancybox-item .fancybox-body {
     flex: 0 0 calc(100% - 60px);
     max-width: calc(100% - 60px);
   }

   .certificate .certificate-body {
     padding: 18px;
   }
 }

 @media (min-width: 320px) and (max-width: 575px) {
   .fancybox-layout2 .fancybox-item {
     flex: 0 0 100%;
     max-width: 100%;
   }

   .fancybox-layout3 .fancybox-item .fancybox-icon {
     font-size: 43px;
     flex: 0 0 55px;
     max-width: 55px;
   }
 }

 /*--------------------
       Team
  ---------------------*/
 .member {
   position: relative;
   overflow: hidden;
   border-radius: 4px;
   margin-bottom: 30px;
   text-align: center;
   transition: all 0.3s linear;
 }

 .member .member-img {
   position: relative;
   z-index: 2;
   transition: all 0.3s ease;
 }

 .member .member-img img {
   width: 100%;
   border-radius: 4px;
 }

 .member .member-info {
   position: relative;
   padding: 28px 20px;
   background-color: var(--color-white);
   transition: all 0.3s ease;
 }

 .member .member-info .member-name {
   font-size: 20px;
   margin-bottom: 6px;
 }

 .member .member-info .member-title {
   font-size: 15px;
   margin-bottom: 0;
 }

 .team-layout1 .member {
   box-shadow: 0px 5px 83px 0px rgba(1, 34, 23, 0.07);
 }

 .team-layout1 .member .member-main-info {
   position: relative;
   z-index: 2;
   padding: 24px;
   border-radius: 2px;
   margin: -57px 0 34px;
   background-color: var(--color-primary);
 }

 .team-layout1 .member .member-main-info .member-name,
 .team-layout1 .member .member-main-info .member-title {
   color: var(--color-white);
 }

 .team-layout1 .member .member-info {
   padding: 40px;
 }

 .team-layout1 .member .social-icons {
   margin-top: 5px;
 }

 .team-layout1 .member .social-icons li {
   margin-right: -2px;
 }

 .team-layout1 .member .social-icons li a {
   width: 60px;
   height: 60px;
   box-shadow: none;
   border-radius: 0;
   border: 2px solid #81a297;
 }

 .team-layout1 .member .social-icons li a:hover .social-icon {
   color: var(--color-primary);
 }

 .team-layout1 .member .social-icons li:last-of-type {
   margin-right: 0;
 }

 .team-layout2 .member .social-icons {
   position: absolute;
   left: 30px;
   right: 30px;
   bottom: 30px;
   opacity: 0;
   padding: 16px 0;
   border-radius: 2px;
   background-color: var(--color-primary);
   transform: scaleX(0.5);
   transition: all 0.3s ease;
 }

 .team-layout2 .member .social-icons li {
   opacity: 0;
   transition: all 0.3s ease;
 }

 .team-layout2 .member .social-icons li:not(:last-of-type) {
   margin-right: 20px;
 }

 .team-layout2 .member .social-icons li a {
   width: 20px;
   height: 20px;
   box-shadow: none;
 }

 .team-layout2 .member .social-icons li a .social-icon {
   color: var(--color-white);
 }

 .team-layout2 .member:hover .social-icons {
   opacity: 1;
   transform: scaleX(1);
 }

 .team-layout2 .member:hover .social-icons li {
   opacity: 1;
 }

 .team-layout2 .member:hover .social-icons li:first-of-type {
   transition-delay: 0.2s;
 }

 .team-layout2 .member:hover .social-icons li:nth-of-type(2) {
   transition-delay: 0.4s;
 }

 .team-layout2 .member:hover .social-icons li:nth-of-type(3) {
   transition-delay: 0.6s;
 }

 .team-layout2 .member:hover .social-icons li:nth-of-type(4) {
   transition-delay: 0.8s;
 }

 /* Mobile Phones and tablets */
 @media (min-width: 320px) and (max-width: 767px) {
   .member {
     max-width: 450px;
     margin: 0 auto 30px;
   }

   .member .member-info {
     padding: 15px !important;
   }

   .member .member-info .member-name {
     font-size: 15px;
     margin-bottom: 0;
   }
 }

 /*----------------------------
       Testimonial
  ------------------------------*/
 .testimonial-item {
   position: relative;
 }

 .testimonial-title {
   font-size: 19px;
   font-weight: 500;
   line-height: 1.5;
   font-style: italic;
   margin-bottom: 21px;
 }

 .testimonial-meta {
   transition: opacity 0.3s ease;
 }

 .testimonial-meta.slick-slide {
   cursor: pointer;
 }

 .testimonial-meta-title {
   color: var(--color-primary);
   font-size: 14px;
   font-weight: 700;
   margin-bottom: 4px;
   white-space: nowrap;
 }

 .testimonial-meta-desc {
   position: relative;
   z-index: 2;
   font-size: 13px;
   line-height: 1;
   margin-bottom: 0;
 }

 .testimonial-thmb {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 50px;
   height: 50px;
   padding: 5px;
   cursor: pointer;
   border-radius: 50%;
   transition: all 0.3s ease;
 }

 .testimonial-thmb img {
   width: 100%;
   height: 100%;
   border-radius: 50%;
 }

 .testimonials-layout1 .testimonial-item {
   padding: 40px;
   border-radius: 5px;
   box-shadow: 0px 5px 83px 0px rgba(13, 14, 67, 0.12);
 }

 .testimonials-layout1 .testimonial-item:hover {
   transform: translateY(-6px);
 }

 .testimonials-layout1 .testimonial-item:before {
   content: "\e9d3";
   font-family: "icomoon";
   position: absolute;
   bottom: 30px;
   right: 30px;
   line-height: 1;
   font-size: 85px;
   color: var(--color-gray-700);
 }

 .testimonials-layout1 .testimonial-item:after {
   content: "";
   position: absolute;
   bottom: -30px;
   left: 0;
   border-right: 40px solid transparent;
   border-top: 40px solid var(--color-white);
 }

 .testimonials-layout1 .testimonial-rating {
   margin-bottom: 18px;
 }

 .testimonials-layout1 .testimonial-rating .active {
   color: #e1a200;
 }

 .testimonials-layout1 .testimonial-thmb {
   margin-right: 20px;
   border: 2px solid var(--color-primary);
 }

 .testimonials-layout1 .testimonial-meta-title {
   font-family: var(--font-secondary);
 }

 .testimonials-layout2 .testimonial-meta-title,
 .testimonials-layout3 .testimonial-meta-title {
   color: var(--color-primary-200);
   font-size: 18px;
   font-weight: 600;
   line-height: 1;
 }

 .testimonials-layout2 .testimonial-meta-desc,
 .testimonials-layout3 .testimonial-meta-desc {
   font-size: 15px;
 }

 .testimonials-layout2 .slider-nav-thumbnails .slick-list,
 .testimonials-layout3 .slider-nav-thumbnails .slick-list {
   overflow: visible;
 }

 .testimonials-layout2 .slider-wrap,
 .testimonials-layout3 .slider-wrap {
   position: relative;
 }

 .testimonials-layout2 .slider-wrap:before,
 .testimonials-layout3 .slider-wrap:before {
   content: "\e9d3";
   font-family: "icomoon";
   position: absolute;
   line-height: 1;
   font-size: 125px;
   color: var(--color-gray-700);
 }

 .testimonials-layout2 .testimonial-rating {
   margin-bottom: 13px;
 }

 .testimonials-layout2 .testimonial-rating .active {
   color: var(--color-primary);
 }

 .testimonials-layout2 .testimonial-meta {
   padding-inline-start: 110px;
 }

 .testimonials-layout2 .testimonial-title {
   font-size: 26px;
   margin-bottom: 32px;
 }

 .testimonials-layout2 .testimonial-thmb {
   position: relative;
   padding: 0;
   width: 88px;
   height: 88px;
   box-shadow: 0 0 0 5px var(--color-white);
 }

 .testimonials-layout2 .testimonial-thmb.slick-current {
   z-index: 2;
   box-shadow: 0 0 0 5px var(--color-white), 0 0 0 9px var(--color-primary), 0 0 0 14px var(--color-white);
 }

 .testimonials-layout2 .slick-arrow {
   top: auto;
   bottom: 0;
   transform: translateY(0);
 }

 .testimonials-layout2 .slick-arrow.slick-next {
   right: auto;
   left: 45px;
 }

 .testimonials-layout2 .slider-wrap:before {
   top: -60px;
   left: -50px;
 }

 .testimonials-layout3 .slider-wrap {
   max-width: 670px;
   border-radius: 4px 0 0 0;
   background-color: var(--color-primary);
 }

 .testimonials-layout3 .slider-wrap:before {
   right: 40px;
   bottom: 20px;
   opacity: 0.15;
 }

 .testimonials-layout3 .slider-wrap:after {
   content: "";
   position: absolute;
   bottom: -40px;
   left: 0;
   z-index: 2;
   border-right: 40px solid transparent;
   border-top: 40px solid var(--color-primary);
 }

 .testimonials-layout3 .testimonial-title {
   font-size: 22px;
   margin-bottom: 40px;
 }

 .testimonials-layout3 .testimonial-meta {
   padding-inline-start: 150px;
 }

 .testimonials-layout3 .slider-nav-thumbnails {
   margin-top: -50px;
 }

 .testimonials-layout3 .testimonial-thmb {
   position: relative;
   padding: 0;
   width: 55px;
   height: 55px;
   box-shadow: 0 0 0 5px var(--color-primary);
 }

 .testimonials-layout3 .testimonial-thmb.slick-current {
   z-index: 2;
   box-shadow: 0 0 0 5px var(--color-primary), 0 0 0 8px var(--color-white), 0 0 0 12px var(--color-primary);
 }

 .testimonials-layout3 .testimonial-title {
   display: -webkit-box;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: normal;
   -webkit-line-clamp: 6;
   line-clamp: 6;
 }

 .testimonials-layout3 .slick-arrow {
   display: none !important;
 }

 .testimonials-light .testimonial-title,
 .testimonials-light .testimonial-meta-title {
   color: var(--color-white);
 }

 .testimonials-light .testimonial-meta-desc {
   color: var(--color-gray-100);
 }

 .testimonials-light .testimonial-meta.slick-current .testimonial-thmb {
   border-color: var(--color-white);
 }

 @media (min-width: 1400px) {
   .testimonials-layout3 .slider-wrap {
     padding: 80px;
   }
 }

 @media (max-width: 1399px) {
   .testimonials-layout3 .slider-wrap {
     padding: 30px;
     max-width: 630px;
   }
 }

 @media (max-width: 991px) {
   .testimonial-title {
     font-size: 18px;
   }

   .testimonial-meta-title {
     font-size: 14px;
   }

   .testimonials-layout1 .testimonial-item {
     padding: 30px;
   }

   .testimonials-layout2 .slider-wrap {
     padding-top: 40px;
   }

   .testimonials-layout2 .slider-wrap:before {
     top: 10px;
     left: -10px;
   }

   .testimonials-layout2 .testimonial-title {
     font-size: 20px;
   }

   .testimonials-layout2 .testimonial-thmb {
     width: 55px;
     height: 55px;
   }

   .testimonials-layout2 .testimonial-thmb.slick-current {
     box-shadow: 0 0 0 3px var(--color-white), 0 0 0 6px var(--color-primary), 0 0 0 10px var(--color-white);
   }
 }

 /*---------------------------
      Client
  ---------------------------*/
 .client {
   position: relative;
   display: flex !important;
   align-items: center;
   justify-content: center;
   height: 90px;
 }

 .client img {
   padding: 0 15px;
   transition: all 0.3s ease;
 }

 .client:hover img {
   transform: scale(1.1);
 }

 .clients-light .client img {
   filter: brightness(100);
 }

 /*--------------------
       Blog
  -------------------*/
 .post-item {
   position: relative;
   margin-bottom: 50px;
   transition: all 0.3s linear;
 }

 .post-item .post-img {
   position: relative;
   overflow: hidden;
   border-radius: 10px;
 }

 .post-item .post-img img {
   width: 100%;
   transition: all 0.9s ease;
 }

 .post-item .post-title {
   font-size: 20px;
   font-weight: 500;
   margin-bottom: 15px;
 }

 .post-item .post-title a {
   color: var(--color-primary-200);
 }

 .post-item .post-title a:hover {
   color: var(--color-primary);
 }

 .post-item .post-desc {
   margin-bottom: 25px;
 }

 .post-item .post-meta {
   margin-top: 26px;
   padding-top: 25px;
   border-top: 2px solid var(--color-gray-600);
 }

 .post-item .post-meta>* {
   position: relative;
   font-size: 13px;
   line-height: 1;
   margin-right: 8px;
   padding-right: 8px;
 }

 .post-item .post-meta>*:after {
   content: "";
   position: absolute;
   bottom: 4px;
   right: -2px;
   width: 4px;
   height: 4px;
   background-color: var(--color-primary-500);
 }

 .post-item .post-meta>*:last-child {
   padding-right: 0;
   margin-right: 0;
 }

 .post-item .post-meta>*:last-child:after {
   display: none;
 }

 .post-item .post-meta-author {
   color: var(--color-primary-200);
 }

 .post-item .post-meta-date {
   color: var(--color-primary-500);
 }

 .post-item .post-body {
   position: relative;
   padding-top: 36px;
 }

 .post-item .post-meta-cat {
   position: absolute;
   top: 10px;
   left: 10px;
   z-index: 2;
   display: flex;
   padding: 7px 13px;
   border-radius: 1px;
   background-color: var(--color-secondary);
   transition: all 0.3s ease-in-out 0s;
 }

 .post-item .post-meta-cat a {
   position: relative;
   font-size: 13px;
   padding: 2px 4px;
   color: var(--color-white);
 }

 .post-item .post-meta-cat a:after {
   content: ",";
   position: absolute;
   top: 3px;
   right: -1px;
 }

 .post-item .post-meta-cat a:last-of-type:after {
   display: none;
 }

 .post-item .btn-more {
   position: absolute;
   bottom: 10px;
   right: 10px;
   left: 10px;
   justify-content: space-between;
   transform: translateY(100px);
 }

 .post-item:not(.featured-post):hover .post-img img {
   transform: scale(1.1) rotate(1deg);
 }

 .post-item:not(.featured-post):hover .post-meta-cat {
   transform: translateY(-100px);
 }

 .post-item:not(.featured-post):hover .btn-more {
   transform: translateY(0);
 }

 .blog-layout2 .post-item {
   box-shadow: none;
   border-radius: 0;
 }

 .blog-layout2 .post-item.featured-post {
   padding: 160px 60px 60px;
 }

 .blog-layout2 .post-item.featured-post .post-img img {
   width: 100%;
 }

 .blog-layout2 .post-item.featured-post .post-body {
   padding: 40px 60px 60px;
   background-color: var(--color-white);
 }

 .blog-layout2 .post-item.featured-post .post-title {
   font-size: 26px;
 }

 .blog-layout2 .post-item.featured-post .post-meta-cat {
   top: -20px;
   left: 60px;
 }

 .blog-layout2 .post-item:not(.featured-post) {
   padding-bottom: 25px;
   margin-bottom: 25px;
   border-bottom: 1px solid var(--color-gray-200);
 }

 .blog-layout2 .post-item:not(.featured-post) .post-body {
   padding: 0;
 }

 .blog-layout2 .post-item:not(.featured-post) .post-title {
   margin-bottom: 17px;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: normal;
 }

 .blog-layout2 .post-item:not(.featured-post) .post-meta {
   margin-bottom: 0;
 }

 .blog-layout2 .post-item:not(.featured-post) .post-meta-cat {
   display: inline-flex;
   position: static;
   padding: 0;
   margin-bottom: 12px;
   background-color: transparent;
   border-bottom: 2px solid var(--color-secondary);
   transform: translateY(0);
 }

 .blog-layout2 .post-item:not(.featured-post) .post-meta-cat a {
   color: var(--color-secondary);
 }

 .blog-layout2 .post-item:not(.featured-post) .post-meta-cat a:first-of-type {
   padding-inline-start: 0;
 }

 .blog-layout2 .post-item:not(.featured-post) .post-meta-cat a:last-of-type {
   padding-inline-end: 0;
 }

 .blog-layout2 .post-item .post-img {
   overflow: hidden;
   border-radius: 4px;
 }

 .blog-layout2 .cta-form {
   position: relative;
   overflow: hidden;
   padding: 20px;
   border-radius: 10px;
   background-color: var(--color-primary);
 }

 .blog-layout2 .cta-form:after {
   content: "\e923";
   font-family: "icomoon";
   position: absolute;
   bottom: -40px;
   right: -30px;
   font-size: 150px;
   line-height: 1;
   color: #59dac2;
 }

 .blog-layout2 .cta-form .cta-title {
   font-weight: 500;
   color: var(--color-white);
   margin-bottom: 39px;
 }

 .blog-layout2 .cta-form .form-control {
   border: 0;
   height: 68px;
 }

 .blog-layout2 .cta-form .form-control::-webkit-input-placeholder {
   color: #848e9f;
 }

 .blog-layout2 .cta-form .form-control:-moz-placeholder {
   color: #848e9f;
 }

 .blog-layout2 .cta-form .form-control::-moz-placeholder {
   color: #848e9f;
 }

 .blog-layout2 .cta-form .form-control:-ms-input-placeholder {
   color: #848e9f;
 }

 .post-item-single {
   box-shadow: 0px 5px 83px 0px rgba(13, 14, 67, 0.12);
 }

 .post-item-single .post-title {
   color: var(--color-primary-200);
 }

 .post-item-single .post-meta {
   margin-bottom: 13px;
 }

 .post-item-single .post-img {
   border-radius: 2px;
 }

 .post-item-single .post-body {
   padding: 36px 50px;
 }

 .blog-carousel .slick-list {
   margin: -15px;
   overflow: visible;
 }

 .blog-carousel .slick-slide {
   margin: 15px;
 }

 .blog-carousel .slick-slide:not(.slick-active) {
   opacity: 0;
   visibility: hidden;
 }

 .blog-carousel .slick-arrow {
   top: -47px;
 }

 .blog-carousel .slick-arrow.slick-prev {
   left: auto;
   right: calc((100% - 715px) / 2);
 }

 .blog-carousel .slick-arrow.slick-next {
   right: calc((100% - 800px) / 2);
 }

 .blog-widget-title {
   font-size: 21px;
   margin-bottom: 24px;
 }

 .widget-nav .nav-icon {
   position: relative;
   max-width: 60px;
   flex: 0 0 60px;
   height: 60px;
   border-radius: 2px;
   display: flex;
   align-items: center;
   justify-content: center;
   color: var(--color-primary-500);
   border: 2px solid var(--color-primary-500);
   transition: all 0.3s linear;
 }

 .widget-nav .nav-ttile {
   font-size: 15px;
   font-weight: 700;
   white-space: nowrap;
   color: var(--color-primary-500);
   flex: 0 0 calc(100% - 80px);
   max-width: calc(100% - 80px);
   transition: all 0.3s linear;
 }

 .widget-nav .nav-next .nav-icon {
   margin-right: 20px;
 }

 .widget-nav .nav-prev .nav-icon {
   margin-left: 20px;
 }

 .widget-nav .nav-prev .nav-ttile {
   text-align: right;
 }

 .widget-nav .nav-next,
 .widget-nav .nav-prev {
   flex: 0 0 160px;
   max-width: 160px;
 }

 .widget-nav .nav-next:hover .nav-icon,
 .widget-nav .nav-prev:hover .nav-icon {
   color: var(--color-white);
   border-color: var(--color-primary);
   background-color: var(--color-primary);
 }

 .widget-nav .nav-next:hover .nav-ttile,
 .widget-nav .nav-prev:hover .nav-ttile {
   color: var(--color-primary);
 }

 .blog-author {
   position: relative;
   padding: 50px;
   border-radius: 2px;
   border: 8px solid var(--color-gray-600);
 }

 .blog-author .blog-author-avatar {
   flex: 0 0 150px;
   max-width: 150px;
   overflow: hidden;
   margin-bottom: 20px;
   padding-right: 30px;
 }

 .blog-author .blog-author-avatar img {
   border-radius: 3px;
 }

 .blog-author .blog-author-content {
   flex: calc(100% - 150px);
   max-width: calc(100% - 150px);
 }

 .blog-author .blog-author-name {
   color: var(--color-primary-200);
   font-size: 18px;
   margin-bottom: 8px;
 }

 .blog-author .blog-author-bio {
   font-size: 15px;
   margin-bottom: 13px;
 }

 .bordered-box {
   border-radius: 4px;
   border: 1px solid #e5e8ea;
 }

 .bordered-box .blog-share,
 .bordered-box .blog-tags {
   padding: 25px;
 }

 .comments-list .comment-item {
   position: relative;
   padding-bottom: 20px;
   border-bottom: 2px solid #eaeaea;
   margin-bottom: 30px;
 }

 .comments-list .comment-item:last-child {
   border-bottom: none;
   padding-bottom: 0;
   margin-bottom: 0;
 }

 .comments-list .comment-item .comment-avatar {
   position: absolute;
   top: 0;
   left: 0;
   width: 80px;
   height: 80px;
   overflow: hidden;
 }

 .comments-list .comment-item .comment-avatar img {
   border-radius: 5px;
 }

 .comments-list .comment-item .comment-content {
   padding-left: 120px;
 }

 .comments-list .comment-item .comment-content .comment-author {
   display: inline-flex;
   font-size: 18px;
   margin-right: 20px;
   margin-bottom: 0;
 }

 .comments-list .comment-item .comment-content .comment-date {
   font-size: 12px;
   line-height: 1;
   color: #616161;
 }

 .comments-list .comment-item .comment-content .comment-desc {
   margin-bottom: 6px;
   padding: 35px 40px;
   position: relative;
   margin: 10px 0;
   background-color: var(--color-gray-700);
 }

 .comments-list .comment-item .comment-content .comment-desc:before {
   content: "";
   position: absolute;
   top: 0;
   left: -20px;
   border-style: solid;
   border-width: 0 20px 15px 0;
   border-color: transparent var(--color-gray-700) transparent transparent;
 }

 .comments-list .comment-item .comment-content .comment-reply {
   text-transform: capitalize;
   font-size: 14px;
   font-weight: 700;
   line-height: 1;
   color: var(--color-primary);
 }

 .comments-list .comment-item .comment-content .comment-reply:hover {
   color: var(--color-secondary);
 }

 .comments-list .comment-item .nested-comment {
   border-top: 1px solid var(--color-gray-700);
   padding: 30px 0 0 0;
   margin: 30px 0 0 90px;
 }

 .blog-comments-form .form-control {
   height: 60px;
 }

 .blog-comments-form .btn {
   min-width: 220px;
 }

 .blog-tags ul li a {
   position: relative;
   font-size: 14px;
   padding-right: 5px;
   color: var(--color-primary);
 }

 .blog-tags ul li a:hover {
   color: var(--color-secondary);
 }

 .blog-tags ul li a:before {
   content: ",";
   position: absolute;
   top: -2px;
   right: 1px;
   color: var(--color-primary);
 }

 .blog-tags ul li:last-child a:before {
   display: none;
 }

 @media (max-width: 1199px) {
   .blog-carousel {
     overflow-x: hidden;
   }

   .blog-carousel .slick-arrow.slick-prev {
     right: 45px;
   }

   .blog-carousel .slick-arrow.slick-next {
     right: 0;
   }
 }

 @media (min-width: 992px) {
   .posts-wrap {
     padding-inline-start: 70px;
     position: relative;
   }

   .posts-wrap:before {
     content: "";
     position: absolute;
     top: 0;
     bottom: 0;
     left: 10px;
     width: 1px;
     background-color: var(--color-gray-600);
   }
 }

 @media (min-width: 1200px) {
   .blog-single .post-item .post-title {
     font-size: 40px;
     font-weight: 600;
   }

   .blog-single .post-item .post-desc p {
     font-size: 16px;
     line-height: 1.7;
     margin-bottom: 25px;
   }

   .blog-single .post-item .post-body {
     margin-bottom: 0;
   }

   .blog-carousel .carousel-container {
     margin-inline-end: -140px;
   }

   .blog-layout2 .cta-form {
     padding: 50px;
   }
 }

 @media (min-width: 1500px) {
   .blog-carousel .carousel-container {
     margin-inline-end: -500px;
   }
 }

 @media (max-width: 992px) {
   .blog-carousel .slick-arrow {
     top: -26px;
   }

   .blog-layout2 .post-item.featured-post {
     padding: 50px 30px 30px;
   }

   .blog-layout2 .post-item.featured-post .post-body {
     padding: 30px;
   }

   .blog-layout2 .post-item.featured-post .post-meta-cat {
     left: 30px;
   }

   .blog-layout2 .post-item:not(.featured-post) {
     padding-bottom: 15px;
     margin-bottom: 15px;
   }
 }

 @media (min-width: 768px) {
   .widget-nav-next {
     text-align: right;
     flex-direction: row-reverse;
   }
 }

 /* Mobile Phones and tablets */
 @media (min-width: 320px) and (max-width: 767px) {
   .post-item .post-title {
     font-size: 17px;
     margin-bottom: 10px;
   }

   .post-item .post-body {
     padding-top: 15px;
   }

   .post-item .post-meta {
     margin-top: 15px;
     padding-top: 15px;
   }

   .post-item .post-desc {
     font-size: 14px;
     margin-bottom: 15px;
   }

   .post-item .post-meta-cat {
     padding: 4px 8px;
     border-radius: 2px;
   }

   .blog-carousel .slick-arrow {
     top: -16px;
   }

   .blog-layout2 .post-item.featured-post .post-body {
     padding: 20px;
   }

   .blog-layout2 .cta-form .cta-title {
     font-weight: 500;
     color: var(--color-white);
     margin-bottom: 19px;
   }

   .blog-layout2 .cta-form .btn,
   .blog-layout2 .cta-form .form-control {
     height: 50px;
   }

   .blog-author {
     flex-direction: column;
     padding: 20px;
   }

   .blog-author .blog-author-avatar,
   .blog-author .blog-author-content {
     flex: 100%;
     max-width: 100%;
   }

   .widget-nav .nav-icon {
     max-width: 40px;
     flex: 0 0 40px;
     height: 40px;
   }

   .widget-nav .nav-next .nav-icon {
     margin-right: 10px;
   }

   .widget-nav .nav-prev .nav-icon {
     margin-left: 10px;
   }

   .widget-nav .nav-ttile {
     flex: 0 0 calc(100% - 50px);
     max-width: calc(100% - 50px);
   }

   .comments-list .comment-item .comment-avatar {
     width: 40px;
     height: 40px;
   }

   .comments-list .comment-item .comment-content {
     padding-left: 60px;
   }

   .comments-list .comment-item .comment-content .comment-desc {
     font-size: 13px;
     padding: 10px;
   }

   .comments-list .comment-item .nested-comment {
     padding: 20px 0 0 0;
     margin: 20px 0 0 40px;
   }

   .bordered-box .blog-share,
   .bordered-box .blog-tags {
     padding: 10px;
   }
 }

 /*----------------------------
      Contact
  ----------------------------*/
 .contact-panel {
   position: relative;
   z-index: 2;
   padding: 50px;
   border-radius: 4px;
   background-color: var(--color-white);
   box-shadow: 0px 5px 83px 0px rgba(1, 34, 23, 0.12);
 }

 .contact-panel .panel-header .panel-title {
   font-size: 23px;
   padding-bottom: 24px;
   margin-bottom: 24px;
   color: var(--color-primary-700);
   border-bottom: 1px solid var(--color-gray-600);
 }

 .contact-panel-title {
   font-size: 24px;
   margin-bottom: 14px;
 }

 iframe {
   width: 100%;
 }

 .map-container {
   position: absolute;
   top: 50%;
   z-index: 1;
   width: 440px;
   transform: translateY(-50%);
   margin-left: calc((100% - 1250px) / 2);
   margin-right: calc((100% - 1250px) / 2);
 }

 .map-container .contact-list li a {
   font-family: var(--font-primary);
   font-size: 19px;
   font-weight: 500;
   display: flex;
   align-items: center;
   color: var(--color-primary-700);
 }

 .map-container .contact-list li a .contact-icon {
   margin-right: 15px;
 }

 .map-container .contact-list li a.contact-address {
   font-size: 14px;
   color: var(--color-gray-400);
 }

 .map-container .contact-list li a:hover {
   color: var(--color-primary-500);
 }

 .map-container .contact-list li:last-child {
   margin-bottom: 0;
 }

 .contact-layout1 .contact-list li,
 .contact-layout2 .contact-list li {
   padding-bottom: 12px;
   margin-bottom: 12px;
   border-bottom: 1px solid var(--color-gray-600);
 }

 .contact-layout1 .contact-list li:last-child,
 .contact-layout2 .contact-list li:last-child {
   padding-bottom: 0;
   margin-bottom: 0;
   border-bottom: 0;
 }

 .contact-layout1 .contact-list li a,
 .contact-layout2 .contact-list li a {
   font-size: 16px;
   font-weight: 600;
   padding-inline-start: 7px;
 }

 .contact-layout1 .contact-list li a {
   color: var(--color-primary-700);
 }

 .contact-layout2.bg-overlay-primary-200-gradient:after {
   opacity: 0.95;
 }

 .contact-layout2 .contact-list li a {
   color: var(--color-white);
 }

 .contact-layout2 .section-secondary {
   padding: 50px 0;
   border-bottom: 1px solid var(--color-gray-600);
 }

 .contact-layout3 .banner-statistics {
   max-width: none;
 }

 .contact-layout3 .banner-statistics:after {
   display: none;
 }

 .contact-layout3 .banner-statistics .btn {
   max-width: 212px;
 }

 .contact-layout3 .banners-wrap {
   overflow: hidden;
   border-radius: 4px;
 }

 .contact-layout3 .banners-wrap .contact-panel {
   border-radius: 0;
   box-shadow: none;
 }

 .contact-layout4 .contact-panel {
   margin: 30px 0;
 }

 .contact-layout4 .contact-panel-header {
   background-color: var(--color-primary);
 }

 .contact-layout4 .contact-panel-header .contact-panel-title {
   color: var(--color-white);
 }

 .contact-layout4 .fancybox-area .fancybox-item .fancybox-icon {
   padding-top: 5px;
   flex: 0 0 90px;
 }

 .contact-layout5 {
   margin-top: -105px;
 }

 .contact-layout5 .banner-img {
   min-height: 300px;
 }

 .contact-layout5 .contact-cta-wrap {
   padding: 55px 70px;
   background-color: var(--color-gray-700);
 }

 .contact-layout5 .rating-area {
   position: relative;
   z-index: 2;
   overflow: hidden;
   border-radius: 0 4px 0 4px;
   background-color: rgba(1, 34, 23, 0.7);
 }

 .contact-layout5 .rating-stars {
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 10px;
   height: 100%;
   flex: 0 0 150px;
   color: var(--color-white);
   background-color: var(--color-secondary);
 }

 .contact-layout5 .rating-stars i {
   margin: 2px;
 }

 .contact-layout5 .rating-content {
   padding: 10px 40px;
 }

 .contact-layout5 .rating-content .text-underlined-white {
   border-bottom: 2px solid var(--color-white);
 }

 .contact-layout5 .contact-cta,
 .contact-layout6 .contact-cta {
   border-radius: 4px;
   padding: 0 40px 40px;
   background-color: var(--color-white);
 }

 .contact-layout5 .contact-cta .contact-avatar,
 .contact-layout6 .contact-cta .contact-avatar {
   margin: -25px 0 15px;
 }

 .contact-layout6 .contact-cta {
   position: relative;
   z-index: 2;
   max-width: 400px;
   background-color: var(--color-white);
   box-shadow: 0px 5px 83px 0px rgba(1, 34, 23, 0.12);
 }

 .contact-layout6 .banner-img {
   position: relative;
   z-index: 2;
 }

 .contact-cta .contact-avatar {
   flex: 0 0 130px;
 }

 .contact-cta .contact-avatar img {
   position: relative;
   margin-inline-end: -5px;
   box-shadow: 0 0 0 5px var(--color-white);
 }

 .contact-cta .phone-icon {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 54px;
   height: 54px;
   font-size: 17px;
   padding: 8px;
   border-radius: 50%;
   color: var(--color-white);
   background-color: var(--color-primary);
 }

 .locations-panel {
   margin-bottom: 65px;
 }

 .locations-panel .locations-panel-header {
   border-bottom: 2px solid #eaeaea;
   margin-bottom: 25px;
 }

 .locations-panel .locations-panel-title {
   font-size: 25px;
   margin-bottom: 25px;
 }

 .locations-panel ul {
   display: flex;
   flex-wrap: wrap;
 }

 .locations-panel ul li {
   margin-bottom: 18px;
   flex-basis: 25%;
 }

 .locations-panel ul li a {
   color: var(--color-primary-200);
   font-size: 14px;
   font-weight: 700;
 }

 .locations-panel ul li a:hover {
   color: var(--color-primary);
 }

 .locations-panel ul li a i {
   margin-right: 10px;
 }

 .login-form {
   margin: auto;
   padding: 60px;
   max-width: 400px;
   border-radius: 20px;
   background-color: var(--color-white);
   box-shadow: 0px 5px 83px 0px rgba(13, 14, 67, 0.12);
 }

 @media (min-width: 768px) {
   .contact-layout5 .banner-img {
     margin-inline-end: -130px;
   }

   .contact-layout5 .rating-area {
     height: 105px;
     margin-inline-start: -44px;
   }

   .contact-layout5 .contact-cta-wrap {
     margin-inline-start: 90px;
   }
 }

 @media (min-width: 992px) {
   .contact-layout1 .contact-panel {
     max-width: 660px;
     margin-top: -130px;
   }

   .contact-layout2 .contact-panel {
     max-width: 660px;
     margin-bottom: -130px;
   }

   .contact-layout3 .banner-statistics .counter-number {
     font-size: 154px;
     margin-bottom: 7px;
   }

   .contact-layout3 .banner-statistics .statistics-title {
     font-size: 37px;
     font-weight: 700;
     margin-bottom: 100px;
   }

   .contact-layout3 .banner-statistics .statistics-desc {
     font-size: 17px;
     margin-bottom: 36px;
   }

   .contact-layout4 .contact-panel {
     max-width: 660px;
   }

   .contact-layout4 .contact-panel .contact-panel-form {
     padding: 50px;
   }

   .contact-layout4 .contact-panel-header {
     padding: 20px 50px;
   }

   .contact-layout4 .fancybox-area {
     padding: 70px;
     max-width: 600px;
   }
 }

 @media (min-width: 1200px) {
   .contact-layout3 .banner-statistics {
     padding: 50px 90px;
   }

   .contact-layout3 .contact-panel {
     padding: 50px 70px;
   }

   .contact-layout3 .banners-wrap .col-lg-6:first-child {
     width: 45%;
   }

   .contact-layout3 .banners-wrap .col-lg-6:last-child {
     width: 55%;
   }

   .contact-layout4 .fancybox-area {
     padding: 100px 110px;
   }

   .contact-layout4 .btn-xl {
     height: 68px;
     min-width: 212px;
   }
 }

 @media (min-width: 1400px) {
   .contact-layout5 .rating-area {
     margin-inline-start: -60px;
   }
 }

 @media (max-width: 1199px) {
   .contact-panel {
     padding: 30px;
   }

   .contact-panel-desc {
     font-size: 13px;
   }

   .map-container {
     margin: 30px;
   }
 }

 @media (max-width: 991px) {
   .contact-layout4 .contact-panel .contact-panel-form {
     padding: 30px;
   }

   .contact-layout4 .contact-panel-header {
     padding: 15px 30px;
   }

   .contact-layout4 .fancybox-area {
     padding: 30px;
     margin-bottom: 30px;
     border-radius: 4px;
     overflow: hidden;
   }

   .contact-layout4 .fancybox-area .fancybox-item .fancybox-icon {
     flex: 0 0 60px;
   }

   .contact-layout4 .fancybox-area .fancybox-item .fancybox-desc {
     font-size: 14px;
   }
 }

 @media (min-width: 320px) and (max-width: 767px) {
   .contact-layout5 .rating-area {
     flex-direction: column;
   }

   .contact-layout5 .rating-stars {
     height: auto;
     flex: 1;
     width: 100%;
   }

   .contact-layout5 .contact-cta-wrap {
     padding: 40px 20px 20px;
   }

   .contact-layout5 .contact-cta-wrap .contact-cta {
     padding: 0 20px 20px;
   }
 }

 /*--------------------------
          pricing
  --------------------------*/
 .pricing-tabs .nav-link {
   --bs-nav-link-padding-y: 20px;
   --bs-nav-link-padding-x: 30px;
   --bs-nav-link-font-size: 15;
   --bs-nav-link-font-weight: 700;
   --bs-nav-link-color: var(--color-white);
   --bg-color: var(--color-primary-200);
   position: relative;
   margin: 0 5px;
   background-color: var(--bg-color);
 }

 .pricing-tabs .nav-link:after {
   content: "";
   position: absolute;
   left: 50%;
   transform: translateX(-50%);
   bottom: -7px;
   border-style: solid;
   border-width: 7px 7px 0;
   border-color: var(--bg-color) transparent transparent transparent;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
 }

 .pricing-tabs .nav-link.active,
 .pricing-tabs .nav-link:hover {
   --bg-color: var(--color-primary);
   color: var(--bs-nav-link-color);
 }

 .pricing-widget {
   padding: 50px;
   border-radius: 4px;
   margin-bottom: 40px;
   background-color: var(--color-gray-700);
 }

 .pricing-widget .pricing-title {
   font-size: 20px;
 }

 .pricing-widget .pricing-desc:last-of-type {
   margin: 10px 0 20px;
 }

 .pricing-widget .pricing-body {
   position: relative;
   z-index: 2;
 }

 .pricing-layout1 .pricing-img {
   flex: 0 0 180px;
   max-width: 180px;
   padding-inline-end: 40px;
 }

 .pricing-layout1 .pricing-widget .pricing-icon {
   position: absolute;
   bottom: -40px;
   right: -20px;
   font-size: 144px;
   line-height: 1;
   color: #d7e6ce;
   z-index: -1;
   opacity: 0;
   transition: 0.3s ease;
   transform: scale(0.3);
 }

 .pricing-layout1 .pricing-widget:hover .pricing-icon {
   opacity: 1;
   transform: scale(1);
 }

 .pricing-layout2 .pricing-item {
   margin-bottom: 40px;
 }

 .pricing-layout2 .pricing-item .pricing-desc {
   font-size: 17px;
   color: var(--color-primary-200);
 }

 .pricing-layout2 .pricing-item .pricing-desc:first-of-type {
   font-weight: 700;
 }

 .pricing-layout2 .pricing-item .pricing-body .btn {
   height: 58px;
 }

 .pricing-layout2 .pricing-item .pricing-widget {
   margin-bottom: 0;
   border-radius: 5px;
   padding: 75px 100px 80px;
 }

 .pricing-layout2 .pricing-item .pricing-widget .pricing-icon {
   position: absolute;
   top: -30px;
   right: -40px;
   font-size: 144px;
   line-height: 1;
   color: #d7e6ce;
   z-index: -1;
   transition: 0.3s ease;
 }

 .pricing-layout2 .pricing-item .pricing-img {
   min-height: 400px;
   border-radius: 5px;
   overflow: hidden;
 }

 .pricing-list li {
   display: flex;
   justify-content: space-between;
   align-items: center;
   font-weight: 700;
   font-size: 15px;
   padding-bottom: 10px;
   margin-bottom: 10px;
   border-bottom: 1px solid #dcdee2;
 }

 .pricing-list li:last-child {
   padding-bottom: 0;
   margin-bottom: 0;
   border-bottom: 0;
 }

 .pricing-list li .price {
   line-height: 1;
   padding: 6px 10px;
   border-radius: 5px;
 }

 @media (min-width: 992px) {
   .pricing-layout2 .pricing-item {
     justify-content: space-between;
   }

   .pricing-layout2 .pricing-item:nth-of-type(odd) {
     flex-direction: row;
   }

   .pricing-layout2 .pricing-item:nth-of-type(even) {
     flex-direction: row-reverse;
   }

   .pricing-layout2 .pricing-item .pricing-img {
     width: calc(56% - 40px);
   }

   .pricing-layout2 .pricing-item .pricing-widget {
     width: 44%;
   }
 }

 @media (max-width: 991px) {
   .pricing-layout2 .pricing-item {
     flex-direction: column;
   }

   .pricing-layout2 .pricing-item .pricing-img {
     width: 100%;
   }
 }

 @media (max-width: 1200px) {
   .pricing-widget {
     padding: 20px;
   }

   .pricing-layout1 .pricing-img {
     padding-inline-end: 20px;
   }

   .pricing-layout2 .pricing-item .pricing-widget {
     padding: 40px;
   }
 }

 @media (min-width: 320px) and (max-width: 767px) {
   .pricing-layout2 .pricing-item .pricing-widget .pricing-icon {
     top: 0;
     right: 0;
     font-size: 90px;
   }

   .pricing-tabs .nav-link {
     --bs-nav-link-padding-y: 10px;
     --bs-nav-link-padding-x: 15px;
     --bs-nav-link-font-size: 13px;
   }
 }

 /* Extra Small Devices */
 @media (min-width: 320px) and (max-width: 575px) {
   .pricing-layout1 .pricing-widget {
     flex-direction: column;
   }

   .pricing-layout1 .pricing-img {
     margin-bottom: 15px;
   }
 }

 /*--------------------
      Sidebar
  ----------------------*/
 .sidebar.sticky-top {
   top: 100px;
 }

 .widget {
   position: relative;
   padding: 50px;
   margin-bottom: 40px;
   background-color: var(--color-gray-700);
 }

 .widget:last-child {
   margin-bottom: 0;
 }

 .widget .widget-title {
   font-size: 22px;
   margin-bottom: 23px;
 }

 .widget-search {
   background-color: var(--color-primary);
 }

 .widget-search .widget-form-search {
   position: relative;
 }

 .widget-search .widget-form-search .form-control {
   height: 55px;
 }

 .widget-search .widget-form-search .btn {
   position: absolute;
   top: 0;
   right: 20px;
   width: auto;
   color: var(--color-primary-200);
   padding: 0;
   min-width: 0;
   height: 55px;
   line-height: 55px;
 }

 .widget-search .widget-form-search .btn:before {
   display: none;
 }

 .widget-search .widget-form-search .btn:hover {
   color: var(--color-primary);
 }

 .widget-categories ul li {
   margin-bottom: 11px;
 }

 .widget-categories ul li:last-child {
   margin-bottom: 0;
 }

 .widget-categories ul li a {
   position: relative;
   display: flex;
   align-items: center;
   justify-content: space-between;
   font-size: 15px;
   font-weight: 700;
   padding: 20px;
   border-radius: 3px;
   text-transform: capitalize;
   color: var(--color-white);
   background-color: var(--color-primary-200);
   transition: all 0.3s linear;
 }

 .widget-categories ul li a .cat-count {
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 13px;
   font-weight: 400;
   width: 24px;
   height: 24px;
   border-radius: 50%;
   color: var(--color-primary-200);
   background-color: var(--color-white);
   transition: all 0.3s linear;
 }

 .widget-categories ul li a:hover,
 .widget-categories ul li a.active {
   background-color: var(--color-primary-500);
 }

 .widget-categories ul li a:hover .cat-count,
 .widget-categories ul li a.active .cat-count {
   color: var(--color-primary-500);
   background-color: var(--color-white);
 }

 .widget-categories-layout2 ul li {
   margin-bottom: 11px;
 }

 .widget-categories-layout2 ul li:last-child {
   margin-bottom: 0;
 }

 .widget-categories-layout2 ul li a {
   position: relative;
   display: flex;
   font-size: 15px;
   font-weight: 700;
   color: var(--color-primary);
 }

 .widget-categories-layout2 ul li a:hover {
   color: var(--color-secondary);
 }

 .widget-services ul li {
   margin-bottom: 4px;
 }

 .widget-services ul li:last-child {
   margin-bottom: 0;
 }

 .widget-services ul li a {
   position: relative;
   display: flex;
   align-items: center;
   justify-content: space-between;
   font-size: 15px;
   font-weight: 700;
   padding: 22px 30px;
   text-transform: capitalize;
   border-radius: 50px;
   font-family: var(--font-primary);
   color: var(--color-secondary);
   background-color: var(--color-white);
   transition: all 0.4s ease;
 }

 .widget-services ul li a:hover {
   color: var(--color-white);
   background-color: var(--color-primary);
 }

 .widget-tags ul {
   display: flex;
   flex-wrap: wrap;
 }

 .widget-tags ul li a {
   display: block;
   font-size: 14px;
   line-height: 1;
   text-transform: capitalize;
   padding: 10px 13px;
   margin: 0 10px 10px 0;
   border-radius: 2px;
   color: var(--color-white);
   background-color: var(--color-secondary);
   transition: all 0.4s ease;
 }

 .widget-tags ul li a:hover {
   color: var(--color-white);
   background-color: var(--color-primary);
 }

 /*widget posts*/
 .widget-post-item {
   margin-bottom: 25px;
 }

 .widget-post-item:last-of-type {
   margin-bottom: 0;
 }

 .widget-post-item .widget-post-title {
   font-size: 16px;
   margin-bottom: 0;
 }

 .widget-post-item .widget-post-title a {
   color: var(--color-primary-200);
 }

 .widget-post-item:hover .widget-post-title a {
   color: var(--color-primary);
 }

 .widget-post-item .widget-post-img {
   position: relative;
   flex: 0 0 80px;
   max-width: 80px;
   height: 80px;
   margin-right: 15px;
   padding-bottom: 80px;
 }

 .widget-post-item .widget-post-img img {
   position: absolute;
   width: 100%;
   height: 100%;
   border-radius: 2px;
 }

 .widget-post-item .widget-post-date {
   line-height: 1;
   font-size: 13px;
   margin-bottom: 4px;
   color: var(--color-primary-500);
 }

 /*widget products*/
 .widget-poducts .widget-product-item {
   margin-bottom: 20px;
 }

 .widget-poducts .widget-product-item:last-of-type {
   margin-bottom: 0;
 }

 .widget-poducts .widget-product-item .widget-product-title {
   font-size: 15px;
   margin-bottom: 0;
   color: var(--color-primary-200);
 }

 .widget-poducts .widget-product-item .widget-product-title a {
   color: var(--color-primary);
 }

 .widget-poducts .widget-product-item:hover .widget-product-title a {
   color: var(--color-primary);
 }

 .widget-poducts .widget-product-item .widget-product-img {
   margin-right: 20px;
   flex: 0 0 60px;
   max-width: 60px;
   height: 60px;
   overflow: hidden;
   border-radius: 2px;
 }

 .widget-poducts .widget-product-item .widget-product-img img {
   max-height: 100%;
 }

 .widget-poducts .widget-product-item .widget-product-price {
   color: var(--color-secondary);
   font-size: 15px;
   font-weight: 700;
 }

 /* Widget banner */
 .widget-banner.banner-statistics.bg-overlay-primary:before {
   background-color: rgba(26, 126, 0, 0.97);
 }

 .widget-banner .widget-icon {
   font-size: 55px;
   margin-bottom: 4px;
 }

 .widget-banner .widget-title {
   font-size: 24px;
   color: var(--color-white);
 }

 .widget-banner .widget-desc {
   font-size: 16px;
   color: var(--color-gray-100);
   margin-bottom: 90px;
 }

 .widget-banner .phone-number {
   font-size: 20px;
   margin-top: 5px;
   color: var(--color-white) !important;
 }

 .widget-banner .contact-list li {
   padding-bottom: 12px;
   margin-bottom: 12px;
   color: var(--color-white);
   border-bottom: 1px solid var(--color-gray-600);
 }

 .widget-banner .contact-list li:last-child {
   padding-bottom: 0;
   margin-bottom: 0;
   border-bottom: 0;
 }

 .widget-banner .contact-list li a {
   font-size: 16px;
   font-weight: 600;
   padding-inline-start: 7px;
   color: var(--color-white);
 }

 .widget-banner .contact-list li .contact-icon {
   margin-right: 15px;
 }

 /* Widget schedule */
 .widget-schedule {
   background-color: #f6f6f6;
 }

 .widget-schedule .widget-icon {
   color: var(--color-secondary);
   font-size: 50px;
   margin-bottom: 6px;
 }

 .time-list li {
   display: flex;
   justify-content: space-between;
   padding-bottom: 10px;
   margin-bottom: 10px;
   border-bottom: 1px solid #dcdee2;
 }

 .time-list li:last-child {
   margin-bottom: 0;
   border-bottom: 0;
 }

 /* widget member */
 .widget-member {
   padding: 0;
   overflow: visible;
 }

 .widget-member .member {
   z-index: 3;
   box-shadow: 0px 5px 83px 0px rgba(9, 29, 62, 0.15);
 }

 .widget-member .member:before {
   transform: scaleX(1);
 }

 .widget-member .member .social-icons li {
   opacity: 1;
 }

 .widget-banner {
   border-bottom: 0;
 }

 /* Widget download */
 .widget-download .btn {
   height: 80px;
   border-radius: 0;
   -webkit-clip-path: none;
   clip-path: none;
   padding: 0;
   justify-content: flex-start;
   text-align: center;
 }

 .widget-download .btn:not(:last-child) {
   margin-bottom: 20px;
 }

 .widget-download .btn .btn-icon {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 80px;
   height: 80px;
   color: var(--color-white);
   font-size: 30px;
   margin-right: 10px;
   margin: 0;
   transition: all 0.3s ease;
   background-color: rgba(255, 255, 255, 0.1);
 }

 .widget-download .btn .btn-text {
   flex: 1;
 }

 /* Widget Filter */
 .widget-filter .ui-slider {
   background-color: rgba(34, 34, 34, 0.06);
   border-radius: 0;
   height: 4px;
   margin-bottom: 18px;
 }

 .widget-filter .ui-slider-range {
   height: 4px;
   background-color: var(--color-primary);
 }

 .widget-filter .price-output {
   flex: 0 0 50%;
   max-width: 50%;
 }

 .widget-filter label {
   color: var(--bs-gray);
   font-size: 14px;
   margin-bottom: 0;
 }

 .widget-filter input {
   background-color: transparent;
   color: var(--bs-gray);
   font-size: 14px;
   border: none;
 }

 .widget-filter .btn-filter {
   font-size: 14px;
   font-weight: 700;
   color: var(--color-secondary);
 }

 .widget-filter .btn-filter:hover {
   color: var(--color-primary);
 }

 .ui-slider {
   position: relative;
 }

 .ui-state-default,
 .ui-widget-content .ui-state-default,
 .ui-widget-header .ui-state-default,
 .ui-widget.ui-widget-content {
   border: none;
 }

 .ui-slider.ui-state-disabled .ui-slider-handle,
 .ui-slider.ui-state-disabled .ui-slider-range {
   filter: inherit;
 }

 .ui-slider-horizontal .ui-slider-handle {
   top: 50%;
   transform: translateY(-50%);
   margin: 0;
   width: 10px;
   height: 10px;
   border-radius: 2px;
   background-color: var(--color-primary);
 }

 .ui-slider-horizontal .ui-slider-range-min {
   left: 0;
 }

 .ui-slider-horizontal .ui-slider-range-max {
   right: 0;
 }

 .sidebar-layout2 .widget {
   padding: 0;
   box-shadow: none;
   border-bottom: 0;
   background-color: transparent;
 }

 @media (min-width: 1200px) {

   .sidebar.has-marign-end,
   .sidebar-layout2.has-marign-end {
     margin-inline-end: 40px;
   }

   .sidebar.has-marign-start,
   .sidebar-layout2.has-marign-start {
     margin-inline-start: 40px;
   }

   .widget-member.shifted-top {
     margin-top: -450px;
   }
 }

 @media (max-width: 992px) {
   .widget {
     padding: 15px;
     margin-bottom: 20px;
   }

   .widget .widget-title {
     margin-bottom: 14px;
   }

   .widget-categories ul {
     padding: 0;
   }

   .widget-categories ul li a {
     font-size: 13px;
   }
 }

 /*----------------------
      About
  -----------------------*/
 .about-img {
   position: relative;
 }

 .about-img img {
   border-radius: 5px;
 }

 .about-text>p:first-of-type {
   font-weight: 700;
 }

 .about-layout1 .author-thumb,
 .about-layout2 .author-thumb {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 68px;
   height: 68px;
   padding: 6px;
   border-radius: 50%;
   border: 2px solid var(--color-secondary);
 }

 .about-layout1 .about-img {
   padding-bottom: 40px;
 }

 .about-layout1 .about-img:after {
   content: "";
   position: absolute;
   bottom: 0px;
   right: -40px;
   width: 300px;
   height: 326px;
   z-index: -1;
   background-image: url('../assets/images/patterns/1.png');
 }

 .about-layout1 .about-banner {
   position: absolute;
   bottom: 0;
   left: -40px;
   width: 360px;
   padding: 37px 40px;
   border-radius: 3px;
   background-color: var(--color-gray-700);
   box-shadow: 0 0 0 40px var(--color-white);
 }

 .about-layout1 .about-banner .banner-text {
   font-size: 20px;
   font-weight: 500;
   font-style: italic;
   line-height: 1.5;
   color: var(--color-primary-700);
 }

 .about-layout1 .features-list-layout1+.btn {
   margin-left: 90px;
 }

 .about-layout2 .about-img {
   margin-bottom: 40px;
 }

 .about-layout2 .about-img:after {
   content: "";
   position: absolute;
   bottom: -40px;
   left: -40px;
   width: 300px;
   height: 326px;
   z-index: -1;
   background-image: url('../assets/images/patterns/6.png');
 }

 .about-layout2 .banner-statistics {
   bottom: 50%;
   transform: translateY(50%);
 }

 .author-meta .author-img {
   margin-right: 15px;
 }

 .author-meta .author-img img {
   border: 10px solid rgba(var(--color-primary-rgb), 0.2);
 }

 .author-meta .author-title {
   font-size: 17px;
   margin-bottom: 2px;
 }

 @media (max-width: 1199px) {
   .about-layout1 .about-img {
     margin: 0 0 40px 0;
     margin-inline-end: 30px;
   }

   .about-layout1 .about-banner {
     left: 0;
     padding: 20px;
     box-shadow: 0 0 0 10px var(--color-white);
   }

   .about-layout1 .about-banner .banner-text {
     font-size: 17px;
   }

   .about-layout2 .about-img {
     padding: 0 20px;
     margin: 20px 0 0;
   }

   .about-layout2 .about-img:after {
     bottom: -20px;
     left: -20px;
   }
 }

 @media (min-width: 1200px) {
   .about-text>p {
     font-size: 17px;
   }

   .about-layout1 .about-img {
     margin-inline-end: 70px;
   }

   .about-layout1 .heading-title {
     padding-inline-end: 60px;
   }

   .about-layout1 .btn-xl {
     min-width: 230px;
   }

   .about-layout1 .about-text>p {
     font-size: 17px;
   }

   .about-layout2 .about-img {
     margin-inline-start: 70px;
     padding-inline-end: 100px;
   }
 }

 /* Extra Small Devices */
 @media (min-width: 320px) and (max-width: 575px) {
   .about-layout2 .banner-statistics {
     padding: 20px;
     border-radius: 4px;
     overflow: hidden;
     max-width: 300px;
   }

   .about-layout2 .banner-statistics .counter-number {
     font-size: 60px;
   }

   .about-layout2 .banner-statistics .statistics-title {
     font-size: 20px;
   }

   .about-layout2 .banner-statistics .statistics-desc {
     font-size: 14px;
     margin-bottom: 23px;
   }

   .about-layout2 .banner-statistics .feature-item .feature-title {
     font-size: 14px;
   }
 }

 /*-----------------------
      Services 
  --------------------------*/
 .service-item {
   position: relative;
   overflow: hidden;
   margin-bottom: 40px;
   border-radius: 6px;
   background-color: var(--color-white);
   box-shadow: 0px 5px 83px 0px rgba(13, 14, 67, 0.08);
   transition: all 0.4s ease;
 }

 .service-item .service-body {
   position: relative;
 }

 .service-item .service-category {
   display: inline-flex;
   padding: 7px 13px;
   border-radius: 1px;
   margin-bottom: 18px;
   background-color: var(--color-secondary);
   transition: all 0.3s ease-in-out 0s;
 }

 .service-item .service-category a {
   position: relative;
   font-size: 13px;
   padding: 2px 4px;
   color: var(--color-white);
 }

 .service-item .service-category a:after {
   content: ",";
   position: absolute;
   top: 3px;
   right: -1px;
 }

 .service-item .service-category a:last-of-type:after {
   display: none;
 }

 .service-item .service-icon {
   font-size: 50px;
   line-height: 1;
   margin-bottom: 23px;
   transition: all 0.3s linear;
 }

 .service-item .service-img {
   position: relative;
 }

 .service-item .service-img img {
   width: 100%;
   border-radius: 6px;
   transition: all 0.3s linear;
 }

 .service-item .service-title {
   font-size: 20px;
   margin-bottom: 15px;
 }

 .service-item .service-title a {
   color: var(--color-primary-200);
 }

 .service-item .service-desc {
   margin-bottom: 0;
 }

 .service-item .service-title,
 .service-item .service-desc,
 .service-item .service-category a {
   position: relative;
   z-index: 2;
   transition: all 0.3s linear;
 }

 .service-item .divider {
   height: 2px;
   margin: 25px 0;
   background-color: var(--color-gray-600);
 }

 .service-item:hover {
   box-shadow: 0px 5px 83px 0px rgba(40, 40, 40, 0.12);
 }

 .services-layout1 .btn [class*=icon-],
 .services-layout2 .btn [class*=icon-],
 .services-layout9 .btn [class*=icon-] {
   margin-left: 5px;
 }

 .services-layout1 .btn.btn-underlined.btn-primary,
 .services-layout2 .btn.btn-underlined.btn-primary,
 .services-layout9 .btn.btn-underlined.btn-primary {
   border-bottom-color: transparent !important;
 }

 .services-layout1 .btn.btn-underlined.btn-primary:hover,
 .services-layout2 .btn.btn-underlined.btn-primary:hover,
 .services-layout9 .btn.btn-underlined.btn-primary:hover {
   border-bottom-color: inherit !important;
 }

 .services-layout1 .service-item .service-img {
   flex: 0 0 35%;
   width: 35%;
 }

 .services-layout1 .service-item .service-body {
   flex: 0 0 65%;
   width: 65%;
   background-color: var(--color-white);
 }

 .services-layout1 .service-item .btn-link:not(:hover) {
   border-bottom-color: transparent !important;
 }

 .services-layout2 .service-item,
 .services-layout3 .service-item,
 .services-layout9 .service-item {
   z-index: 3;
 }

 .services-layout2 .service-item .service-icon,
 .services-layout3 .service-item .service-icon,
 .services-layout9 .service-item .service-icon {
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 50px;
   width: 88px;
   height: 88px;
   color: var(--color-white);
   background-color: var(--color-primary-200);
 }

 .services-layout2 .service-item .btn-link:not(:hover),
 .services-layout3 .service-item .btn-link:not(:hover),
 .services-layout9 .service-item .btn-link:not(:hover) {
   border-bottom-color: transparent !important;
 }

 .services-layout2 .service-item:hover .service-icon,
 .services-layout3 .service-item:hover .service-icon,
 .services-layout9 .service-item:hover .service-icon {
   background-color: var(--color-secondary);
 }

 .services-layout2 .service-item .service-icon,
 .services-layout9 .service-item .service-icon {
   margin-top: -68px;
 }

 .services-layout3 {
   padding-bottom: 160px;
 }

 .services-layout3 .service-item .service-icon {
   position: absolute;
   top: 130px;
   left: 40px;
   opacity: 0;
   transform: translateY(10px);
 }

 .services-layout3 .service-item .btn {
   min-width: 134px;
   height: 48px;
   margin-top: 36px;
 }

 .services-layout3 .service-item .service-item-inner {
   margin-bottom: -88px;
   transition: all 0.3s linear;
 }

 .services-layout3 .service-item .service-body {
   padding: 35px 40px 40px;
 }

 .services-layout3 .service-item:hover .service-item-inner {
   transform: translateY(-98px);
 }

 .services-layout3 .service-item:hover .service-icon {
   opacity: 1;
   transform: translateY(0);
 }

 .services-layout4 .service-item,
 .services-layout5 .service-item,
 .services-layout6 .service-item,
 .services-layout7 .service-item {
   overflow: hidden;
   box-shadow: 0px 5px 83px 0px rgba(1, 34, 23, 0.07);
 }

 .services-layout4 .service-item .service-title,
 .services-layout5 .service-item .service-title,
 .services-layout6 .service-item .service-title,
 .services-layout7 .service-item .service-title {
   font-size: 23px;
   font-weight: 700;
   margin-bottom: 24px;
 }

 .services-layout4 .service-item .service-icon,
 .services-layout5 .service-item .service-icon,
 .services-layout6 .service-item .service-icon,
 .services-layout7 .service-item .service-icon {
   color: var(--color-primary);
   font-size: 65px;
   margin-bottom: 16px;
 }

 .services-layout4 .service-item .service-desc,
 .services-layout5 .service-item .service-desc,
 .services-layout6 .service-item .service-desc,
 .services-layout7 .service-item .service-desc {
   margin-bottom: 16px;
 }

 .services-layout4 .service-item .features-list-layout1,
 .services-layout5 .service-item .features-list-layout1,
 .services-layout6 .service-item .features-list-layout1,
 .services-layout7 .service-item .features-list-layout1 {
   margin-bottom: 32px;
 }

 .services-layout4 .service-item .feature-item:before,
 .services-layout5 .service-item .feature-item:before,
 .services-layout6 .service-item .feature-item:before,
 .services-layout7 .service-item .feature-item:before {
   color: var(--color-primary);
 }

 .services-layout4 .service-item .service-category,
 .services-layout5 .service-item .service-category,
 .services-layout6 .service-item .service-category,
 .services-layout7 .service-item .service-category {
   position: absolute;
   top: 20px;
   right: 20px;
   z-index: 3;
   font-size: 14px;
   font-weight: 700;
   padding: 5px 10px;
   color: var(--color-primary-200);
   background-color: #e8e8dd;
 }

 .services-layout4 .service-item:hover .service-overlay,
 .services-layout5 .service-item:hover .service-overlay,
 .services-layout6 .service-item:hover .service-overlay,
 .services-layout7 .service-item:hover .service-overlay {
   opacity: 1;
   visibility: visible;
   transform: translateX(0);
 }

 .services-layout4 .service-item:hover .btn-link,
 .services-layout4 .service-item:hover .service-title a,
 .services-layout4 .service-item:hover .service-icon,
 .services-layout4 .service-item:hover .feature-item:before,
 .services-layout4 .service-item:hover .feature-item .feature-title,
 .services-layout5 .service-item:hover .btn-link,
 .services-layout5 .service-item:hover .service-title a,
 .services-layout5 .service-item:hover .service-icon,
 .services-layout5 .service-item:hover .feature-item:before,
 .services-layout5 .service-item:hover .feature-item .feature-title,
 .services-layout6 .service-item:hover .btn-link,
 .services-layout6 .service-item:hover .service-title a,
 .services-layout6 .service-item:hover .service-icon,
 .services-layout6 .service-item:hover .feature-item:before,
 .services-layout6 .service-item:hover .feature-item .feature-title,
 .services-layout7 .service-item:hover .btn-link,
 .services-layout7 .service-item:hover .service-title a,
 .services-layout7 .service-item:hover .service-icon,
 .services-layout7 .service-item:hover .feature-item:before,
 .services-layout7 .service-item:hover .feature-item .feature-title {
   color: var(--color-white);
 }

 .services-layout4 .service-item:hover .service-desc,
 .services-layout5 .service-item:hover .service-desc,
 .services-layout6 .service-item:hover .service-desc,
 .services-layout7 .service-item:hover .service-desc {
   color: var(--color-gray-100);
 }

 .services-layout4 .service-item:hover .btn-link,
 .services-layout5 .service-item:hover .btn-link,
 .services-layout6 .service-item:hover .btn-link,
 .services-layout7 .service-item:hover .btn-link {
   border-bottom-color: var(--color-white) !important;
 }

 .services-layout4 .service-body,
 .services-layout5 .service-body,
 .services-layout6 .service-body,
 .services-layout7 .service-body {
   z-index: 2;
 }

 .services-layout4 .service-overlay,
 .services-layout5 .service-overlay,
 .services-layout6 .service-overlay,
 .services-layout7 .service-overlay {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   opacity: 0;
   visibility: hidden;
   transition: all 0.3s ease-in-out;
   transform: translateX(-100%);
 }

 .services-layout4 .service-overlay .bg-overlay-primary:before,
 .services-layout5 .service-overlay .bg-overlay-primary:before,
 .services-layout6 .service-overlay .bg-overlay-primary:before,
 .services-layout7 .service-overlay .bg-overlay-primary:before {
   background-color: var(--color-primary);
 }

 .services-layout5 .service-item {
   border-radius: 0;
   box-shadow: 0px 5px 83px 0px rgba(13, 14, 67, 0.08);
 }

 .services-layout6 {
   padding-top: 200px;
   margin-top: -110px;
 }

 .services-layout6 .service-item {
   border-top: 6px solid #e8e8dd;
   box-shadow: 0px 5px 83px 0px rgba(13, 14, 67, 0.08);
 }

 .services-layout6 .service-item .service-category {
   top: 0;
 }

 .services-layout6 .service-item .btn-outlined {
   border-color: #81a297;
 }

 .services-layout6 .service-item .btn-outlined:hover {
   border-color: var(--color-primary);
 }

 .services-layout6 .service-item:hover {
   border-top-color: var(--color-secondary);
 }

 .services-layout6 .service-item:hover .service-category {
   color: var(--color-white);
   background-color: var(--color-secondary);
 }

 .services-layout6 .service-item:hover .service-title a,
 .services-layout6 .service-item:hover .service-desc,
 .services-layout6 .service-item:hover .service-icon {
   color: inherit;
 }

 .services-layout7 {
   padding-bottom: 70px;
 }

 .services-layout7 .section-bg {
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
   z-index: -1;
   height: 270px;
 }

 .services-layout7 .service-item {
   border-top: 6px solid var(--color-primary);
   box-shadow: 0px 5px 83px 0px rgba(13, 14, 67, 0.08);
 }

 .services-layout7 .service-item .service-desc {
   margin-bottom: 0;
 }

 .services-layout7 .service-item .service-img {
   padding: 50px;
 }

 .services-layout7 .service-item .btn {
   opacity: 0;
   transition: all 0.3s ease-in-out 0s;
   transform: translateY(100%);
 }

 .services-layout7 .service-item .service-overlay {
   transform: translateY(100%);
   transition: all 0.3s ease-in-out 0s;
 }

 .services-layout7 .service-item .service-overlay:before,
 .services-layout7 .service-item .service-overlay:after {
   content: "";
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   left: 0;
   transition: all 0.3s ease;
 }

 .services-layout7 .service-item .service-overlay:before {
   background-color: rgb(1, 34, 23);
   opacity: 0.2;
 }

 .services-layout7 .service-item .service-overlay:after {
   --cms-secondary-darken2-rgb: 1, 34, 23;
   background: linear-gradient(to bottom, rgba(var(--cms-secondary-darken2-rgb), 0) 0%, rgba(var(--cms-secondary-darken2-rgb), 0) 5%, rgba(var(--cms-secondary-darken2-rgb), 1) 98%, rgba(var(--cms-secondary-darken2-rgb), 1) 100%);
 }

 .services-layout7 .service-item:hover .btn {
   opacity: 1;
   transform: translateY(0);
   transition: all 0.3s ease-in-out 0.8s;
 }

 .services-layout7 .service-item:hover .service-overlay {
   transform: translateY(0);
   transition: all 0.3s ease-in-out 0.3s;
 }

 .services-layout7 .service-item:hover .service-title a,
 .services-layout7 .service-item:hover .service-desc,
 .services-layout7 .service-item:hover .service-icon {
   color: inherit;
 }

 .services-layout8 {
   margin-top: -130px;
 }

 .services-layout8 .service-item {
   z-index: 2;
   border-radius: 4px;
   overflow: visible;
   margin-bottom: 30px;
   padding: 40px 30px 30px;
   padding-inline-start: 0 !important;
   background-color: var(--color-white);
   box-shadow: 0px 5px 83px 0px rgba(1, 34, 23, 0.12);
 }

 .services-layout8 .service-item .service-img {
   flex: 0 0 160px;
   margin-inline-start: -10px;
   padding-inline-end: 30px;
 }

 .services-layout8 .service-item .service-img img {
   border-radius: 4px;
 }

 .services-layout8 .service-item .service-body {
   padding: 0;
   flex: 0 0 calc(100% - 160px);
 }

 .services-layout8 .service-item .service-desc {
   font-size: 14px;
   line-height: 1.6;
   margin-bottom: 6px;
   -webkit-line-clamp: 3;
   line-clamp: 3;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: normal;
 }

 .services-layout8 .service-item .btn-link.btn-primary {
   border-bottom: 2px solid transparent;
 }

 .services-layout8 .service-item .btn-link.btn-primary:hover {
   color: var(--color-primary) !important;
   border-bottom-color: var(--color-primary);
 }

 .services-layout8 .service-item:hover .service-img {
   animation: bounce 1s linear infinite;
 }

 .services-layout9 .heading,
 .services-layout9 .carousel-container {
   position: relative;
   z-index: 2;
 }

 .services-layout9 .section-secondary-bg {
   position: absolute;
   top: 0;
   right: 0;
   bottom: 0;
   width: 45%;
   max-width: 530px;
 }

 .services-clients-wrap .services-layout2 .service-item {
   margin-bottom: -285px !important;
 }

 .services-clients-wrap .clients {
   padding-top: 320px;
 }

 .services-carousel .slick-list {
   margin: -20px;
   overflow: visible;
 }

 .services-carousel .slick-slide {
   margin: 20px;
 }

 .services-carousel .slick-slide:not(.slick-active) {
   opacity: 0;
   visibility: hidden;
 }

 .services-carousel .slick-arrow {
   top: -47px;
 }

 .services-carousel .slick-arrow.slick-prev {
   left: auto;
   right: 45px;
 }

 .services-carousel .slick-arrow.slick-next {
   right: 0;
 }

 .services-carousel .slick-slide {
   transition: opacity 0.3s ease, border 0.3s ease, box-shadow 0.4s ease, box-shadow 0.4s ease;
 }

 .services-carousel .service-item .service-desc {
   display: -webkit-box;
   -webkit-line-clamp: 4;
   line-clamp: 4;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
   white-space: normal;
 }

 .list-filter li {
   margin-right: 30px;
 }

 .list-filter li:last-child {
   margin-right: 0;
 }

 .list-filter .filter {
   position: relative;
   display: block;
   font-size: 15px;
   font-weight: 700;
   color: var(--color-secondary);
   padding-bottom: 3px;
 }

 .list-filter .filter:before {
   content: "";
   position: absolute;
   bottom: 0;
   left: 0;
   width: 0;
   height: 2px;
   background-color: var(--color-primary);
   transition: 0.4s ease;
 }

 .list-filter .filter.active,
 .list-filter .filter:hover {
   color: var(--color-primary);
 }

 .list-filter .filter.active:before,
 .list-filter .filter:hover:before {
   width: 100%;
 }

 .mix {
   display: none;
 }

 @media (min-width: 992px) {
   .services-layout9.services-carousel-wide .carousel-container {
     margin-inline-end: -230px !important;
   }

   .services-layout9 .service-item .service-body {
     padding: 0 20px 20px;
   }

   .services-layout9 .heading {
     max-width: 330px;
   }

   .services-layout9 .slick-dots {
     text-align: start;
   }
 }

 @media (min-width: 1200px) {
   .services-carousel-wide .carousel-container {
     margin-inline-end: -140px;
   }

   .services-layout1 .service-item .service-body {
     padding: 0 38px 35px 50px;
   }

   .services-layout1 .service-item .service-desc {
     -webkit-line-clamp: 5;
     line-clamp: 5;
   }

   .services-layout2 .service-item .service-body,
   .services-layout9 .service-item .service-body {
     padding: 0 40px 40px;
   }

   .services-layout3 {
     margin: 0 20px;
   }

   .services-layout4 .service-item .service-body,
   .services-layout5 .service-item .service-body,
   .services-layout6 .service-item .service-body,
   .services-layout7 .service-item .service-body {
     padding: 50px;
   }

   .services-layout5 .slick-dots {
     position: absolute;
     right: 0;
     bottom: -60px;
     margin-top: 20px;
   }

   .services-layout6 .slick-dots {
     position: absolute;
     bottom: -60px;
     right: calc((100% - 1320px) / 2 + 145px) !important;
   }

   .services-layout9.services-carousel-wide .carousel-container {
     margin-inline-end: -280px !important;
   }
 }

 @media (min-width: 1400px) {
   .services-layout6 .slick-dots {
     right: calc((100% - 1320px) / 2 + 110px) !important;
   }
 }

 @media (min-width: 1500px) {
   .services-carousel-wide .carousel-container {
     margin-inline-end: -400px;
   }

   .services-layout3 {
     margin: 0 60px;
   }

   .services-layout6 .slick-dots {
     right: calc((100% - 1320px) / 2 + 205px) !important;
   }
 }

 @media (max-width: 1199px) {
   .services-layout1 .service-item .service-body {
     padding: 0 20px 20px 20px;
   }

   .services-layout1 .service-item .divider {
     margin: 15px 0;
   }

   .services-layout2 .service-item .service-body,
   .services-layout9 .service-item .service-body {
     padding: 0 20px 20px;
   }

   .services-layout4 .service-item .service-body,
   .services-layout5 .service-item .service-body,
   .services-layout6 .service-item .service-body,
   .services-layout7 .service-item .service-body {
     padding: 30px;
   }
 }

 /* Mobile Phones & tablets and Small Screens */
 @media (max-width: 991px) {
   .service-item .service-title {
     font-size: 18px;
     margin-bottom: 8px;
   }

   .service-item .service-desc {
     font-size: 14px;
   }

   .service-item .service-category {
     margin-bottom: 15px;
   }

   .services-carousel .slick-arrow {
     top: -26px;
   }

   .list-filter li {
     margin-right: 15px;
   }

   .services-layout3 .service-item .service-item-inner {
     transform: translateY(-88px);
   }

   .services-layout3 .service-item .service-body {
     padding: 20px;
   }

   .services-layout3 .service-item .btn {
     margin-top: 16px;
   }

   .services-layout4 .service-item .service-icon,
   .services-layout5 .service-item .service-icon,
   .services-layout6 .service-item .service-icon,
   .services-layout7 .service-item .service-icon {
     margin-bottom: 15px;
   }

   .services-layout8 .service-item {
     padding: 15px;
     margin: 15px;
   }

   .services-layout8 .service-item .service-img {
     flex: 0 0 140px;
     padding-inline-end: 20px;
   }

   .services-layout8 .service-item .service-body {
     flex: 0 0 calc(100% - 140px);
   }
 }

 @media (min-width: 991px) {

   .service-item .service-title,
   .service-item .service-desc {
     display: -webkit-box;
     -webkit-box-orient: vertical;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: normal;
   }

   .service-item .service-title {
     -webkit-line-clamp: 2;
     line-clamp: 2;
   }

   .service-item .service-desc {
     -webkit-line-clamp: 4;
     line-clamp: 4;
   }
 }

 @media (min-width: 320px) and (max-width: 767px) {
   .services-layout1 .service-item {
     flex-direction: column;
   }

   .services-layout1 .service-item .service-img,
   .services-layout1 .service-item .service-body {
     flex: 0 0 100%;
     width: 100%;
   }

   .services-layout1 .service-item .service-img {
     min-height: 250px;
   }

   .services-layout1 .service-item .service-body {
     padding: 20px;
   }

   .services-carousel .slick-arrow {
     top: 20px;
   }

   .services-carousel .slick-slider {
     padding-top: 50px;
   }

   .services-layout1.services-carousel .slick-slider,
   .services-layout5.services-carousel .slick-slider,
   .services-layout6.services-carousel .slick-slider {
     padding-top: 0;
   }
 }

 @media (min-width: 768px) and (max-width: 991px) {
   .services-layout1 .service-item .service-img {
     flex: 0 0 45%;
     width: 45%;
   }

   .services-layout1 .service-item .service-body {
     flex: 0 0 55%;
     width: 55%;
   }
 }

 /*-------------------------
     gallery
  -------------------------*/
 .gallery-img {
   position: relative;
   overflow: hidden;
   border-radius: 2px;
   margin-bottom: 40px;
 }

 .gallery-img:before {
   content: "";
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   opacity: 0;
   background-color: rgba(var(--color-primary-rgb), 0.85);
   transform: scale(0);
   transition: all 0.4s ease-in-out;
 }

 .gallery-img img {
   width: 100%;
   border-radius: 2px;
 }

 .gallery-img .popup-gallery-item {
   display: flex;
   align-items: center;
   justify-content: center;
   position: absolute;
   top: 50%;
   left: 50%;
   z-index: 2;
   opacity: 0;
   width: 68px;
   height: 68px;
   font-size: 10px;
   border-radius: 2px;
   color: var(--color-primary);
   background-color: var(--color-white);
   transition: all 0.5s ease;
   transform: translate(-50%, -50%);
 }

 .gallery-img:hover:before {
   opacity: 1;
   transform: scale(1);
 }

 .gallery-img:hover .popup-gallery-item {
   opacity: 1;
 }

 .gallery-hidden .gallery-img {
   display: none;
 }

 /*------------------------
      Shop
  --------------------------*/
 .product-item {
   position: relative;
   border-radius: 4px;
   overflow: hidden;
   margin-bottom: 40px;
   padding: 100px 40px 40px;
   background-color: var(--color-gray-700);
   transition: all 0.3s linear;
 }

 .product-item .product-overlay {
   position: absolute;
   top: 0;
   bottom: 0;
   right: 0;
   left: 0;
   z-index: 1;
   opacity: 0;
   visibility: hidden;
   background-color: rgba(248, 248, 242, 0.85);
   transform: scale(0.8);
   transition: all 0.3s linear;
 }

 .product-item .product-action {
   position: absolute;
   left: 40px;
   right: 40px;
   bottom: 0;
   opacity: 0;
   z-index: 2;
   transition: all 0.3s ease;
 }

 .product-item .product-info {
   padding-top: 40px;
   transition: all 0.3s ease;
 }

 .product-item .product-action .btn {
   overflow: hidden;
   padding: 0 0 0 50px;
 }

 .product-item .product-action .btn .custom-icon {
   margin: 0;
   width: 68px;
   height: 68px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: rgba(0, 0, 0, 0.1);
 }

 .product-item .badge {
   position: absolute;
   top: 10px;
   right: 10px;
   border-radius: 0;
 }

 .product-item .badge.sale {
   background-color: #ff0000;
 }

 .product-item:hover .product-overlay {
   opacity: 1;
   visibility: visible;
   transform: scale(1);
 }

 .product-item:hover .product-action {
   opacity: 1;
   bottom: 40px;
 }

 .product-item:hover .product-info {
   opacity: 0;
 }

 .product-title {
   font-size: 20px;
   margin-bottom: 8px;
 }

 .product-title a {
   color: var(--color-primary-200);
 }

 .product-title a:hover {
   color: var(--color-primary);
 }

 .product-price {
   display: inline-block;
   font-size: 20px;
   font-weight: 700;
   color: var(--color-secondary);
 }

 .product-img {
   text-align: center;
 }

 .product-img img {
   max-height: 250px;
   margin: auto;
 }

 /* Shop Single */
 .product-item-single .product-img {
   padding: 50px;
   background-color: var(--color-gray-700);
 }

 .product-item-single .product-img img {
   max-height: 500px;
 }

 .product-item-single .product-title {
   font-size: 29px;
   margin-bottom: 15px;
 }

 .product-item-single .product-rating {
   margin-right: 37px;
 }

 .product-item-single .product-rating i {
   font-size: 15px;
 }

 .product-item-single .product-rating i.active {
   color: var(--color-secondary);
 }

 .product-item-single .product-img img {
   border-radius: 15px;
 }

 .product-item-single .product-price {
   font-size: 22px;
 }

 .product-item-single .product-desc {
   font-size: 15px;
 }

 .product-item-single .social-icons li a {
   font-size: 16px;
   width: 40px;
   height: 40px;
   line-height: 40px;
 }

 .product-details li {
   line-height: 2;
 }

 .product-details li strong {
   font-size: 16px;
   font-weight: 700;
   color: var(--color-accent);
   min-width: 120px;
   display: inline-block;
 }

 .product-meta-review {
   font-size: 14px;
 }

 .product-meta-review a {
   position: relative;
   color: var(--bs-gray);
   padding-left: 10px;
 }

 .product-meta-review a:hover {
   color: var(--color-primary);
 }

 .product-meta-review a:before {
   content: "/";
   position: absolute;
   top: 0;
   left: 0;
 }

 .product-meta-details li {
   display: flex;
   font-size: 15px;
   margin-bottom: 13px;
 }

 .product-meta-details li:last-child {
   margin-bottom: 0;
 }

 .product-meta-details li span:first-of-type {
   font-family: var(--font-primary);
   color: var(--color-primary-700);
   font-weight: 700;
   font-size: 16px;
   min-width: 100px;
 }

 .product-meta-details li a {
   color: var(--bs-gray);
 }

 .product-meta-details li a:hover {
   color: var(--color-primary);
 }

 .related-products-title {
   font-size: 24px;
 }

 .product-quantity-title {
   margin-bottom: 0;
   display: inline-block;
   margin-right: 10px;
 }

 .quantity-input-wrap {
   display: inline-flex;
   position: relative;
   width: 80px;
   max-height: 50px;
   border-radius: 3px;
   border: 2px solid #e6e8eb;
 }

 .quantity-input-wrap i {
   width: 25px;
   height: 25px;
   line-height: 25px;
   text-align: center;
   color: var(--color-accent);
   cursor: pointer;
   position: absolute;
   right: 0;
   font-style: normal;
   font-size: 9px;
   border-left: 2px solid #e6e8eb;
   -webkit-user-select: none;
   -moz-user-select: none;
   -o-user-select: none;
   user-select: none;
 }

 .quantity-input-wrap .decrease-qty {
   bottom: 0;
   border-top: 2px solid #e6e8eb;
 }

 .quantity-input-wrap .qty-input {
   background-color: transparent;
   text-align: center;
   color: var(--color-accent);
   font-weight: 600;
   font-size: 16px;
   padding: 0;
   margin: 0;
   width: 50px;
   height: 50px;
   border: 0;
   outline: 0;
   -webkit-appearance: textfield;
   -moz-appearance: textfield;
   appearance: textfield;
 }

 .quantity-input-wrap .qty-input::-webkit-inner-spin-button,
 .quantity-input-wrap .qty-input::-webkit-outer-spin-button {
   -webkit-appearance: none;
   -moz-appearance: none;
   -o-appearance: none;
   appearance: none;
 }

 .product-quantity .btn {
   height: 50px;
   min-width: 140px;
   background-color: var(--color-primary-200);
 }

 .product-details .nav-tabs {
   border-bottom: 2px solid var(--color-gray-600);
 }

 .product-details .nav-tabs .nav-link {
   margin-bottom: -2px;
 }

 .product-details .tab-content {
   padding-top: 20px;
 }

 .product-details .tab-content p {
   font-size: 15px;
 }

 .product-details .tab-content p:last-child {
   margin-bottom: 0;
 }

 .product-details .reviews-form .form-control {
   height: 50px;
 }

 .magnifier {
   z-index: 3000 !important;
 }

 .sorting-options .nice-select {
   width: auto;
   min-width: 230px;
   height: 50px;
   line-height: 50px;
   margin-bottom: 0;
 }

 /* Shopping Cart */
 .cart-table {
   margin-bottom: 50px;
 }

 .cart-table .table {
   border-radius: 4px;
   margin-bottom: 0;
 }

 .cart-table thead {
   background-color: transparent;
 }

 .cart-table thead tr th {
   font-size: 15px;
   font-weight: 700;
   text-align: center;
   color: var(--color-accent);
   border-bottom-width: 0 !important;
   border-color: #e9e9e9;
   padding: 15px 0;
 }

 .cart-table tbody tr td {
   padding: 15px;
   border-color: #e9e9e9;
   vertical-align: middle;
 }

 .cart-table .cart-product {
   position: relative;
 }

 .cart-table .cart-product-price,
 .cart-table .cart-product-total {
   font-size: 16px;
   font-weight: 700;
   color: var(--color-secondary);
   text-align: center;
 }

 .cart-table .cart-product-quantity {
   text-align: center;
 }

 .cart-table .cart-product-img {
   width: 50px;
   margin-right: 20px;
 }

 .cart-table .cart-product-title {
   font-size: 16px;
   margin-bottom: 0;
 }

 .cart-table .cart-product-remove {
   display: flex;
   align-items: center;
   justify-content: center;
   width: 19px;
   height: 19px;
   font-size: 9px;
   border-radius: 50%;
   text-align: center;
   cursor: pointer;
   margin-right: 20px;
   color: var(--color-white);
   background-color: var(--color-primary);
 }

 .cart-table .cart-product-remove:hover {
   background-color: var(--color-secondary);
 }

 .cart-table .cart-product-action td {
   padding: 30px 20px 20px;
 }

 .cart-table .cart-product-action td .form-control {
   font-size: 12px;
   font-weight: 400;
   width: 250px;
   height: 42px;
   border-radius: 4px;
   border: 1px solid #dbdbdb;
 }

 .cart-table .cart-product-action td .btn {
   height: 42px;
   min-width: 134px;
   -webkit-clip-path: none;
   clip-path: none;
   margin-left: 10px;
 }

 .cart-total-amount ul li {
   font-size: 17px;
   margin-bottom: 12px;
   padding-bottom: 12px;
   display: flex;
   justify-content: space-between;
   border-bottom: 1px solid #e9e9e9;
 }

 .cart-total-amount ul li span:first-of-type {
   font-weight: 700;
   color: var(--color-accent);
 }

 /* Mobile Phones */
 @media (min-width: 320px) and (max-width: 575px) {
   .cart-table tbody tr td {
     padding: 5px;
   }

   .cart-table .cart-product-img {
     width: 40px;
     margin-right: 5px;
   }

   .cart-table .cart-product-title {
     font-size: 12px;
     font-weight: 400;
   }

   .cart-table .cart-product-price,
   .cart-table .cart-product-total {
     font-size: 10px;
   }

   .cart-table .cart-product-action-content {
     display: block !important;
   }

   .cart-table .cart-product-price,
   .cart-table .cart-product-total {
     font-size: 10px;
     font-weight: 400;
     padding: 0;
   }

   .cart-table thead tr th {
     font-size: 10px;
   }
 }

 @media (min-width: 320px) and (max-width: 767px) {
   .product-item {
     margin-bottom: 20px;
     padding: 50px 20px 20px;
   }

   .product-item .product-title,
   .product-item .product-price {
     font-size: 16px;
   }

   .product-item .product-action .btn .custom-icon {
     width: 55px;
     height: 55px;
   }
 }

 /*--------------------------
        Maps
  --------------------------*/
 .map-card {
   position: absolute;
 }

 .map-card:first-of-type {
   top: 59%;
   left: 79.5%;

 }

 .map-card:nth-of-type(2) {
   top: 31%;
   left: 16%;
 }

 .map-card:nth-of-type(3) {
   top: 72%;
   left: 30%;
 }

 .map-card:nth-of-type(4) {
   top: 13%;
   left: 77%;
 }

 .map-card.show .map-card-inner {
   opacity: 1;
   visibility: visible;
 }

 .map-card:hover .map-pointer,
 .map-card.show .map-pointer {
   background-color: rgba(228, 164, 0, 0.8);
   box-shadow: 0 0 0 29px rgba(228, 164, 0, 0.8);
 }

 .map-card:hover .map-pointer:before,
 .map-card.show .map-pointer:before {
   background-color: var(--color-white);
 }

 .map-card:hover .pointer-arrow:after,
 .map-card.show .pointer-arrow:after {
   opacity: 1;
 }

 .map-pointer {
   position: relative;
   cursor: pointer;
   width: 36px;
   height: 36px;
   border-radius: 50%;
   background-color: rgba(228, 164, 0, 0.3);
   transition: all 0.3s ease-in-out;
 }

 .map-pointer:hover+.map-card-inner {
   opacity: 1;
   visibility: visible;
 }

 .map-pointer:before,
 .map-pointer:after {
   content: "";
   position: absolute;
   border-radius: 50%;
   transition: all 0.3s ease-in-out;
 }

 .map-pointer:before {
   width: 22px;
   height: 22px;
   top: 7px;
   left: 7px;
   background-color: rgba(228, 164, 0, 0.4);
 }

 .map-pointer:after {
   width: 8px;
   height: 8px;
   top: 14px;
   left: 14px;
   z-index: 1;
   background-color: var(--color-secondary);
 }

 .map-pointer:hover:before {
   background-color: var(--color-white);
 }

 .map-pointer .pointer-arrow:after {
   content: "";
   position: absolute;
   top: 33px;
   left: 18px;
   opacity: 0;
   border-right: 10px solid transparent;
   border-bottom: 10px solid var(--color-white);
   transition: all 0.3s ease-in-out;
 }

 .map-card-inner {
   --card-width: 400px;
   position: absolute;
   top: 43px;
   left: -145px;
   z-index: 5;
   opacity: 0;
   visibility: hidden;
   border-radius: 4px;
   width: var(--card-width);
   background-color: var(--color-white);
   box-shadow: 0px 5px 83px 0px rgba(1, 34, 23, 0.12);
   transition: all 0.3s ease-in-out;
 }

 .map-card-inner .map-card-img {
   flex: 0 0 130px;
 }

 .map-card-inner .map-card-img img {
   border-radius: 4px 0 0 4px;
 }

 .map-card-inner .map-card-body {
   padding: 26px 30px 28px;
   flex: 1;
 }

 .map-card-inner .map-card-title {
   font-size: 19px;
   margin-bottom: 3px;
 }

 .map-card-inner .map-card-desc {
   margin-bottom: 5px;
 }

 .map-card-inner .btn-primary {
   color: var(--color-primary);
 }

 .map-card-inner .btn-primary:hover {
   color: var(--color-secondary);
 }

 .banner-map-layout2 .map-pointer {
   background-color: rgba(26, 126, 0, 0.3);
 }

 .banner-map-layout2 .map-pointer:before {
   background-color: rgba(26, 126, 0, 0.4);
 }

 .banner-map-layout2 .map-pointer:after {
   background-color: var(--color-primary);
 }

 .banner-map-layout2 .map-card:hover .map-pointer,
 .banner-map-layout2 .map-card.show .map-pointer {
   background-color: rgba(26, 126, 0, 0.8);
   box-shadow: 0 0 0 29px rgba(26, 126, 0, 0.8);
 }

 .banner-map-layout2 .col-inner {
   max-width: 670px;
 }

 @media (max-width: 767px) {
   .map-card:nth-of-type(4) .map-card-inner {
     left: auto;
     right: -50px;
   }

   .map-card .map-card-inner {
     --card-width: 270px;
     left: -60px;
   }

   .map-card .map-card-inner .map-card-img {
     display: none;
   }

   .map-card .map-card-inner .map-card-body {
     padding: 12px 15px;
   }

   .map-card .map-card-inner .map-card-title {
     font-size: 16px;
   }

   .map-card .map-card-inner .map-card-desc,
   .map-card .map-card-inner .btn-link {
     font-size: 14px;
   }
 }

 /*-------------------------
      Work Process
  -------------------------*/
 .process-item {
   position: relative;
   transition: all 0.3s ease;
 }

 .work-process-layout1 .process-item {
   background-color: var(--color-white);
 }

 .work-process-layout1 .process-item .process-number {
   font-size: 55px;
   line-height: 1;
   margin-bottom: 0;
   min-width: 85px;
   font-family: var(--font-primary);
   color: var(--color-primary-700);
   transition: all 0.3s ease;
 }

 .work-process-layout1 .process-item .process-icon {
   position: absolute;
   top: 10px;
   right: 0;
   font-size: 120px;
   line-height: 1;
   color: rgba(60, 145, 38, 0.1);
 }

 .work-process-layout1 .process-item .process-title {
   font-size: 19px;
 }

 .work-process-layout1 .process-item .process-desc {
   position: relative;
   z-index: 2;
   margin-bottom: 20px;
 }

 .work-process-layout1 .process-item:hover .process-number {
   animation: bounce 1s linear infinite;
 }

 .work-process-layout2 .process-tabs .nav-link {
   --bs-nav-link-padding-x: 45px;
   --bs-nav-link-padding-y: 20px;
   --bs-nav-link-font-weight: 700;
   --bs-nav-link-font-: 16px;
   position: relative;
   margin: 0;
   color: var(--color-white);
   border-bottom: 1px solid #539e40;
   counter-increment: num;
 }

 .work-process-layout2 .process-tabs .nav-link:before {
   content: counters(num, "");
   position: absolute;
   top: 20px;
   left: 0;
   width: 26px;
   height: 26px;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 50%;
   font-size: 14px;
   font-weight: 700;
   color: var(--color-primary-200);
   background-color: var(--color-white);
   transition: all 0.3s ease;
 }

 .work-process-layout2 .process-tabs .nav-link:hover:before,
 .work-process-layout2 .process-tabs .nav-link.active:before {
   box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.3);
 }

 .work-process-layout2 .process-tabs .nav-link:last-of-type .process-title {
   border-bottom: 0;
 }

 .work-process-layout2 .process-item {
   z-index: 2;
   margin: 30px 0;
   max-width: 440px;
 }

 .work-process-layout2 .process-title {
   font-size: 20px;
   color: var(--color-white);
 }

 .work-process-layout2 .process-desc {
   color: var(--color-gray-100);
   max-width: 330px;
 }

 .work-process-layout2 .process-icon {
   position: absolute;
   bottom: -50px;
   right: 10px;
   font-size: 140px;
   line-height: 1;
   color: #3c9126;
   z-index: -1;
 }

 @media (min-width: 992px) {
   .work-process-layout1 .work-process-banner-wrap {
     top: 70px;
     min-height: 100vh;
   }

   .work-process-layout1 .work-process-banner {
     position: absolute;
     top: 50%;
     transform: translateY(-50%);
     opacity: 0;
     visibility: hidden;
     transition: all 0.3s ease-in-out;
   }

   .work-process-layout1 .work-process-banner.active {
     opacity: 1;
     visibility: visible;
   }

   .work-process-layout1 .work-process-banner .cta-banner {
     position: absolute;
     top: 40px;
     left: 40px;
     width: 280px;
     padding: 40px 37px 40px 40px;
   }

   .work-process-layout1 .work-process-banner .cta-banner:after {
     content: "";
     position: absolute;
     left: 0;
     bottom: -14px;
     border-right: 15px solid transparent;
     border-top: 17px solid var(--color-white);
     transition: all 0.3s ease;
   }

   .work-process-layout1 .work-process-banner .cta-banner .cta-title {
     line-height: 1.5;
   }

   .work-process-layout1 .work-process-banner .cta-banner .cta-icon {
     margin-bottom: 18px;
   }

   .work-process-layout1 .work-process-banner:hover .cta-banner {
     background-color: var(--color-primary);
   }

   .work-process-layout1 .work-process-banner:hover .cta-banner:after {
     border-color: var(--color-primary) transparent transparent transparent;
   }

   .work-process-layout1 .work-process-banner:hover .cta-banner .cta-icon,
   .work-process-layout1 .work-process-banner:hover .cta-banner .cta-title {
     color: var(--color-white);
   }

   .work-process-layout1 .work-process-text {
     height: 100vh;
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     padding-left: 30px;
   }

   .work-process-layout1 .work-process-text .heading {
     padding-left: 100px;
   }

   .work-process-layout1 .process-item {
     padding-right: 90px;
   }
 }

 @media (max-width: 991px) {
   .work-process-layout1 .process-item {
     margin-top: 50px;
   }

   .work-process-layout2 .highlights-banner {
     padding: 20px 30px;
     margin-bottom: 0;
   }
 }

 /*-----------------------
      Progress Bars
  ------------------------*/
 .progress-item {
   margin-bottom: 37px;
   overflow: hidden;
 }

 .progress {
   background-color: var(--color-gray-600);
   border-radius: 2px;
   width: 100%;
   height: 4px;
   overflow: visible;
   position: relative;
 }

 .progress-bar {
   overflow: visible;
   position: relative;
   border-radius: 2px;
   height: 4px;
   width: 0;
   background-color: var(--color-primary-500);
   transition: width 2s ease;
 }

 .progress-title {
   font-family: var(--font-secondary);
   position: relative;
   z-index: 2;
   font-size: 15px;
   font-weight: 700;
   line-height: 1;
   color: var(--color-primary-700);
   margin-bottom: 18px;
 }

 .progress-percentage {
   font-size: 14px;
   font-weight: 700;
   line-height: 1;
   position: absolute;
   top: -31px;
   right: 0;
   color: var(--color-primary-600);
 }

 /*# sourceMappingURL=style.css.map */