@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
    --black-button: #231F20;
    --offwhite: #FFFFFF;
    --button-padding: 12px 43px;
}

.section1-container-navigate {
    padding-top: 25px;
    height: 100%;
    width: 100%;

    .navbar {
        border-bottom: 1px solid #E5E9EB;
    }


    .nav-link-bars {
        margin-top: 14px;
        font-size: 15px;
        margin-right: 15px;
    }

    .nav-link-bar:hover {
        background-color: #E7E7E7;
        border-radius: 8px;
    }

    .login-button {
        display: inline-flex;
        padding: 11px 30px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        background-color: var(--black-button);
        color: var(--white);
    }


    .nav-link-grey {
        padding: 0.5rem 1rem;

    }

    .nav-link-grey:hover {
        background-color: #f1eeee;
        border-radius: 8px;
    }

}

.logo {
    height: 60px;
}

.header-design-elements1 {
    position: absolute;
    padding-left: 40%;
    padding-top: 95px;

    .design-element1 {
        transform: rotate(27.908deg);
    }
}

.header-design-elements2 {
    position: absolute;
    padding-top: 25%;

    .design-element2 {
        transform: rotate(-9.294deg);
    }
}

.header-design-elements3 {
    float: right;
    padding-top: 45%;
    margin-right: 5px;

    .design-element3 {
        transform: rotate(-19deg);
    }
}



@media only screen and (max-width: 800px) {
    .header-design-elements1 {
        padding-left: 55%;
    }

    .header-design-elements2 {
        padding-top: 50%;
    }
}

@media only screen and (max-width: 577px) {
    .header-design-elements3 {
        padding-top: 70%;
    }

    .header-design-elements2 {
        display: none;
    }
}


// home

.container-main {
    margin: 0;
}

.banner-container {
    display: flex;
    justify-content: center;
    padding-top: 40px;

    .cover-image-container {
        position: relative;
        max-width: 100%;
        margin-top: 20px;
        overflow: hidden;

        .cover-image {
            width: 100%;
            height: auto;
            display: block;
        }

        .info-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 86, 179, 0.8);
            color: white;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            clip-path: polygon(0 0, 100% 0, 80% 100%, 0% 100%);
            width: 55%;
            border-radius: 20px 0 0 20px;

            .main-heading {
                margin-left: 55px;
                margin-top: 30px;
                // padding-left: 35px;
                font-size: 3.2vw;
            }

            .main-content {
                margin-left: 55px;
                // padding-left: 35px;
                font-weight: 300;
                width: 60%;
                font-size: 1.2vw;




                .content-divider {
                    margin-top: 20px;
                    border: none;
                    border-top: 2px solid #FFFFFF;
                    width: 100%;
                }

                .icon-arrow {
                    width: 35px;
                    height: auto;

                    @media (max-width: 768px) {
                        display: none;
                    }

 
                }
            }
        }

        .line-with-arrow {
            display: flex;
            align-items: center;
            gap: 10px;


            .line {
                border: 1px solid white;
                margin-left: 65px;
                margin-top: 30px;
                opacity: 0.7;
            }

            .arrow-link {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 35px;
                height: 35px;
                background-color: #ffff;
                color: #0056b3;
                text-decoration: none;
                font-size: 1.4rem;
                border-radius: 50%;
            }
        }

    }

    @media only screen and (max-width: 1400px) {
        .cover-image-container {
            max-width: 76% !important;
            margin: 0 25px;
            border-radius: 20px;
        }
    }

    @media only screen and (max-width: 1356px) {
        .cover-image-container {
            max-width: 81% !important;
            margin: 0 25px;
            border-radius: 20px;
        }

        .info-overlay {
            clip-path: polygon(0 0, 100% 0, 89% 89%, 0% 89%);

            .main-content {
                margin-left: 5px;
                padding-left: 5px;
                width: 70%;
                font-size: 1vw;
            }
        }
    }   
     @media only screen and (max-width: 913px) {
        .cover-image-container {
            max-width: 72% !important;
            margin: 0 25px;
            border-radius: 20px;
        }
    }
    @media only screen and (max-width: 902px) {
        .cover-image-container {
            max-width: 85% !important;
            margin: 0 25px;
            border-radius: 20px;
        }
    }

    @media only screen and (max-width: 769px) {
        .cover-image-container {
            max-width: 85% !important;
            margin: 0 25px;
            border-radius: 20px;
        }

        .info-overlay {
            clip-path: polygon(0 0, 100% 0, 89% 89%, 0% 89%);
            margin: 0;

            .main-content {
                margin-left: 15px !important;
                // padding-left: 5px;
                width: 85%;
                font-size: 1vw;
            }
           
        }
    }

    @media only screen and (max-width: 450px) {
        .cover-image-container {
            max-width: 86% !important;
            margin: 0 25px;
            border-radius: 20px;
        }

        .arrow-link {
            font-size: 1.2rem;
        }

        .info-overlay {
            clip-path: polygon(0 0, 100% 0, 90% 90%, 0% 90%);
            margin: 0;

            .main-heading {
                margin-left: 15px !important;
                padding-left: 3px;
                width: 100%;
                font-size: 1vw
            }

            .main-content {
                margin-left: 0px;
                padding: 5px;
                width: 100%;
                font-size: vw;
            }
        }
    }

    @media only screen and (max-width: 391px) {
        .cover-image-container {
            max-width: 89% !important;
            margin: 0 25px;
            border-radius: 20px;
        }

        .arrow-link {
            font-size: 1.2rem;
        }

        .info-overlay {
            clip-path: polygon(0 0, 100% 0, 90% 90%, 0% 90%);
            margin: 0;

            .main-heading {
                margin-left: 18px !important;
                padding-left: 3px;
                padding-top: 10px !important;
                width: 100%;
                font-size: 1vw
            }

            .main-content {
                margin-left: 0px;
                padding: 5px;
                width: 100%;
                font-size: vw;
            }
        }
    }

    @media only screen and (max-width: 376px) {
        .cover-image-container {
            max-width: 85% !important;
            margin: 0 25px;
            border-radius: 20px;
        }
    }
}

.buttons-row {
    margin-top: 1rem;

    .btn-custom {
        background-color: white;
        color: #534686;
        border-radius: 30px;
        padding: 10px 35px;
        transition: all 0.3s ease;
        font-weight: 400;
        font-size: 16px;

        &:hover {
            background-color: #534686;
            color: white;
        }
    }
}


.custom-card {
    background-color: white;
    padding: 20px;
    border-radius: 26px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}


// activeplan
.activeplan-container {
    padding: 0;

    .profile-field-card {
        .active-plan {
            padding: 1rem;

            .table-containers {
                margin-left: 10px;
                margin-top: 0;
                margin-right: 0;

                @media (max-width: 768px) {
                    margin-left: 30px;
                    margin-top: 10px;
                    margin-right: 15px;

                }


                .expiry-and-table {
                    margin-left: 0;

                    .expiry-date,
                    .expired-date {
                        margin-bottom: 1rem;
                        color: green;
                        background-color: #EFF6FF;
                        padding: 4px 10px;
                        border-radius: 8px;
                        font-size: 14px;
                        display: inline-block;
                        margin-left: 24px;

                        @media (max-width: 768px) {
                            font-size: 12px;
                            margin-left: 10px;
                            padding: 3px 8px;
                        }
                    }

                    .expired-date {
                        color: #DF2935;
                    }

                    .table-activeplan {
                        margin-left: -45px;

                        .table-headers,
                        .table-content {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-wrap: wrap;
                        }

                        .table-headers {
                            font-weight: bold;
                            margin-bottom: 0.5rem;
                            font-size: 14px;

                            @media (max-width: 768px) {
                                font-size: 12px;
                                margin-left: 10px;
                            }
                        }

                        .table-content {
                            margin-bottom: 1rem;
                            margin-left: -4px;

                            @media (max-width: 768px) {
                                margin-left: 0;
                                padding-left: 10px;
                            }
                        }

                        .table-column {
                            flex: 1;
                            text-align: center;

                            @media (max-width: 768px) {
                                text-align: left;
                                font-size: 12px;
                                padding-left: 10px;
                            }
                        }

                        .view-button {
                            align-self: flex-end;
                            background: linear-gradient(90deg, #1EADFF, #068FFF);
                            border: none;
                            color: white;
                            padding: 5px 25px;
                            font-size: 14px;
                            border-radius: 5px;
                            transition: background 0.3s ease;
                            cursor: pointer;

                            &:hover {
                                background: linear-gradient(90deg, #068FFF, #1EADFF);
                            }

                            @media (max-width: 768px) {
                                padding: 4px 15px;
                                font-size: 12px;
                            }
                        }

                        .empty-space {
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .active-plan {
            padding: 1rem 10px;
        }

        .expiry-and-table {
            margin-left: 5px; //space for smaller screens
        }

        .table-headers {
            margin-left: 10px;
        }

        .table-content {
            margin-left: 10px;
        }

        .table-column {
            text-align: left;
            font-size: 12px;
            padding-left: 10px;
        }
    }
}

.pay-container {

.paycard-box{
    .profile-field-card{
        .active-plan{
            .subcription-item-bin,.subcription-item-dues{
                width: 140px;
            }
            .subcription-item-address{
                width: 600px;
            }
            .head{
                color: #6E7C87;
            }
            .address{
                padding-right: 80px;
            }
        }
    }
}
.sale-order-dues{
    font-weight: bold;
}

}
// pay-popup
.pay-container {
    padding-top: 70px;
    .card{
        box-shadow: none;
    }
    .paycard-box {

        .profile-field-card {
            // box-shadow: 0 4px 8px rgba(58, 53, 53, 0.1);
            border-radius: 15px;
            position: relative;

            .active-plan {
                padding: 1rem;
                position: relative;
                background-color: #fff;
                border-radius: 26px;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 5px;
                    height: 95%;
                    background-color: #DF2935;
                    border-radius: 30px;
                }

                .table-containers {
                    .expiry-and-table {
                        margin-left: 0;

                        .table-activeplan {
                            margin-left: -45px;

                            .table-headers,
                            .table-content {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                            }

                            .table-headers {
                                font-weight: bold;
                                margin-bottom: 0.5rem;
                            }

                            .table-content {
                                margin-bottom: 1rem;
                                margin-left: -4px;
                            }

                            .table-column {
                                flex: 1;
                                text-align: center;
                            }

                            .view-button {
                                align-self: flex-end;
                                background: linear-gradient(90deg, #10B981, #059666);
                                border: none;
                                color: white;
                                padding: 5px 10px;
                                font-size: 14px;
                                border-radius: 5px;
                                transition: linear-gradient(90deg, #10B981, #059666) 0.3s ease;

                                &:hover {
                                    background: linear-gradient(90deg, #10B981, #059666);
                                }
                            }

                            .empty-space {
                                margin-bottom: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .pay-all-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        .pay-all {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(90deg, #10B981, #059666);
            color: white;
            padding: 7px 20px;
            font-size: 16px;
            border-radius: 5px;
            border: none;
            cursor: pointer;
            transition: linear-gradient(90deg, #10B981, #059666) 0.3s ease;
            max-width: fit-content;
        }
    }

    @media (max-width: 700px) {
        .paycard-box {
            .profile-field-card {
                .active-plan {
                    padding: 0.8rem;

                    .table-containers {
                        .expiry-and-table {
                            margin-left: 0;

                            .table-activeplan {
                                margin-left: 0;

                                .table-headers {
                                    font-size: 12px;
                                }

                                .table-content {
                                    font-size: 12px;
                                }

                                .view-button {
                                    font-size: 12px; //fontsize for PayNow button
                                    padding: 4px 8px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .pay-all-container {
            .pay-all {
                font-size: 14px;
                padding: 6px 16px;
            }
        }
    }
}


.due-color {
    color: #DF2935
}

.unpaidinvoice-container {
    .tab-bar {
        margin-left: 40px !important;
    }

    .rks,
    .to,
    .invo {
        padding: 0;
    }

    .rks-col {
        padding-left: 35px;

    }

    .to-col {
        padding-left: 125px;
    }

    .invo {
        padding: 0 0 0 170px;
    }

    .row>.col {
        padding: 0;
    }

    .invo .invo div {
        margin-bottom: 4px;
        font-size: 0.9rem;
    }

    p {
        margin-bottom: 4px;
    }

    // .table,
    // .table td,
    // .table th {
    //     border: none !important;
    // }

    // .table tr {
    //     border-bottom: none !important;
    // }

    @media (max-width: 1200px) {

        // ...
        .tab-bar {
            margin-left: 10px;
        }

        .to-col {
            padding-left: 40px;
        }


        .rks,
        .to,
        .invo {
            padding: 0 !important;
        }

        .invo {
            padding: 0 0 0 50px !important;
        }

        .row>.col {
            padding: 0 !important;
        }
    }

    @media (max-width: 1000px) {

        .rks,
        .to,
        .invo {
            padding: 0 !important;
        }

        .invo {
            padding: 0 !important;
        }

        .row>.col {
            padding: 0 !important;
        }
    }

    @media (max-width: 565px) {

        // ...
        .tab-bar {
            margin-left: 0;
        }

        .to-col {
            padding-left: 0;
        }


        .rks,
        .to,
        .invo {
            padding: 0 !important;
        }

        .invo {
            padding: 0 !important;
        }

        .row>.col {
            padding: 0 !important;
        }
    }
}

.paidInvoice-container {

    .tab-bar {
        margin-left: 40px !important;
    }


    .rks,
    .to,
    .invo {
        padding: 0;
    }

    .rks-col {
        padding-left: 35px;

    }

    .to-col {
        padding-left: 125px;
    }

    .invo {
        padding: 0 0 0 170px;
    }

    .row>.col {
        padding: 0;
    }

    .invo .invo div {
        margin-bottom: 4px;
        font-size: 0.9rem;
    }

    p {
        margin-bottom: 4px;
    }

    // .table,
    // .table td,
    // .table th {
    //     border: none !important;
    // }

    // .table tr {
    //     border-bottom: none !important;
    // }


    @media (max-width: 1200px) {

        // ...
        .tab-bar {
            margin-left: 10px;
        }

        .rks-col {
            padding-left: 15px;

        }

        .to-col {
            padding-left: 40px;
        }


        .rks,
        .to,
        .invo {
            padding: 0 !important;
        }

        .invo {
            padding: 0 0 0 50px !important;
        }

        .row>.col {
            padding: 0 !important;
        }
    }


    @media (max-width: 1000px) {

        // ...
        .tab-bar {
            margin-left: 0;
        }

        .rks-col {
            padding-left: 5px;

        }

        .to-col {
            padding-left: 0;
        }


        .rks,
        .to,
        .invo {
            padding: 0 !important;
        }

        .invo {
            padding: 0 !important;
        }

        .row>.col {
            padding: 0 !important;
        }
    }

    @media (max-width: 565px) {

        // ...
        .tab-bar {
            margin-left: 0;
        }

        .rks-col {
            padding-left: 0px;

        }

        .to-col {
            padding-left: 10px !important;
        }


        .rks,
        .invo {
            padding: 0 !important;
        }

        .invo {
            padding: 0 !important;
        }

        .row>.col {
            padding: 0 !important;
        }
    }
}


.dotted-line {
    border-top: 2px dotted #a09999;
    margin: 20px 0;
}

.dashed-line {
    display: block;
    border-top: 1px dashed #948e8e;
    width: 100%;
    margin: 5px 0;
    width: 100%;
}

// Receipt
.receipts-container {
    .receipt-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        padding: 20px;

        @media (max-width: 480px) {
            padding: 10px;
        }
    }

    .receipt-box {
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
        width: 100%;
        max-width: 450px;
        padding: 20px;

        @media (max-width: 480px) {
            width: 100%;
            padding: 15px;
        }

        @media (min-width: 768px) {
            width: 400px;
        }
    }

    .business-name {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 5px;

        @media (max-width: 480px) {
            font-size: 20px;
        }
    }

    .contact-info {
        text-align: center;
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 500;

        @media (max-width: 480px) {
            font-size: 12px;
        }
    }

    .receipt-title {
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;


        @media (max-width: 480px) {
            font-size: 16px;
        }
    }

    .divider {
        border: none;
        border-top: 1px solid #ddd;
        margin: 10px 0;
    }

    .customer-details {
        margin-bottom: 20px;

        h4 {
            margin-bottom: 5px;
            font-size: 16px;
            font-weight: 500;

            @media (max-width: 480px) {
                font-size: 14px;
            }
        }
    }

    .details-table {
        width: 100%;
        border-collapse: collapse;
        font-size: 14px;
        margin-bottom: 15px;

        @media (max-width: 480px) {
            font-size: 12px;
        }

        td {
            padding: 5px 0;

            @media (max-width: 480px) {
                padding: 3px 0;
            }
        }

        .highlight {
            font-weight: 600;
            //   color: #007bff;
        }
    }

    .footer-note {
        font-size: 12px;
        color: #555;
        text-align: center;
        margin-top: 15px;

        @media (max-width: 480px) {
            font-size: 10px;
        }
    }

    .print-button {
        display: block;
        padding: px 9px;
        margin: 10px auto;
        background: linear-gradient(#10B981, #059666);
        color: white;
        border: none;
        border-radius: 7px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        text-align: center;
        margin-top: 10px;
        transition: all 0.3s ease;

        &:hover {
            background: #218838;
        }

        @media (max-width: 480px) {
            padding: 8px;
            font-size: 14px;
        }
    }

}

// View Details
.viewdetail-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    max-width: 100%;

    @media (max-width: 768px) {
        padding: 15px;
    }

    @media (max-width: 576px) {
        padding: 10px;
    }

    @media (max-width: 400px) {
        padding: 5px;
    }
}


.viewdetail-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 100%;
    max-width: 1200px;
}

.card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
    background-color: #f4f4f4;
    padding: 15px;
    text-align: center;
}

.card-body {
    padding: 15px;
}

.card-footer {
    padding: 10px;
    text-align: center;
}

.update-btn {
    background-color: #534686;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
}

.update-btn:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .viewdetail-grid {
        grid-template-columns: repeat(2, 1fr);

    }
}


@media (max-width: 480px) {
    .viewdetail-grid {
        grid-template-columns: 1fr;

    }
}

.profile-info-tab {
    margin: 0;

    .field-card {
        border: none;
        border-radius: 12px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);


        .card-body {
            padding: 16px;

            .field-label {
                font-size: 0.9rem;
                font-weight: 500;
                color: #534686;
                margin-bottom: 8px;
                display: block;
            }

            .field-input {
                background-color: #eff6ff;
                padding: 10px;
                border: none;
                font-size: 0.9rem;
                color: #333;
                pointer-events: none;
                border-radius: 6px !important;
            }


        }
    }

    .row {
        margin: 0;


        .col-md-6,
        .col-md-6 {
            display: flex;
            justify-content: center;
        }
    }
}

.custom-popup {
    max-width: 1200px !important;
    width: 90%;
}


.custom-popup>.popup-content {
    max-width: 1200px !important;
}


//  viewDetails

.view-info-tab {
    margin: 0;

    .field-card {
        border: none;
        border-radius: 12px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);


        .card-body {
            padding: 16px;

            .field-label {
                font-size: 0.9rem;
                font-weight: 500;
                color: #534686;
                margin-bottom: 8px;
                display: block;
            }

            .field-input {
                background-color: #eff6ff;
                border: none;
                font-size: 0.9rem;
                color: #333;
                pointer-events: none;
            }

            .textarea-wrapper {
                display: flex;
                align-items: center;
                position: relative;
                width: 100%;
            }

            .form-control {
                flex-grow: 1;
                padding-right: 40px;
            }

            .icon-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 10px;
            }

            .icon-message {
                font-size: 20px;
                color: #534686;
            }

            .textarea-wrapper {
                position: relative;
                display: flex;
                align-items: center;
                width: 100%;
            }

            .contenteditable-textarea {
                flex-grow: 1;
                padding: 10px 10px 10px 40px;
                background-color: #EFF6FF;
                border-radius: 12px;
                min-height: 50px;
                white-space: pre-wrap;
                word-wrap: break-word;
                outline: none;
                overflow-y: auto;
                resize: none;
            }

            .icon-message {
                position: absolute;
                left: 10px;
                top: 38%;
                transform: translateY(-50%);
                color: #534686;
                pointer-events: none;
                background-color: white;
                border-radius: 50%;
                padding: 4px;
            }

        }
    }

    .row {
        margin: 0;


        .col-md-6,
        .col-md-6 {
            display: flex;
            justify-content: center;
        }
    }

    .pay-all-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        .pay-all {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(90deg, #10B981, #059666);
            color: white;
            padding: 7px 20px;
            font-size: 16px;
            border-radius: 8px;
            border: none;
            cursor: pointer;
            transition: linear-gradient(90deg, #10B981, #059666) 0.3s ease;
            max-width: fit-content;
        }
    }

    .viewdetails-lastcard {
        .card-body {
            .top-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1rem;

                .fields-label {
                    font-weight: 600;
                    font-size: 16px;
                }

                .date-status-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    .date-label {
                        margin: 0;
                        color: #6e7c87;
                    }

                    .status-progress {
                        margin: 0;
                        border-radius: 5px;
                        padding: 3px 10px;
                        background-color: #d4edda;
                    }

                    .status-inprogress {
                        margin: 0;
                        border-radius: 5px;
                        padding: 3px 10px;
                        background-color: #FFF3CD;
                    }

                    .status-request {
                        margin: 0;
                        border-radius: 5px;
                        padding: 3px 10px;
                        background-color: #F8D7DA;
                    }
                }
            }

            @media (max-width: 576px) {
                .top-row {
                    margin-top: 1.5rem;
                }
            }

        }
    }
}

.expire-info-tab {

    .field-label {
        font-size: 0.9rem;
        font-weight: 500;
        color: #534686;
        margin-bottom: 8px;
        display: block;
    }

    .field-input {
        background-color: #EFF6FF !important;
        border: none;
        font-size: 0.9rem;
        color: #333;
        pointer-events: none;

        @media (max-width: 576px) {
            min-height: 120px;
        }
    }

    .expiry-date {
        margin-bottom: 1rem;
        color: #10B981;
        background-color: #ECFDF5 !important;
        padding: 4px 10px;
        border-radius: 8px;
        font-size: 14px;
        display: inline-block;
        margin-left: 24px;
    }

    .expired-date {
        margin-bottom: 1rem;
        color: #DF2935;
        background-color: #EFF6FF;
        padding: 4px 10px;
        border-radius: 8px;
        font-size: 14px;
        display: inline-block;
        margin-left: 24px;
    }

    .position-relative .position-absolute {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        font-weight: 500;
        color: #6c757d;
    }

    .custom-input-style {
        border-radius: 8px;
        padding: 28px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #EFF6FF;
        font-size: 16px;
        font-weight: 500;
        height: 38px;

    }

    .custom-field {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .price-text {
        color: #6c757d;
        margin-left: auto;
    }

}

ul.custom-bullets {
    list-style-type: disc;
    padding-left: 20px;
}

.d-flex .ms-3 {
    display: flex;
    align-items: center;
}

.expired-date {
    margin-bottom: 1rem;
    color: #DF2935;
    background-color: #EFF6FF;
    padding: 4px 10px;
    border-radius: 8px;
    font-size: 14px;
    display: inline-block;
    margin-left: 24px;
}

.pay-all {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(90deg, #10B981, #059666);
    color: white;
    padding: 7px 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: linear-gradient(90deg, #10B981, #059666) 0.3s ease;
    max-width: fit-content;
}

.change-plan-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #ffff;
    color: #059666;
    padding: 7px 20px;
    font-size: 16px;
    border-radius: 8px;
    border: 1px solid rgb(13, 216, 13);
    cursor: pointer;
    transition: linear-gradient(90deg, #10B981, #059666) 0.3s ease;
    max-width: fit-content;

}

.change-plan-btn:hover,
.pay-all:hover {
    background-color: #155d20;
    color: white;
}

.f-14 {
    font-size: 16px;
}

.f-20 {
    font-size: 20px;
}

.f-18 {
    font-size: 18px;
}

.font-14 {
    font-size: 14px;
}


// .card-container {
//     .container-cardbox {
//         max-width: 1220px !important;

//         @media (min-width: 1356px) {
//             max-width: 1300px !important;
//         }
//     }
// }